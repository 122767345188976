import { ReactNode, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import moment from 'moment';
import Table from '../../../components/common/Table';
import Badge from '../../../components/common/Badge';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import SvgIcon from '../../../components/common/SvgIcon';
import Avatar from '../../../components/common/Avatar';
import PublishContentModal from '../../../components/Data/Process/PublishContentModal';
import Tooltip from '../../../components/common/Tooltip';

const SAMPLE_DATA = [
  {
    id: 1,
    completedStep: {
      name: 'Validate Analysis',
      desc: 'Upload and share pre-approved branding content on the company’s Instagram profile as part of the social media strategy.'
    },
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Performance Marketing',
    members: [
      { avatar: '' },
      { avatar: '' },
      { avatar: '' },
      { avatar: '' },
      { avatar: '' },
      { avatar: '' },
      { avatar: '' }
    ],
    lastChange: {
      time: moment().valueOf(),
      desc: 'Step recorded by Malte Pfeiffer'
    }
  },
  {
    id: 2,
    completedStep: {
      name: 'Validate Analysis',
      desc: 'Upload and share pre-approved branding content on the company’s Instagram profile as part of the social media strategy.'
    },
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    members: [{ avatar: '' }],
    lastChange: {
      time: moment().valueOf(),
      desc: 'Step recorded by Malte Pfeiffer'
    }
  },
  {
    id: 3,
    completedStep: {
      name: 'Validate Analysis',
      desc: 'Upload and share pre-approved branding content on the company’s Instagram profile as part of the social media strategy.'
    },
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Sales',
    members: [{ avatar: '', linked: true }, { avatar: '' }, { avatar: '' }, { avatar: '' }],
    lastChange: {
      time: moment().valueOf(),
      desc: 'Step recorded by Malte Pfeiffer'
    }
  }
];

const MyProcessSteps = () => {
  const [show, setShow] = useState(false);

  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: 'S.ID',
        columnType: 'optional',
        accessorKey: 'id'
      },
      {
        header: 'Completed Process Steps',
        cell: (row) => {
          const info = row.getValue() as Record<string, any>;
          return (
            <div className={'border border-[#EBEBEB] inline-flex p-2 rounded-lg gap-3'}>
              <div className={'flex items-center gap-3'}>
                <SvgIcon name={'steps'} size={20} color={'#838383'} />
                <div>
                  <div className={'font-semibold leading-[17.64px] text-sm'}>{info?.name}</div>
                  <div
                    className={
                      'text-xs leading-[15.12px] max-w-[305px] text-ellipsis overflow-hidden'
                    }>
                    {info?.desc}
                  </div>
                </div>
              </div>

              <div
                onClick={() => setShow(true)}
                className={
                  'border w-[34px] h-[34px] rounded-lg border-[#EBEBEB] flex items-center justify-center cursor-pointer'
                }>
                <SvgIcon name={'eye'} size={18} color={'#636369'} />
              </div>
            </div>
          );
        },
        accessorKey: 'completedStep'
      },
      {
        header: 'Process',
        cell: (row) => {
          const info = row.getValue() as Record<string, string>;
          return (
            <div>
              <div className="font-bold text-base mb-1">{info.name}</div>
              <div className="max-w-[408px] font-normal text-sm text-ellipsis overflow-hidden">
                {info.desc}
              </div>
            </div>
          );
        },
        accessorKey: 'process'
      },
      {
        header: 'Department',
        cell: (row) => {
          let cssClass = '';
          let textCssClass = '';

          switch (row.getValue()) {
            case 'Marketing':
              cssClass += '!bg-[#13C296] !border-[#13C296]';
              textCssClass += '!text-white';
              break;

            case 'Performance Marketing':
              cssClass += '!bg-[#CCFFF2] !border-[#CCFFF2]';
              textCssClass += '!text-[#149474]';
              break;
          }

          return (
            <Badge
              text={row.getValue() as ReactNode}
              className={cssClass}
              textClassName={textCssClass}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'department'
      },
      {
        header: 'Process Step Members',
        cell: (row) => {
          const members = row.getValue() as Record<string, any>[];
          let otherMembers = members.slice(1, 4);
          let memberAmountLeft = members.length - 4;

          return (
            <div
              className={`flex min-w-[200px] items-center gap-2 ${members[0]?.linked ? 'border border-[#EBEBEB] rounded-lg py-2 px-1.5' : ''}`}>
              {!!members[0] && (
                <div className={`flex items-center`}>
                  {members[0]?.linked && (
                    <>
                      <Tooltip text={'Marketing@apple.com'}>
                        <div
                          className={
                            'flex items-center justify-center border border-[#DEDEDE] rounded-md w-[27px] h-[27px]'
                          }>
                          <SvgIcon name={'inbox'} size={16} color={'#838383'} />
                        </div>
                      </Tooltip>

                      <SvgIcon name={'plug'} size={24} />
                    </>
                  )}

                  <Avatar
                    className={
                      'border-[#13C296] border-[2.84px] min-w-[40px] w-[40px] h-[40px] rounded-lg flex-grow-0 flex-shrink-0'
                    }
                    src={members[0].avatar}
                  />
                </div>
              )}

              <div className={'rounded-full w-[6px] h-[6px] border border-[#636363]'} />

              {otherMembers.length > 0 && (
                <div className={'relative h-[40px] overflow-visible'}>
                  {otherMembers.map((ava, idx) => (
                    <div className={'absolute'} style={{ left: `${idx * 15}px` }}>
                      <Avatar
                        key={idx}
                        className={`absolute top-0 min-w-[40px] w-[40px] h-[40px] rounded-lg border-[#FFF] border`}
                        src={ava.avatar}
                      />
                    </div>
                  ))}

                  {memberAmountLeft > 0 && (
                    <div
                      className={`absolute top-0 select-none border border-[#FFF] w-[40px] h-[40px] rounded-lg bg-[#F2F4F7] text-[#858585] leading-[40px] text-center font-medium`}
                      style={{ left: `${otherMembers.length * 15}px` }}>
                      +{memberAmountLeft}
                    </div>
                  )}
                </div>
              )}

              {otherMembers.length === 0 && (
                <div
                  className={'border border-[#B1B1B1] border-dotted w-[40px] h-[40px] rounded-lg'}
                />
              )}
            </div>
          );
        },
        accessorKey: 'members'
      },
      {
        header: 'Last Change',
        cell: () => {
          return (
            <div className="flex flex-col items-start">
              <div className={'text-sm'}>
                <b>23/12/2024</b> <span className={'font-normal'}>12:00</span>
              </div>
              <div className={'font-medium'}>New Version created by Malte Pfeiffer</div>
            </div>
          );
        },
        accessorKey: 'myProcessStep'
      }
    ],
    []
  );

  return (
    <>
      <div className="w-full">
        <Table
          enableSetting
          enableStripedRowColor
          data={SAMPLE_DATA}
          columns={cols}
          header={
            <Button
              className="min-w-[301px] !justify-start"
              label="Search"
              size="small"
              leftIcon={<Icon name="search" />}
            />
          }
          rightAction={
            <Button
              className="ml-auto"
              label="Department"
              size="small"
              leftIcon={<Icon name="list-filter" />}
              rightIcon={<Icon name="chevron-down" />}
            />
          }
        />
      </div>

      <PublishContentModal show={show} onClose={() => setShow(false)} />
    </>
  );
};

export default MyProcessSteps;
