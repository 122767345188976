import { FC, useMemo } from 'react';
import { Badge as RBadge, Theme } from '@radix-ui/themes';
import { BadgeVariant, Props } from './@types/badge';

const Badge: FC<Props> = ({
  text,
  className,
  textClassName,
  round = 'semi-round',
  state = 'fill',
  variant = 'primary',
  size = 'medium'
}) => {
  const getTextColorFromOutline = (variant: BadgeVariant) => {
    switch (variant) {
      case 'primary':
        return '!text-blue-primary';
      case 'secondary':
        return '!text-blue-secondary';
      case 'dark':
        return '!text-dark-primary';
      case 'gray':
        return '!text-gray-secondary';
      case 'light':
        return '!text-gray-3';
      case 'warning':
        return '!text-yellow-primary';
      case 'danger':
        return '!text-red-primary';
      case 'success':
        return '!text-green-primary';
      case 'info':
        return '!text-cyan-primary';
      case 'white':
        return '!text-gray-6';
    }
  };

  const getRadiusOption = useMemo(() => {
    switch (round) {
      case 'full-round':
        return 'full';
      case 'semi-round':
        return 'large';
    }
  }, [round]);
  const getTextColor = useMemo(() => {
    if (variant === 'light') {
      return 'text-dark-primary';
    }
    switch (state) {
      case 'fill':
        return 'text-white';
      default: {
        return getTextColorFromOutline(variant);
      }
    }
  }, [state, variant]);
  const getBackgroundColor = useMemo(() => {
    if (state === 'outline' || variant === 'white') {
      return '!bg-transparent';
    }
    const isDouTone = state === 'dou-tone';
    switch (variant) {
      case 'primary':
        return isDouTone ? '!bg-blue-dou-primary' : '!bg-blue-primary';
      case 'secondary':
        return isDouTone ? '!bg-blue-dou-secondary' : '!bg-blue-secondary';
      case 'dark':
        return isDouTone ? '!bg-dark-dou-secondary' : '!bg-dark-primary';
      case 'gray':
        return isDouTone ? '!bg-gray-dou-primary' : '!bg-gray-secondary';
      case 'light':
        return isDouTone ? '!bg-gray-dou-secondary' : '!bg-gray-3';
      case 'warning':
        return isDouTone ? '!bg-yellow-light-4' : '!bg-yellow-primary';
      case 'danger':
        return isDouTone ? '!bg-red-light-5' : '!bg-red-primary';
      case 'success':
        return isDouTone ? '!bg-green-light-6' : '!bg-green-primary';
      case 'info':
        return isDouTone ? '!bg-cyan-light-3' : '!bg-cyan-primary';
    }
  }, [variant, state]);

  const getBorder = useMemo(() => {
    if (state !== 'outline') {
      return '!border-none';
    }
    switch (variant) {
      case 'primary':
        return '!border-[1px] !border-blue-primary';
      case 'secondary':
        return '!border-[1px] !border-blue-secondary';
      case 'dark':
        return '!border-[1px] !border-dark-primary';
      case 'gray':
        return '!border-[1px] !border-gray-secondary';
      case 'light':
        return '!border-[1px] !border-gray-3';
      case 'warning':
        return '!border-[1px] !border-yellow-primary';
      case 'danger':
        return '!border-[1px] !border-red-primary';
      case 'success':
        return '!border-[1px] !border-green-primary';
      case 'info':
        return '!border-[1px] !border-cyan-primary';
      case 'white':
        return '!border-[1px] !border-border-8';
    }
  }, [variant, state]);

  const getHeight = useMemo(() => {
    switch (size) {
      case 'large':
        return '!h-[32px]';
      case 'semi':
        return '!h-[28.7px]';
      case 'medium':
        return '!h-[22px]';
      case 'small':
        return '!h-[17.66px]';
    }
  }, [size]);

  const getSize = useMemo(() => {
    switch (size) {
      case 'large':
      case 'semi':
        return 'text-base';
      case 'medium':
        return 'text-xs';
      case 'small':
        return 'text-[10px]';
    }
  }, [size]);

  const getSpace = useMemo(() => {
    switch (size) {
      case 'semi': {
        return `!px-[11.75px]`;
      }
      case 'large': {
        return `!px-[10px]`;
      }
      default:
        return `!px-2`;
    }
  }, [size]);

  return (
    <Theme>
      <RBadge
        className={`w-fit !py-1 ${getSpace} ${getHeight} ${getBackgroundColor} ${getBorder} ${className && className}`}
        radius={getRadiusOption}>
        {text && (
          <div
            className={`font-medium select-none ${getSize} leading-5 ${getTextColor} ${textClassName || ''}`}>
            {text}
          </div>
        )}
      </RBadge>
    </Theme>
  );
};

export default Badge;
