import React from 'react';
import Avatar from '../../common/Avatar';
import Badge from '../../common/Badge';

type Props = {
  onClick?: () => void;
  firstName: string;
  lastName: string;
  role: string;
  department: string;
};

function SelectUserInfo(props: Props) {
  return (
    <div
      onClick={() => props.onClick?.()}
      className={'w-full flex cursor-pointer flex-col items-center gap-1.5'}>
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center gap-1.5">
          <Avatar className="!w-[32px] !h-[32px]" src={''} alt={`name`} />
          <div className="text-sm">
            <div className={'leading-[17.64px] select-none'}>
              <span className="uppercase">{props.firstName},</span> {props.lastName}
            </div>
            <div className="text-xs select-none text-gray-secondary leading-[14px] font-normal">
              {props.role}
            </div>
          </div>
        </div>

        <Badge
          text={props.department}
          variant="white"
          state="outline"
          round="semi-round"
          size="medium"
        />
      </div>
    </div>
  );
}

export default SelectUserInfo;
