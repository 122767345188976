import { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './containers/LoginPage';
import { useTranslation } from 'react-i18next';
import ForgotPasswordPage from './containers/ForgotPasswordPage';
import ResetPasswordPage from './containers/ResetPasswordPage';
import { ROUTES } from './constants/router';
import DataPage from './containers/DataPage';
import PrivateRoutes from './components/layout/PrivateRoutes';
import Process from './containers/DataPage/Process';
import CreateProcess from './containers/DataPage/Process/CreateProcess';
import CreateOrganization from './containers/DataPage/Organization/CreateOrganization';
import Organization from './containers/DataPage/Organization';
import SignUpPage from './containers/SignUpPage';
import Department from './containers/DataPage/Department';
import CreateDepartment from './containers/DataPage/Department/CreateDepartment';
import OrganizationMember from './containers/DataPage/OrganizationMember';
import ProcessDetail from './containers/DataPage/Process/ProcessDetail';
import MyTask from './containers/DataPage/MyTask';
import MyProcessSteps from './containers/DataPage/MyProcessStep';

function App() {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    const currentLng = navigator.language;
    if (currentLng.includes('de')) {
      i18n.changeLanguage('de');
    } else {
      i18n.changeLanguage('en');
    }
  }, [i18n]);
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
        <Route path={ROUTES.SIGN_UP} element={<SignUpPage />} />
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.ANY} element={<Navigate to={ROUTES.LOGIN} replace />} />
        <Route element={<PrivateRoutes />}>
          <Route path={ROUTES.MAIN} element={<></>} />
          <Route path={ROUTES.DATA} element={<DataPage />}>
            <Route path={ROUTES.DATA_OVERVIEW} element={<div>{t('overview')}</div>} />
            <Route path={ROUTES.DATA_ORGANIZATION} element={<Organization />} />
            <Route path={ROUTES.DATA_DEPARTMENT} element={<Department />} />
            <Route
              path={`${ROUTES.DATA_DEPARTMENT_EDIT}/:departmentId`}
              element={<CreateDepartment />}
            />
            <Route path={ROUTES.DATA_ORGANIZATION_CREATE} element={<CreateOrganization />} />
            <Route
              path={`${ROUTES.DATA_ORGANIZATION_EDIT}/:organizationId`}
              element={<CreateOrganization />}
            />
            <Route path={ROUTES.DATA_DEPARTMENT_CREATE} element={<CreateDepartment />} />
            <Route path={ROUTES.DATA_PROCESS} element={<Process />} />
            <Route path={ROUTES.MY_PROCESS} element={<MyProcessSteps />} />
            <Route path={ROUTES.MY_TASK} element={<MyTask />} />
            <Route path={`${ROUTES.DATA_PROCESS}/:id`} element={<ProcessDetail />} />
            <Route path={ROUTES.DATA_PROCESS_CREATE} element={<CreateProcess />} />
            <Route path={ROUTES.DATA_ORGANIZATION_MEMBERS} element={<OrganizationMember />} />
            <Route path={ROUTES.DATA_TEAM_MEMBERS} element={<div>Team Members</div>} />
            <Route path={ROUTES.DATA_NOTES} element={<div>{t('notes')}</div>} />
            <Route path={ROUTES.DATA_FILES} element={<div>{t('files')}</div>} />
          </Route>
          <Route path={ROUTES.DASHBOARD} element={<></>} />
          <Route path={ROUTES.NOTIFICATION} element={<></>} />
          <Route path={ROUTES.PROFILE} element={<></>} />
          <Route path={ROUTES.SETTING} element={<></>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
