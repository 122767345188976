import { useTranslation } from 'react-i18next';
import LogoWithHeader from '../components/layout/LogoWithHeader';
import TrustTeams from '../components/layout/TrustTeams';
import RightPanel from '../components/layout/RightPanel';
import ResetPasswordForm from '../components/ResetPassword/ResetPasswordForm';
const ResetPasswordPage = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[100vh] w-[100vw] flex overflow-hidden">
      <div className="flex flex-col justify-center items-center h-full w-[46.7%]">
        <div className="max-w-[432px]">
          <LogoWithHeader />
          <div>
            <div className="font-semibold text-xl">{t('forgotPassword')}?</div>
            <div className="text-sub text-sm">{t('forgotPasswordDescription')}</div>
            <div className="mt-4">
              <ResetPasswordForm />
            </div>
            <TrustTeams />
          </div>
        </div>
      </div>
      <RightPanel />
    </div>
  );
};

export default ResetPasswordPage;
