import { createSlice } from '@reduxjs/toolkit'
import { listIndustryClassification } from '../../api/industry-classification'
import { IndustryClassification } from '../../@types/industry-classification'

interface IState {
    list: IndustryClassification[],
    loading: boolean
}

const defaultState: IState = {
    list: [],
    loading: false
}

const initialState = defaultState
const industryClassificationSlice = createSlice({
    name: 'industryClassification',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(listIndustryClassification.pending, (state) => {
            state.loading = true
        })
        builder.addCase(listIndustryClassification.fulfilled, (state, action) => {
            state.loading = false
            state.list = action.payload
        })
    }
})

export default industryClassificationSlice.reducer