import FormCard from '../../../common/FormCard';
import Rating from '../../../common/Rating';
import TextArea from '../../../common/TextArea';

const Question = () => {
  return (
    <div>
      <FormCard theme="light">
        <div className='flex justify-between items-center'>
          <div>
            <div className="text-gray-13 text-sm">Question 1</div>
            <div className="text-dark-6 text-base mt-1">
              How effective is this <strong>Process Step</strong>?
            </div>
          </div>
          <div>
            <Rating />
          </div>
        </div>
        <div className="text-gray-14 text-sm mb-2 mt-4">
          Do you have any comments on this step or suggestions for improvement?
        </div>
        <TextArea />
      </FormCard>
    </div>
  );
};

export default Question;
