import { useTranslation } from 'react-i18next';

const TrustTeams = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-8">
      <p className="text-sm text-body text-center">{t('trustBy')}</p>
      <div className="flex mt-2 items-center justify-center gap-7">
        <img src="/assets/images/headspace.png" alt="logo" />
        <img src="/assets/images/shopify.png" alt="logo" />
        <img src="/assets/images/revolut.png" alt="logo" />
        <img src="/assets/images/duolingo.png" alt="logo" />
      </div>
    </div>
  );
};

export default TrustTeams;
