export const checkUpperCaseRegex = (str: string) => {
    return /[A-Z]/gi.test(str)
}

export const checkLowerCaseRegex = (str: string) => {
    return /[a-z]/gi.test(str)
}

export const checkNumericRegex = (str: string) => {
    return /\d/gi.test(str)
}

export const checkSpecialCharRegex = (str: string) => {
    return /[!@#$%^&*()_+]/g.test(str)
}

export const extractFirstLetters = (text?: string) => {
    if (!text) return ''
    const words = text.split(' ');
    const firstLetters = words.map(word => word.charAt(0));
    return firstLetters.join('').slice(0, 2);
}