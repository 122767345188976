import { createSlice } from '@reduxjs/toolkit';
import { forgotPasword, profile, resetPassword, signIn, signUp } from '../../api/auth';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { User, UserTypes } from '../../@types/user';

interface IState {
  loading: boolean;
  resetSuccess: boolean;
  sendForgotSuccess: boolean;
  error: boolean;
  accessToken: string;
  user?: User;
  userTypes: UserTypes[];
}

const defaultState: IState = {
  loading: false,
  resetSuccess: false,
  sendForgotSuccess: false,
  error: false,
  accessToken: '',
  user: undefined,
  userTypes: []
};

const initialState = defaultState;
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      state = defaultState;
      window.location.pathname = '/login';
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.accessToken = action.payload.data.access_token;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.loading = false;
      if (action.error) {
        state.error = true;
      }
    });
    builder.addCase(forgotPasword.fulfilled, (state) => {
      state.sendForgotSuccess = true;
      state.loading = false;
    });
    builder.addCase(forgotPasword.pending, (state) => {
      state.sendForgotSuccess = false;
      state.loading = true;
    });
    builder.addCase(forgotPasword.rejected, (state) => {
      state.sendForgotSuccess = false;
      state.loading = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
      state.resetSuccess = true;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
      state.resetSuccess = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.loading = false;
      state.resetSuccess = false;
    });
    builder.addCase(profile.fulfilled, (state, action) => {
      state.user = action.payload;
      state.userTypes =
        action.payload.userTypes?.length === 0 ? ['OrgMember'] : action.payload.userTypes;
      state.loading = false;
    });
    builder.addCase(profile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(profile.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(signUp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(signUp.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { logout, setLoading } = authSlice.actions;

export default authSlice.reducer;
