const UserNotFound = () => {
  return (
    <div className="flex items-center justify-center flex-col flex-1 relative">
      <img src="/assets/images/user-not-found.png" alt="Not found" />
      <div className="text-2xl">No users found.</div>
      <img
        className="absolute translate-x-full -translate-y-1/3"
        src="/assets/images/arrow-rotate.png"
        alt="Arrow"
      />
    </div>
  );
};

export default UserNotFound;
