import React, { PropsWithChildren } from 'react';
import SvgIcon from './SvgIcon';
import { Props } from './@types/formCard';

function FormCard(props: PropsWithChildren<Props>) {
  const bgColor = props.theme === 'gray' ? 'bg-[#F9F9F9]' : 'bg-white';

  return (
    <div
      className={`w-full border-[#DEE2E8] border rounded-lg p-3 ${props.className} ${bgColor} ${props.disabled ? 'opacity-[22%] cursor-not-allowed' : ''}`}>
      {!!props.content ? (
        props.content
      ) : (
        <div className={'flex items-start justify-between mb-2'}>
          <div>
            <div className={'flex items-center gap-[3.67px]'}>
              {props.icon && <SvgIcon name={props.icon} size={16.52} />}
              <b className={'text-base font-semibold'}>{props.title}</b>
            </div>
            <div className={'text-sm text-[#A5A7AA]'}>{props.desc}</div>
          </div>

          {props.rightContent}
        </div>
      )}

      {props.children}
    </div>
  );
}

export default FormCard;
