import { FC, useMemo } from 'react';
import _ from 'lodash';
import Modal from '../../common/Modal';
import Icon from '../../common/Icon';
import Input from '../../common/Input';
import TextArea from '../../common/TextArea';
import Select from '../../common/Select';
import Button from '../../common/Button';
import { ArrowRight } from 'lucide-react';
import { Props } from '../../common/@types/modal';
import Avatar from '../../common/Avatar';
import DatePicker from '../../common/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { addDrafItem } from '../../../app/slices/process.slice';
import { ICreateProcess } from '../../../@types/process';

const CreateProcessModal: FC<Props> = ({ open, onClose }) => {
  const { listDepartment } = useAppSelector((state) => state.department);
  const dispatch = useAppDispatch();
  const { getValues, setValue, watch } = useForm<ICreateProcess>();
  const { department, dueDate, processOwner, initAssignMember } = watch();

  const departmentItems = useMemo(() => {
    return listDepartment.map((item) => ({ value: item._id, label: item.name }));
  }, [listDepartment]);

  const onSubmit = () => {
    const data = getValues();
    dispatch(addDrafItem(data));
    onClose();
  };

  const departmentMembers = useMemo(() => {
    const matchDepartment = _.find(listDepartment, (item) => item._id === department);
    if (!matchDepartment) {
      return [];
    }
    return _.map(matchDepartment.members, (member) => ({
      value: member._id,
      label: (
        <div className="flex items-center gap-2 w-full">
          <Avatar rounded="lg" src={member.image} />
          <div className="text-left">
            <div className="font-black text-sm uppercase">
              {member.firstName}, {member.lastName}
            </div>
            <div className="text-gray-secondary font-normal text-sm capitalize">
              {member.role.toLowerCase()}
            </div>
          </div>
        </div>
      )
    }));
  }, [listDepartment, department]);

  return (
    <Modal
      open={open}
      content={
        <div className="flex flex-col gap-4">
          <div className="p-[16.5px] rounded-2xl border border-boder-4 w-fit">
            <Icon name="waypoints-2" size={30} />
          </div>
          <div>
            <div className="font-semibold text-2xl">Create New Process</div>
            <div className="text-base">Placeholder copy here.</div>
          </div>
          <form>
            <div className="grid grid-cols-2 gap-x-6 gap-y-[18px]">
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">Process Name*</div>
                <Input
                  className="w-[300px]"
                  value={getValues('name')}
                  onChange={(e) => setValue('name', e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">Due Date*</div>
                <DatePicker value={dueDate} onChange={(value) => setValue('dueDate', value)} />
              </div>
              <div className="col-span-2">
                <div className="mb-2 text-base font-medium text-gray-900">Process Description*</div>
                <TextArea
                  className="w-full"
                  value={getValues('description')}
                  onChange={(e) => setValue('description', e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <div className="mb-2 text-base font-medium text-gray-900">Department*</div>
                <Select
                  items={departmentItems}
                  value={getValues('department')}
                  onChange={(value) => setValue('department', value)}
                  placeholder="Select"
                  className="w-full"
                />
              </div>
              <div className="col-span-2">
                <div className="mb-2 text-base font-medium text-gray-900">Process Owner*</div>
                <Select
                  items={departmentMembers}
                  value={processOwner}
                  onChange={(value) => setValue('processOwner', value)}
                  placeholder="Select"
                  className="w-full"
                  size="medium"
                  disabled={!department}
                />
              </div>
              <div className="col-span-2">
                <div className="mb-2 text-base font-medium text-gray-900">
                  Initial Member to start the proces
                </div>
                <Select
                  items={departmentMembers}
                  value={initAssignMember}
                  onChange={(value) => setValue('initAssignMember', value)}
                  placeholder="Select"
                  className="w-full"
                  size="medium"
                  disabled={!processOwner}
                />
              </div>
            </div>

            <div className="flex gap-4 mt-4">
              <Button label="Back" className="flex-1" onClick={onClose} />
              <Button
                label="Continue"
                className="flex-1"
                rightIcon={<ArrowRight />}
                onClick={onSubmit}
              />
            </div>
          </form>
        </div>
      }
      onClose={onClose}
    />
  );
};

export default CreateProcessModal;
