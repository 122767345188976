import React from 'react';
import Select from '../../common/Select';
import SvgIcon from '../../common/SvgIcon';
import Input from '../../common/Input';
import Avatar from '../../common/Avatar';
import Badge from '../../common/Badge';
import { IItem } from '../../common/@types/select';
import SelectUserInfo from './SelectUserInfo';

type Props = {
  options: IItem[];
  value: string;
  onChange: (value: string) => void;
};

function PeopleSelect(props: Props) {
  const hdSelectDept = (value: string) => {};

  return (
    <Select
      items={props.options}
      placeholder={'Select'}
      onChange={props.onChange}
      value={props.value}
      onValueRender={(item: IItem) => (
        <SelectUserInfo
          firstName={item.origin?.firstName}
          lastName={item.origin?.lastName}
          role={item.origin?.role}
          department={item.origin?.department}
        />
      )}
      onMenuRender={(items, options) => (
        <div
          className={
            'flex flex-col p-2 rounded-lg border-[1px] border-[#ECEDF2] bg-white max-h-[289px] box-border overflow-y-auto'
          }>
          <div className={'flex items-center gap-2'}>
            <Select
              className={'grow-2 max-w-[238px]'}
              items={[
                {
                  label: (
                    <div className={'flex items-center gap-1.5'}>
                      <SvgIcon name={'list-filter'} />
                      <div>All Departments</div>
                    </div>
                  ),
                  value: 'all'
                }
              ]}
              placeholder={'Select'}
              onChange={hdSelectDept}
              value={'all'}
            />
            <Input containerClassName={'grow'} placeholder={'Search'} />
          </div>

          <div
            className={
              'flex flex-col cursor-pointer items-center gap-1.5 mt-2 p-2 h-[52px] box-border'
            }>
            <div className="flex items-center w-full justify-between pb-1.5 border-b border-b-[#EAEAEA]">
              <div className="flex items-center gap-1.5">
                <Avatar
                  className="border-border-10 border-[2px] !w-[24px] !h-[24px]"
                  src={''}
                  alt={`name`}
                />
                <div className="text-sm">
                  <div className={'leading-[17.64px] select-none'}>John Kenny (You)</div>
                  <div className="text-xs text-gray-secondary leading-[14px] font-normal select-none">
                    {'Senior Software Developer'}
                  </div>
                </div>
              </div>

              <div className={'flex items-center gap-2'}>
                <Badge
                  text={'Design'}
                  variant="white"
                  state="outline"
                  round="semi-round"
                  size="medium"
                />

                <SvgIcon name={'check'} color={'#44507F'} size={16} />
              </div>
            </div>
          </div>

          {items.map((it) => (
            <div
              key={it.value}
              onClick={() => options.onSelectItem(it)}
              className={'flex cursor-pointer flex-col items-center gap-1.5 mt-2 p-2'}>
              <div className="flex items-center w-full justify-between">
                <div className="flex items-center gap-1.5">
                  <Avatar className="!w-[24px] !h-[24px]" src={''} alt={`name`} />
                  <div className="text-sm">
                    <div className={'leading-[17.64px] select-none'}>
                      <span className="uppercase">{it.origin?.firstName},</span>{' '}
                      {it.origin?.lastName}
                    </div>
                    <div className="text-xs select-none text-gray-secondary leading-[14px] font-normal">
                      {it.origin?.role}
                    </div>
                  </div>
                </div>

                <div className={'flex items-center gap-2 h-[22px]'}>
                  <Badge
                    text={it.origin?.department}
                    variant="white"
                    state="outline"
                    round="semi-round"
                    size="medium"
                  />

                  <SvgIcon name={'check'} color={'#44507F'} size={16} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
}

export default PeopleSelect;
