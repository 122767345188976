import { ArrowLeft } from 'lucide-react';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import PasswordInput from '../common/PasswordInput';
import {
  checkLowerCaseRegex,
  checkNumericRegex,
  checkSpecialCharRegex,
  checkUpperCaseRegex
} from '../../libs/helper';
import { resetPassword } from '../../api/auth';
import { ROUTES } from '../../constants/router';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const passwordQuantity = useRef({
    length: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    special: false
  });
  const { loading, resetSuccess } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleGoBack = () => {
    navigate(ROUTES.LOGIN);
  };

  const handleSubmit = useCallback(async () => {
    if (!token || !password) {
      return;
    }
    await dispatch(
      resetPassword({
        resetPasswordToken: token,
        password
      })
    );
  }, [token, password, dispatch]);

  useEffect(() => {
    if (password) {
      passwordQuantity.current = {
        length: password.length > 5,
        uppercase: checkUpperCaseRegex(password),
        lowercase: checkLowerCaseRegex(password),
        numeric: checkNumericRegex(password),
        special: checkSpecialCharRegex(password)
      };
    }
  }, [password]);

  useEffect(() => {
    if (resetSuccess) {
      window.alert('Ok');
      navigate(ROUTES.LOGIN);
    }
  }, [resetSuccess, navigate]);

  const isPasswordError = useMemo(() => {
    if (password) {
      const isError = Object.values(passwordQuantity.current).some((val) => !val);
      return isError;
    }
  }, [password]);

  const renderHelperText = useMemo(() => {
    if (password) {
      return (
        <Fragment>
          {confirmPassword && confirmPassword !== password ? (
            <div className="text-red-600">{t('passwordNotMatch')}</div>
          ) : null}
          {isPasswordError ? (
            <ul className="list-disc ml-[14px]">
              <li
                className={`${passwordQuantity.current.length ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordLength')}
              </li>
              <li
                className={`${passwordQuantity.current.uppercase ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordUpper')}
              </li>
              <li
                className={`${passwordQuantity.current.lowercase ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordLower')}
              </li>
              <li
                className={`${passwordQuantity.current.numeric ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordNumeric')}
              </li>
              <li
                className={`${passwordQuantity.current.special ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordSpecial')}
              </li>
            </ul>
          ) : null}
        </Fragment>
      );
    }
  }, [password, confirmPassword, isPasswordError, t]);

  const isConfirmPasswordError = useMemo(() => {
    if (password && confirmPassword) {
      return password !== confirmPassword;
    }
  }, [password, confirmPassword]);

  if (!token) {
    navigate(ROUTES.FORGOT_PASSWORD);
  }

  return (
    <div>
      <PasswordInput
        value={password}
        status={isPasswordError ? 'error' : 'default'}
        onChange={(e) => setPassword(e.target.value)}
        placeholder={t('password')}
      />
      <PasswordInput
        helperText={renderHelperText}
        status={isConfirmPasswordError ? 'error' : 'default'}
        className="mt-4"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        placeholder={t('confirmPassword')}
      />
      <div className="mt-4 flex gap-2">
        <Button onClick={handleGoBack} leftIcon={<ArrowLeft />} />
        <Button
          theme="dark"
          loading={loading}
          onClick={handleSubmit}
          className="w-full"
          label={t('resetPassword')}
        />
      </div>
      <div className="mt-2 text-center">
        <span className="text-gray-secondary text-sm">{t('didNotReceiveEmail')}</span>{' '}
        <span className="text-sm underline">{t('clickToResend')}</span>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
