import Card from '../../../common/Card';
import Question from './Question';

const SurveyContent = () => {
  return (
    <Card className="w-full h-full">
      <Question />
    </Card>
  );
};

export default SurveyContent;
