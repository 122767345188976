import { ArrowLeft, ArrowRight } from 'lucide-react';
import { IconName } from '../../../components/common/@types/icon';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { editMember, removeMember, reset, setStep } from '../../../app/slices/organization';
import { useCallback, useEffect, useMemo, useState } from 'react';
import GeneralInformation from '../../../components/Data/Oranization/Create/GeneralInformation';
import LicenseInformation from '../../../components/Data/Oranization/Create/LicenseInformation';
import AdditionalInformation from '../../../components/Data/Oranization/Create/AdditionalInfomation';
import CreateInitialUsers from '../../../components/Data/Oranization/Create/CreateInitialUsers';
import {
  addMemberToOrganization,
  createOrganization,
  fetchOrganizationDetail,
  updateMemberOfOrganization,
  updateOrganization
} from '../../../api/organization';
import { UserTypes } from '../../../config/data';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/router';
import UsersManagement from '../../../components/Data/Oranization/Create/UsersManagement';
import Modal from '../../../components/common/Modal';
import SvgIcon from '../../../components/common/SvgIcon';
import { setContentSpacing } from '../../../app/slices/layout.slice';

const CreateOrganization = () => {
  const { step, createData, members, loading } = useAppSelector((state) => state.organization);
  const { organizationId } = useParams();
  const [showLeavingPrompt, setShowLeavingPrompt] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setContentSpacing(false));

    return () => {
      dispatch(setContentSpacing(true));
      dispatch(reset());
    };
  }, [dispatch]);

  const STEPS = useMemo(
    () => [
      {
        id: 1,
        name: 'General Information*',
        icon: 'settings-2'
      },
      {
        id: 2,
        name: 'License Information*',
        icon: 'contact'
      },
      {
        id: 3,
        name: 'Additional Information',
        icon: 'badge-alert'
      },
      {
        id: 4,
        name: organizationId ? 'Users Management' : 'Create Initial Users*',
        icon: 'users-round'
      }
    ],
    [organizationId]
  );

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganizationDetail(organizationId));
    }
  }, [organizationId, dispatch]);

  const renderStep = useMemo(() => {
    if (step === 1) {
      return <GeneralInformation />;
    }
    if (step === 2) {
      return <LicenseInformation />;
    }
    if (step === 3) {
      return <AdditionalInformation />;
    }
    if (step === 4) {
      return organizationId ? <UsersManagement /> : <CreateInitialUsers />;
    }
  }, [step, organizationId]);

  const handleCreate = useCallback(async () => {
    if (createData) {
      const listErrorMember: string[] = [];
      if (organizationId) {
        await dispatch(updateOrganization({ organizationId, body: createData }));
        await Promise.all(
          _.map(members, async (member) => {
            if (member._id) {
              return dispatch(
                updateMemberOfOrganization({
                  organizationId,
                  member
                })
              );
            } else {
              const result = await dispatch(
                addMemberToOrganization({
                  organizationId,
                  member
                })
              );
              if (_.get(result, 'error')) {
                listErrorMember.push(member.email);
              } else {
                dispatch(editMember(result.payload));
              }
            }
          })
        );
      } else {
        const data = await dispatch(createOrganization(createData));

        await Promise.all(
          _.map(members, async (member) => {
            const result = await dispatch(
              addMemberToOrganization({
                organizationId: _.get(data.payload, '_id', ''),
                member
              })
            );
            if (_.get(result, 'error')) {
              listErrorMember.push(member.email);
            } else {
              dispatch(editMember(result.payload));
            }
          })
        );
      }
      if (listErrorMember.length) {
        window.alert(`Some user email already taken: ${listErrorMember.join(',')}`);
        listErrorMember.forEach((email) => dispatch(removeMember({ email })));
      } else {
        navigate(`${ROUTES.DATA}/${ROUTES.DATA_ORGANIZATION}`);
      }
    }
  }, [createData, dispatch, members, navigate, organizationId]);

  const handleNextStep = async () => {
    if (step === 4) {
      await handleCreate();
    } else {
      dispatch(setStep(step + 1));
    }
  };

  const handlePreviousStep = async () => {
    if (step === 1) {
      setShowLeavingPrompt(true);
    } else {
      dispatch(setStep(step - 1));
    }
  };

  const isFullUserTypes = useMemo(() => {
    let hasSystemAdmin = false;
    let hasOrgManager = false;
    members?.forEach((member) => {
      const userTypes = member.userTypes || [];
      if (userTypes.includes(UserTypes.SYSTEM_ADMIN)) {
        hasSystemAdmin = true;
      }
      if (userTypes.includes(UserTypes.ORG_MANAGER)) {
        hasOrgManager = true;
      }
    });
    return hasOrgManager && hasSystemAdmin;
  }, [members]);

  const disableProcessNextStep = useMemo(() => {
    switch (step) {
      case 1:
        return !createData?.companyName;
      case 2: {
        return (
          !createData?.license?.endDate ||
          !createData.license?.startDate ||
          !createData.license?.model
        );
      }
      case 4:
        return true;

      default: {
        return false;
      }
    }
  }, [step, isFullUserTypes, createData]);

  return (
    <div className="h-full flex flex-col">
      <div className="grid grid-cols-10 h-full">
        <div className="col-span-2 px-[2px] pb-3 pl-7 pt-7 border-r-[1.5px] border-r-[#DCDCDC]">
          <div className="flex gap-10 flex-col">
            {STEPS.map((item) => (
              <div
                key={item.id}
                className={`flex gap-2 ${step < item.id ? 'opacity-20' : ''} cursor-pointer`}>
                <Icon name={item.icon as IconName} />
                <div className="text-lg font-semibold">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-8 pt-7 w-full flex justify-center h-[calc(100vh_-_250px)] overflow-y-auto">
          {renderStep}
        </div>
      </div>

      <div
        className={
          'bg-white border-t border-border-5 p-4 mt-auto absolute bottom-0 left-0 w-full flex items-center justify-between'
        }>
        <Button
          onClick={() => setShowLeavingPrompt(true)}
          leftIcon={<SvgIcon name={'log-out'} size={20} color={'black'} />}
          label={'Exit'}
        />

        <div className="flex items-center gap-2">
          <Button
            disabled={step === 1}
            label="Back"
            leftIcon={<ArrowLeft />}
            onClick={() => handlePreviousStep()}
          />
          <Button
            label={'Continue'}
            rightIcon={<ArrowRight />}
            disabled={disableProcessNextStep}
            loading={loading}
            onClick={() => handleNextStep()}
          />
        </div>

        <Button
          rightIcon={
            !organizationId ? <SvgIcon name={'arrow-right'} size={20} color={'black'} /> : null
          }
          disabled={!organizationId && (step !== 4 || !isFullUserTypes)}
          label={!organizationId ? 'Finish' : 'Save Changes'}
          onClick={() => handleNextStep()}
        />
      </div>

      <Modal
        open={showLeavingPrompt}
        content={
          <div className="min-w-[422px] flex flex-col items-center justify-center">
            <img className={'w-[479px] h-auto'} src="/assets/images/lose-process.png" alt="Logo" />

            <div className="mt-7 text-2xl font-semibold leading-8">
              You will lose your progress!
            </div>

            <div className="mt-2 text-base leading-[20.16px] font-normal">
              Are you sure you want to proceed?
            </div>

            <div className="flex gap-4 w-full mt-4">
              <Button className="flex-1" label="No" onClick={() => setShowLeavingPrompt(false)} />
              <Button
                className="flex-1"
                label="Yes"
                rightIcon={<ArrowRight />}
                onClick={() => {
                  navigate(`${ROUTES.DATA}/${ROUTES.DATA_ORGANIZATION}`);
                }}
              />
            </div>
          </div>
        }
        onClose={() => {}}
      />
    </div>
  );
};

export default CreateOrganization;
