import { Plus } from 'lucide-react';
import Button from '../../../common/Button';
import UserNotFound from './UserNotFound';
import CreateUserModal from './CreateUserModal';
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import Badge from '../../../common/Badge';
import Table from '../../../common/Table';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { IOgrganizationMember } from '../../../../@types/organization';
import { useTranslation } from 'react-i18next';
import { MAP_ORG_MEMBER_STATUS } from '../../../../config/data';
import { useParams } from 'react-router-dom';
import { fetchOrganizationMembers } from '../../../../api/organization';
import Toggle from '../../../common/Toggle';

const RenderUserList: FC<{
  items: IOgrganizationMember[];
  onEditUser: (email: string) => void;
}> = ({ items, onEditUser }) => {
  const { t } = useTranslation();
  const processItems = useMemo(
    () =>
      items.map((item, index) => ({
        id: index + 1,
        info: {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email
        },
        role: item.role,
        userTypes: item.userTypes,
        orgMemberStatus: {
          name: t(`data.${item.orgMemberStatus}`),
          description: t(`data.${MAP_ORG_MEMBER_STATUS[item.orgMemberStatus].description}`)
        },
        active: item.confirm
      })),
    [items, t]
  );
  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        cell: (row) => {
          return <div className="text-lg">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Name',
        cell: (row) => {
          const info = row.getValue();
          return (
            <div>
              <div className="font-bold text-base">
                {_.get(info, 'firstName')}, {_.get(info, 'lastName')}
              </div>
              <div className="font-normal text-sm text-gray-secondary">{_.get(info, 'email')}</div>
            </div>
          );
        },
        accessorKey: 'info'
      },
      {
        header: 'Role Description',
        cell: (row) => {
          return <div className="text-sm font-medium">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'role'
      },
      {
        header: 'User-Type',
        cell: (row) => {
          const userTypes = (row.getValue() as string[]) || [];
          return (
            <div className="flex flex-col gap-1">
              {userTypes.map((item) => (
                <Badge text={item} variant="light" state="fill" round="full-round" size="medium" />
              ))}
            </div>
          );
        },
        accessorKey: 'userTypes'
      },
      {
        header: 'Org-Member Status',
        cell: (row) => {
          const value = row.getValue() as any;
          return (
            <div>
              <div className="text-base font-medium">{value.name}</div>
              <div className="font-normal text-sm text-gray-secondary">{value.description}</div>
            </div>
          );
        },
        accessorKey: 'orgMemberStatus'
      },
      {
        header: 'Is active?',
        cell: (row) => {
          const active = row.getValue() as boolean;
          return <Toggle checked={active} lableRight={active ? 'Yes' : 'No'} />;
        },
        accessorKey: 'active'
      },
      {
        header: '',
        cell: (row) => {
          const value = row.getValue() as any;
          return (
            <div className="flex items-center gap-4 justify-end">
              <Button
                label="Edit"
                size="small"
                className="!w-[88px]"
                onClick={() => onEditUser(value.email)}
              />
            </div>
          );
        },
        accessorKey: 'info',
        id: 'action'
      }
    ],
    [onEditUser]
  );

  return (
    <div className="mt-7 w-full">
      <Table data={processItems} columns={cols} />
    </div>
  );
};

const UsersManagement = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { members } = useAppSelector((state) => state.organization);
  const [selectedMember, setSelectedMember] = useState<IOgrganizationMember>();
  const { organizationId } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchOrganizationMembers(organizationId));
    }
  }, [dispatch, organizationId]);

  const onEditUser = useCallback(
    (email: string) => {
      const matchMember = members?.find((member) => member.email === email);
      if (matchMember) {
        setSelectedMember(matchMember);
        setOpenCreateModal(true);
      }
    },
    [members]
  );

  return (
    <div className="w-full flex flex-col px-[27px]">
      <div className="flex justify-between items-center">
        <div>
          <div className="text-xl font-semibold">User Management</div>
          <div className="text-sm text-gray-8">Update your personal details here.</div>
        </div>
        <Button
          onClick={() => setOpenCreateModal(true)}
          label="Create New Member"
          rightIcon={<Plus />}
        />
      </div>
      {members?.length ? (
        <RenderUserList items={members} onEditUser={onEditUser} />
      ) : (
        <UserNotFound />
      )}
      <CreateUserModal
        user={selectedMember}
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
      />
    </div>
  );
};

export default UsersManagement;
