import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isInvite?: boolean;
}

const LogoWithHeader: FC<Props> = ({ isInvite }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center mb-8">
      <img className="h-[70px] w-fit" src="/assets/images/logo.png" alt="Logo" />
      <div className="text-[40px] mt-6 mb-2 leading-[50px] font-semibold">Process Bee</div>
      <div className="text-sm text-sub text-center">
        {isInvite ? (
          <div>
            Hello <strong className="text-black-2">Malte</strong> , you have been invited to join our
            processbee...
          </div>
        ) : (
          t('welcomeText')
        )}
      </div>
    </div>
  );
};

export default LogoWithHeader;
