import { Plus } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import Card from '../../../common/Card';
import CardHeader from '../../../common/CardHeader';
import Icon from '../../../common/Icon';
import UserInfoItem from '../../../common/UserInfoItem';
import FormCard from '../../../common/FormCard';
import TextArea from '../../../common/TextArea';
import Select from '../../../common/Select';
import { useState } from 'react';
import ModalUploadDocument from '../ModalUploadContent';
import Input from '../../../common/Input';

const SecondStep = () => {
  const [uploadModal, setUploadModal] = useState(false);
  const [inputs, setInputs] = useState<any[]>([{ id: uuidv4(), value: '' }]);
  const [selectedInput, setSelectedInput] = useState<string>(inputs[0].id);
  const handleAddInput = () => {
    const newInput = { id: uuidv4(), value: '' };
    setInputs([...inputs, newInput]);
  };

  const handleRemoveInput = (id: string) => {
    const newInputs = inputs.filter((input) => input.id !== id);
    setInputs(newInputs);
  };

  return (
    <Card className="!w-full">
      <CardHeader>
        <UserInfoItem
          role="Senior Software Developer"
          firstName="Kenny"
          lastName="John"
          department="Marketing"
        />
      </CardHeader>
      <ol className="relative mt-7 border-s border-[#DEDEDE]">
        <li className="ms-4 mb-3">
          <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
          <div className="-translate-y-1">
            <div className="flex justify-between">
              <div className="flex gap-1">
                {inputs.map((input, index) => (
                  <div
                    onClick={() => setSelectedInput(input.id)}
                    key={input.id}
                    className={`flex items-center gap-1 cursor-pointer ${selectedInput === input.id ? 'opacity-100' : 'opacity-20'}`}>
                    <Icon name="badge-input" size={16} />
                    <div className="text-base font-semibold">Input {index + 1}</div>
                    {selectedInput === input.id ? (
                      <Icon name="x" onClick={() => handleRemoveInput(input.id)} />
                    ) : (
                      <Icon name="badge-info" />
                    )}
                  </div>
                ))}
              </div>

              <div className="flex items-center gap-1">
                <Icon name="badge-input-2" size={16} />
                <div className="text-xs font-medium text-gray-12">Add Input</div>
                <div
                  className="w-5 h-5 rounded-full border border-border-15 flex items-center justify-center cursor-pointer"
                  onClick={handleAddInput}>
                  <Plus size={16} />
                </div>
              </div>
            </div>
            <Input className="text-sm my-2" />
          </div>
        </li>
        <li className="ms-4">
          <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
          <div className="-translate-y-1 mb-2">
            <FormCard theme="gray" icon="steps-active" title="Process Step Description">
              <FormCard theme="light" className="pb-8">
                This step involves taking the approved purchase orders (POs) from the review process
                and converting them into actionable requests for procurement. It ensures that the
                necessary items or services are ordered from vendors efficiently and accurately.
              </FormCard>
              <FormCard theme="light" className="mt-2">
                <div className="grid grid-cols-3 gap-2">
                  <FormCard className="col-span-1" theme="light" icon="rotate" title="System Used">
                    <Select className="!min-w-full" items={[]} onChange={() => {}} />
                  </FormCard>
                  <FormCard
                    className="col-span-1"
                    theme="light"
                    icon="person"
                    title="Who else does this?">
                    <Select className="!min-w-full" items={[]} onChange={() => {}} />
                  </FormCard>
                  <FormCard className="col-span-1" theme="light" icon="document" title="Documents">
                    <div onClick={() => setUploadModal(true)}>
                      <Select className="!min-w-full" items={[]} onChange={() => {}} />
                    </div>
                  </FormCard>
                </div>
              </FormCard>
            </FormCard>
          </div>
        </li>
        <li className="ms-4">
          <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
          <div className="-translate-y-1">
            <div className="flex items-center gap-1">
              <Icon name="badge-input" size={16} />
              <div className="text-base font-semibold">Output</div>
            </div>
            <TextArea className="mt-2" placeholder="Describe the output of this process." />
          </div>
        </li>
      </ol>
      {uploadModal && (
        <ModalUploadDocument open={uploadModal} onClose={() => setUploadModal(false)} />
      )}
    </Card>
  );
};

export default SecondStep;
