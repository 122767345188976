import { Plus } from 'lucide-react';
import Button from '../../../components/common/Button';
import { useAppSelector } from '../../../app/hooks';
import { isOrgManager } from '../../../utils/data';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/router';
import DepartmentList from '../../../components/Data/Department/ListDepartment';

const Department = () => {
  const { userTypes } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/data/${ROUTES.DATA_DEPARTMENT_CREATE}`);
  };
  return (
    <div>
      <DepartmentList
        header={
          isOrgManager(userTypes) ? (
            <Button
              onClick={handleClick}
              size="small"
              label="Create New Department"
              rightIcon={<Plus />}
            />
          ) : null
        }
      />
    </div>
  );
};

export default Department;
