import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MainLayout from './MainLayout';
import { useEffect } from 'react';
import { profile } from '../../api/auth';
import { setLoading } from '../../app/slices/auth.slice';

const PrivateRoutes = () => {
  const { user, loading } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(profile());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      dispatch(setLoading(false));
    }
  }, [user, dispatch]);

  if (loading) {
    return null;
  }

  return <MainLayout />;
};

export default PrivateRoutes;
