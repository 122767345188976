import { useState } from 'react';

const MAX = [1, 2, 3, 4, 5];
const Rating = () => {
  const [currentHover, setCurrentHover] = useState<number>(1);
  return (
    <div className="flex">
      {MAX.map((item) => (
        <div className="cursor-pointer">
          <svg
            onMouseEnter={() => setCurrentHover(item)}
            onMouseLeave={() => setCurrentHover(1)}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_56703_42123)">
              <path
                d="M12.8614 3.61455C13.1608 2.69324 14.4642 2.69324 14.7636 3.61455L16.6189 9.32479C16.7528 9.73681 17.1368 10.0158 17.57 10.0158H23.5741C24.5428 10.0158 24.9456 11.2554 24.1619 11.8248L19.3045 15.3539C18.954 15.6086 18.8073 16.0599 18.9412 16.4719L20.7965 22.1822C21.0959 23.1035 20.0414 23.8696 19.2577 23.3002L14.4003 19.7711C14.0498 19.5165 13.5752 19.5165 13.2247 19.7711L8.3673 23.3002C7.58359 23.8696 6.5291 23.1035 6.82846 22.1822L8.68382 16.4719C8.8177 16.0599 8.67104 15.6086 8.32055 15.3539L3.46314 11.8248C2.67942 11.2554 3.0822 10.0158 4.05092 10.0158H10.055C10.4882 10.0158 10.8722 9.73681 11.0061 9.32479L12.8614 3.61455Z"
                fill={item <= currentHover ? '#FFC700' : '#EBEBEB'}
              />
            </g>
            <defs>
              <clipPath id="clip0_56703_42123">
                <rect width="27" height="27" fill="white" transform="translate(0.3125 0.6875)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      ))}
    </div>
  );
};

export default Rating;
