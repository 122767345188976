import { createSlice } from '@reduxjs/toolkit'
import { DataPageEnum } from '../../constants/data'



const defaultState = {
    page: DataPageEnum.ALL_PROCESS,
}

const initialState = defaultState
const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        changePage: (state, action) => {
            state.page = action.payload
        },

    },
})

export const { changePage } = dataSlice.actions

export default dataSlice.reducer