import DatePicker from '../../../common/DatePicker';
import Select from '../../../common/Select';
import Toggle from '../../../common/Toggle';
import { LICENSE_MODEL, LicenseModelType } from '../../../../config/data';
import { useTranslation } from 'react-i18next';
import { setCreateData } from '../../../../app/slices/organization';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

const LicenseInformation = () => {
  const { t } = useTranslation();
  const { createData } = useAppSelector((state) => state.organization);
  const dispatch = useAppDispatch();
  const licenseModelData = LICENSE_MODEL.map((item) => {
    const value = item.type;
    const label = (
      <div className="text-left">
        <div className="font-medium text-sm">{t(`data.${item.name}`)}</div>
        <div className="text-xs text-gray-secondary">
          {item.isLimitless
            ? t('data.limitless')
            : `${item.description} ${t('data.licensedUsers')}`}
        </div>
      </div>
    );
    return { value, label };
  });
  const handleChangeValue = (key: string, value: any) => {
    dispatch(setCreateData({ key, value }));
  };

  const handleChangeModel = (type: LicenseModelType) => {
    const matchType = LICENSE_MODEL.find((item) => item.type === type);
    handleChangeValue('license.model', {
      type: matchType?.type,
      size: {
        min: matchType?.size.min || undefined,
        max: matchType?.size.max || undefined
      }
    });
  };

  return (
    <div className="min-w-[930px] mt-10">
      <div>
        <div className="text-xl font-semibold">License Information</div>
        <div className="text-sm text-gray-8">
          Enter the current license model of this organization.
        </div>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Contract Start Date*</div>
            <DatePicker
              value={createData?.license?.startDate || null}
              onChange={(value) => handleChangeValue('license.startDate', value)}
              placeholder="DD/MM/YYYY"
              dateFormat="DD/MM/YYYY"
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Contract End Date*</div>
            <DatePicker
              value={createData?.license?.endDate || null}
              onChange={(value) => handleChangeValue('license.endDate', value)}
              placeholder="DD/MM/YYYY"
              dateFormat="DD/MM/YYYY"
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">License Model*</div>
            <Select
              containerClassName={'max-h-[300px]'}
              items={licenseModelData}
              onChange={(value) => handleChangeModel(value)}
              value={createData?.license?.model?.type}
            />
          </div>
          <div className="flex gap-2 flex-col text-base font-medium text-gray-900">
            <div>Manual License Status</div>
            <div className="flex items-center justify-between h-full">
              <div>Is the organization active?</div>
              <Toggle
                onChange={(value) => handleChangeValue('license.manualStatus', value)}
                checked={createData?.license?.manualStatus}
                lableRight={createData?.license?.manualStatus ? 'On' : 'Off'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseInformation;
