import { FC } from 'react';
import DP from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Props } from './@types/datepicker';
const DatePicker: FC<Props> = ({ value, onChange, placeholder, dateFormat }) => {
  return (
    <DP
      className="w-full h-[48px] outline-none p-3 text-base ease-in duration-100 rounded-lg border-[1px] focus:border-[3px] flex items-center justify-between gap-3 border-normal focus:border-blue-light-3 hover:border-blue-primary"
      selected={value}
      onChange={onChange}
      wrapperClassName="min-w-[100px] w-full"
      placeholderText={placeholder}
      dateFormat={dateFormat}
    />
  );
};

export default DatePicker;
