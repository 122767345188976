import { useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { setContentSpacing } from '../../../../app/slices/layout.slice';
import SurveyContent from '../Survey/SurveyContent';

const Survey = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setContentSpacing(false));
    return () => {
      dispatch(setContentSpacing(true));
    };
  }, [dispatch]);
  return (
    <div className="h-full flex">
      <div className="bg-white h-full px-9 w-[400px] flex flex-col items-center py-4 justify-between">
        <div className="flex flex-col items-center">
          <img src="/assets/images/asking-question.png" alt="" />
          <div className="font-bold text-2xl mb-2">Process Bee Survey</div>
          <div className="text-sm text-gray-500">
            This survey will help your company to improve.
          </div>
          <div className="text-sm text-gray-500">Good job!</div>
        </div>
        <div className="flex items-center text-xs">
          <div>Created by</div>
          <img className="w-[16px] ml-1.5 mr-1" src="/assets/images/logo.png" alt="" />
          <div className="font-medium">Process Bee</div>
        </div>
      </div>
      <div className="bg-bg-5 h-full p-7 flex-1">
        <SurveyContent />
      </div>
    </div>
  );
};

export default Survey;
