import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from '../libs/agent'
import { ICreateProcess, Process } from "../@types/process";

export const createProcess = createAsyncThunk("process/create", async (data: ICreateProcess): Promise<[]> => {
    const response = await agent.post(`processes`, data);
    return response.data.data
})

export const fetchAllProcess = createAsyncThunk("process/all", async (): Promise<Process[]> => {
    const response = await agent.get(`processes/all`);
    return response.data.data
})

