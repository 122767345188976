import { useAppDispatch } from '../../app/hooks';
import { logout } from '../../app/slices/auth.slice';
import { ROUTES } from '../../constants/router';
import { useLocation, useNavigate } from 'react-router-dom';

const MENU_ITEMS = [
  {
    key: 'dashboard',
    route: ROUTES.DASHBOARD,
    icon: <img className="w-[29px] h-[29px]" src="/assets/icons/graph.svg" alt="icon" />,
    iconActive: <img className="w-[29px] h-[29px]" src="/assets/icons/bold/graph.svg" alt="icon" />
  },
  {
    key: 'data',
    route: ROUTES.DATA,
    icon: <img className="w-[29px] h-[29px]" src="/assets/icons/note-2.svg" alt="icon" />,
    iconActive: (
      <img className="w-[29px] h-[29px]" src="/assets/icons/bold/note-21.svg" alt="icon" />
    )
  },
  {
    key: 'notifications',
    route: ROUTES.NOTIFICATION,
    icon: <img className="w-[29px] h-[29px]" src="/assets/icons/notification.svg" alt="icon" />,
    iconActive: (
      <img className="w-[29px] h-[29px]" src="/assets/icons/bold/notification.svg" alt="icon" />
    )
  },
  {
    key: 'profiles',
    route: ROUTES.PROFILE,
    icon: <img className="w-[29px] h-[29px]" src="/assets/icons/profile-circle.svg" alt="icon" />,
    iconActive: (
      <img className="w-[29px] h-[29px]" src="/assets/icons/bold/profile-circle.svg" alt="icon" />
    )
  },
  {
    key: 'settings',
    route: ROUTES.SETTING,
    icon: <img className="w-[29px] h-[29px]" src="/assets/icons/setting-2.svg" alt="icon" />,
    iconActive: (
      <img className="w-[29px] h-[29px]" src="/assets/icons/bold/setting-2.svg" alt="icon" />
    )
  }
];

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [, path] = location.pathname.split('/');
  const handleClickItem = (route: string) => {
    navigate(route);
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="w-[102px] min-h-[100vh] px-5 py-7 border-border-2 border-r-[1.2px] flex flex-col justify-between">
      <div>
        <div className="w-[64px] h-[64px] border-border-1 border-[1px] flex items-center justify-center rounded-full">
          <img className="h-[31px] w-fit" src="/assets/images/logo.png" alt="Logo" />
        </div>
        <div className="flex flex-col gap-14 justify-center items-center mt-10">
          {MENU_ITEMS.map((item) => (
            <div
              className="cursor-pointer"
              key={item.key}
              onClick={() => handleClickItem(item.route)}>
              {path === item.key ? item.iconActive : item.icon}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-14 justify-center items-center pb-14">
        <img className="w-[29px] h-[29px] cursor-pointer" src="/assets/icons/help.svg" alt="icon" />
        <img className="w-[29px] h-[29px] cursor-pointer" src="/assets/icons/headphones.svg" alt="icon" />
        <img
          onClick={handleLogout}
          className="w-[29px] h-[29px] cursor-pointer"
          src="/assets/icons/login.svg"
          alt="icon"
        />
      </div>
    </div>
  );
};

export default Sidebar;
