import Button from '../common/Button';
import Input from '../common/Input';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { signUp, verifyToken } from '../../api/auth';
import { ROUTES } from '../../constants/router';
import { User } from '../../@types/user';
import PasswordInput from '../common/PasswordInput';
import {
  checkLowerCaseRegex,
  checkNumericRegex,
  checkSpecialCharRegex,
  checkUpperCaseRegex
} from '../../libs/helper';

const SignUpForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const { loading } = useAppSelector((state) => state.auth);
  const [tokenData, setTokenData] = useState<User>();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const passwordQuantity = useRef({
    length: false,
    uppercase: false,
    lowercase: false,
    numeric: false,
    special: false
  });

  const handleSignUp = useCallback(async () => {
    if (token) {
      await dispatch(
        signUp({
          password,
          resetPasswordToken: token
        })
      );
    }
    navigate(ROUTES.LOGIN);
  }, [password, token, navigate, dispatch]);

  useEffect(() => {
    const fetchingToken = async () => {
      if (token && dispatch) {
        const result = await dispatch(verifyToken(token));
        if (!result.payload) {
          window.alert('Token invalid or expired');
        }
        setTokenData(result.payload);
      }
    };
    fetchingToken();
  }, [token, dispatch]);

  useEffect(() => {
    if (password) {
      passwordQuantity.current = {
        length: password.length > 5,
        uppercase: checkUpperCaseRegex(password),
        lowercase: checkLowerCaseRegex(password),
        numeric: checkNumericRegex(password),
        special: checkSpecialCharRegex(password)
      };
    }
  }, [password]);

  const isPasswordError = useMemo(() => {
    if (password) {
      const isError = Object.values(passwordQuantity.current).some((val) => !val);
      return isError;
    }
  }, [password]);

  const renderHelperText = useMemo(() => {
    if (password) {
      return (
        <div className="col-span-2">
          {confirmPassword && confirmPassword !== password ? (
            <div className="text-red-600">{t('passwordNotMatch')}</div>
          ) : null}
          {isPasswordError ? (
            <ul className="list-disc ml-[14px]">
              <li
                className={`${passwordQuantity.current.length ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordLength')}
              </li>
              <li
                className={`${passwordQuantity.current.uppercase ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordUpper')}
              </li>
              <li
                className={`${passwordQuantity.current.lowercase ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordLower')}
              </li>
              <li
                className={`${passwordQuantity.current.numeric ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordNumeric')}
              </li>
              <li
                className={`${passwordQuantity.current.special ? 'text-green-600' : 'text-red-600'}`}>
                {t('alertPasswordSpecial')}
              </li>
            </ul>
          ) : null}
        </div>
      );
    }
  }, [password, confirmPassword, isPasswordError, t]);

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <Input value={tokenData?.firstName} disabled />
        <Input value={tokenData?.lastName} disabled />
        <div className="col-span-2">
          <Input value={tokenData?.email} disabled />
        </div>
        <PasswordInput onChange={(e) => setPassword(e.target.value)} placeholder={t('password')} />
        <PasswordInput
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder={t('confirmPassword')}
        />
        {renderHelperText}
      </div>
      <div className="mt-4 flex gap-2">
        <Button
          theme="dark"
          onClick={handleSignUp}
          className="w-full"
          label={t('signUp')}
          loading={loading}
        />
      </div>
      <div className="mt-2 text-[10px] text-gray-secondary text-center">
        {t('byContinueTerm')} <span className="underline">{t('termOfService')} </span> {t('and')}{' '}
        <span className="underline">{t('privacyPolicy')}</span>.
      </div>
    </div>
  );
};

export default SignUpForm;
