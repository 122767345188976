import { useSearchParams } from 'react-router-dom';
import AssignmentValidation from '../../../components/Data/Process/AssignmentValidation';
import { useAppDispatch } from '../../../app/hooks';
import { useEffect } from 'react';
import { setContentBackground } from '../../../app/slices/layout.slice';
import ProcessFlow from '../../../components/Data/Process/Flow';

const ProcessDetail = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setContentBackground(true));
    return () => {
      dispatch(setContentBackground(false));
    };
  }, [dispatch]);

  return (
    <div>{searchParams.get('view') === 'review' ? <AssignmentValidation /> : <ProcessFlow />}</div>
  );
};

export default ProcessDetail;
