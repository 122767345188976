import React, { PropsWithChildren, useState } from 'react';
import Checkbox from './Checkbox';

type Props = PropsWithChildren & {
  list: { name: string; id: string; type?: string }[];
  values: string[];
  onChange: (values: string) => void;
};

function ColumnSettingDisplayDropdown(props: Props) {
  const [show, setShow] = useState(false);

  return (
    <div className={'relative z-10'}>
      <div onClick={() => setShow((prev) => !prev)}>{props.children}</div>

      {show && (
        <div className="border setting-box border-border-16 p-4 rounded-lg absolute mt-3 z-100 bg-white right-0 min-w-[235px]">
          <div className="text-sm font-bold">Display Columns</div>
          <div className="mt-2 flex flex-col gap-1">
            {props.list.map((item) => {
              return (
                <div className="p-2 pl-0" key={item.id}>
                  <Checkbox
                    disabled={item.type !== 'optional'}
                    checked={props.values.includes(item.id)}
                    value={item.id}
                    label={item.name}
                    onChange={() => props.onChange(item.id)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ColumnSettingDisplayDropdown;
