import ListOrganization from '../../../components/Data/Oranization/Create/ListOrganization';
import SvgIcon from '../../../components/common/SvgIcon';
import Select from '../../../components/common/Select';
import { useEffect } from 'react';
import * as _ from 'lodash';
import Button from '../../../components/common/Button';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { listIndustryClassification } from '../../../api/industry-classification';
import { LICENSE_MODEL } from '../../../config/data';
import { useTranslation } from 'react-i18next';
import { setFilter } from '../../../app/slices/organization';

const Organization = () => {
  const { filters } = useAppSelector((state) => state.organization);
  const { t } = useTranslation();
  const { list: industryClassifications } = useAppSelector((state) => state.industryClassification);
  const dispatch = useAppDispatch();

  return (
    <div>
      {/*<div className={'flex items-center justify-end mb-4'}>*/}
      {/*  <div className={'flex items-center gap-2'}>*/}
      {/*    <Select*/}
      {/*      className={'grow-2 max-w-[238px] h-[51px]'}*/}
      {/*      items={_.orderBy(industryClassifications, 'class').map((item) => ({*/}
      {/*        label: (*/}
      {/*          <div className={'flex items-center gap-1.5'}>*/}
      {/*            <div>Class {item.class}</div>*/}
      {/*          </div>*/}
      {/*        ),*/}
      {/*        value: item._id*/}
      {/*      }))}*/}
      {/*      onValueRender={() => (*/}
      {/*        <>*/}
      {/*          <SvgIcon name={'list-filter'} />*/}
      {/*          <div>Industry</div>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      placeholder={'Select'}*/}
      {/*      onChange={onChangeIndustry}*/}
      {/*      value={filters?.industries}*/}
      {/*      checkbox*/}
      {/*    />*/}

      {/*    <Select*/}
      {/*      className={'grow-2 max-w-[238px] h-[51px]'}*/}
      {/*      items={licenseModelData}*/}
      {/*      onValueRender={() => (*/}
      {/*        <div className={'flex items-center gap-1.5'}>*/}
      {/*          <SvgIcon name={'list-filter'} />*/}
      {/*          <div>License</div>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*      checkbox*/}
      {/*      placeholder={'Select'}*/}
      {/*      onChange={onChangeLicense}*/}
      {/*      value={filters?.licenses}*/}
      {/*    />*/}

      {/*    <Button className={'w-[51px] h-[51px]'} label={<SvgIcon name={'setting'} size={20} />} />*/}
      {/*  </div>*/}
      {/*</div>*/}

      <ListOrganization />
    </div>
  );
};

export default Organization;
