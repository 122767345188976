import React, { useState } from 'react';
import CardHeader from '../../common/CardHeader';
import Icon from '../../common/Icon';
import Button from '../../common/Button';
import Card from '../../common/Card';
import Modal from '../../common/Modal';
import SvgIcon from '../../common/SvgIcon';
import FormCard from '../../common/FormCard';
import Select from '../../common/Select';
import CheckCard from './CheckCard';
import PeopleSelect from './PeopleSelect';
import SelectUserInfo from './SelectUserInfo';

const SAMPLE_PEOPLE = [
  {
    id: '1',
    role: 'Senior Designer',
    firstName: 'Alan',
    lastName: 'Thomas',
    department: 'Design'
  },
  {
    id: '2',
    role: 'UI/UX Designer',
    firstName: 'Dam',
    lastName: 'Simon',
    department: 'Design'
  },
  {
    id: '3',
    role: 'Senior Product Manager',
    firstName: 'Ricky',
    lastName: 'Bruce',
    department: 'Marketing'
  }
];

const DEFAULT_FORM = {
  people: ''
};

function SuccessorCard() {
  const [list, setList] = useState<Record<string, any>[]>([]);
  const [form, setForm] = useState({ ...DEFAULT_FORM });
  const [show, setShow] = useState(false);

  const hdChange = (key: string, value: string) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const hdSubmit = () => {
    setList((prev) => [
      ...prev,
      SAMPLE_PEOPLE.find((peo) => peo.id === form.people) as Record<string, any>
    ]);
    setShow(false);
    setForm({ ...DEFAULT_FORM });
  };

  return (
    <>
      <Card className='!w-full'>
        <CardHeader>
          <div className={'flex items-center gap-[8px] w-full'}>
            <SvgIcon name="tab-right" size={21} color={'#020202'} />
            <div className={'text-lg text-[#010101]'}>Successor</div>
          </div>
          <div className={'mt-1 text-sm text-[#000000]'}>
            Choose all <b>successors</b> who will receive your output.
          </div>
        </CardHeader>

        {list.length === 0 && (
          <div className={'flex flex-col items-center gap-2'}>
            <img
              className={'w-[116px] h-[116px'}
              src={'/assets/images/same-day.png'}
              alt={'same-day'}
            />
            <div className={'text-[#6B7280] text-xs leading-[15.12px]'}>
              A successor can be a person, a system or an inbox.
            </div>
          </div>
        )}

        {list.length > 0 && (
          <FormCard
            title={'Successor'}
            theme={'light'}
            rightContent={
              <div className={'flex items-center gap-[6px]'}>
                <div className={'flex justify-center items-center w-[24px] h-[24px]'}>
                  <SvgIcon name={'trash'} size={18} color={'#919191'} />
                </div>
                <div className={'flex justify-center items-center w-[24px] h-[24px]'}>
                  <SvgIcon name={'pen'} size={16} color={'#919191'} />
                </div>
              </div>
            }>
            <div className={'flex flex-col gap-2'}>
              {list.map((person) => (
                <div
                  key={person.id}
                  className={'border rounded-[12px] border-[#DFE4EA] px-[14.92px] py-[13.05px]'}>
                  <SelectUserInfo
                    firstName={person.firstName}
                    lastName={person.lastName}
                    role={person.role}
                    department={person.department}
                  />
                </div>
              ))}
            </div>
          </FormCard>
        )}

        <Button
          onClick={() => setShow(true)}
          label={'Add a Successor'}
          className={'w-full mt-4'}
          rightIcon={<Icon name={'plus'} size={20} />}
        />
      </Card>

      <Modal
        noCloseIcon
        open={show}
        content={
          <div className="min-w-[679px] flex flex-col justify-start items-start gap-4">
            <div className={'rounded-2xl p-[16.5px] border border-[#DEDEDE]'}>
              <SvgIcon color={'#898989'} name={'tab-right'} size={30} />
            </div>

            <div>
              <h1 className={'text-2xl font-semibold'}>Add a Successor</h1>
              <div className={'text-base leading-7'}>
                Choose a <b>successor</b> who will receive your output.
              </div>
            </div>

            <div className="w-full flex flex-col gap-2">
              <FormCard
                desc={'This person will receive an invitation to describe the next process step.'}
                theme={'light'}
                icon={'person'}
                title={'Person'}
                rightContent={
                  !form.people ? null : (
                    <div
                      className={
                        'flex items-center gap-[3.4px] py-[5px] px-[6px] rounded-[96px] bg-[#FFECEC]'
                      }>
                      <div
                        className={
                          'text-sm select-none text-[#FB0101] leading-[15px] cursor-pointer'
                        }
                        onClick={() => hdChange('people', '')}>
                        Remove
                      </div>
                      <SvgIcon name={'circle-cancel'} size={15} />
                    </div>
                  )
                }>
                <PeopleSelect
                  value={form.people}
                  options={SAMPLE_PEOPLE.map((item) => ({
                    label: item.firstName,
                    value: item.id.toString(),
                    origin: { ...item }
                  }))}
                  onChange={(v) => hdChange('people', v)}
                />
              </FormCard>

              <div className={'flex gap-2'}>
                <CheckCard
                  disabled={!!form.people}
                  icon={'circle-help'}
                  title={'Unclear'}
                  desc={'The process will end here'}
                />
                <CheckCard
                  disabled={!!form.people}
                  icon={'circle-off'}
                  title={'None'}
                  desc={'The process will end here'}
                />
              </div>
            </div>

            <FormCard theme={'gray'} title={'Person'} disabled={!!form.people}>
              <div className="flex flex-col gap-2">
                <FormCard
                  desc={'This person will receive an invitation to describe the next process step.'}
                  theme={'light'}
                  icon={'inbox'}
                  title={'Shared Inbox'}>
                  <Select
                    disabled={!!form.people}
                    items={[]}
                    placeholder={'Select'}
                    onChange={(v) => hdChange('people', v)}
                  />
                </FormCard>

                <FormCard
                  desc={'This person will receive an invitation to describe the next process step.'}
                  theme={'light'}
                  icon={'fan'}
                  title={'Software/System'}>
                  <Select
                    disabled={!!form.people}
                    items={[]}
                    placeholder={'Select'}
                    onChange={(v) => hdChange('people', v)}
                  />
                </FormCard>

                <FormCard
                  desc={'This person will receive an invitation to describe the next process step.'}
                  theme={'light'}
                  icon={'at-sign'}
                  title={'External Contact'}>
                  <Select
                    disabled={!!form.people}
                    items={[]}
                    placeholder={'Select'}
                    onChange={(v) => hdChange('people', v)}
                  />
                </FormCard>
              </div>
            </FormCard>

            <div className={'w-full flex gap-4 items-center'}>
              <Button className={'flex-1'} label={'Cancel'} onClick={() => setShow(false)} />
              <Button
                disabled={!form.people}
                className={'flex-1'}
                label={'Continue'}
                rightIcon={<Icon name={'arrow-right'} size={20} />}
                onClick={hdSubmit}
              />
            </div>
          </div>
        }
        onClose={() => setShow(false)}
      />
    </>
  );
}

export default SuccessorCard;
