import { FC, ReactNode, useEffect, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Table from '../../common/Table';
import { fetchDeparments } from '../../../api/department';
import { User } from '../../../@types/user';
import Button from '../../common/Button';
import { ROUTES } from '../../../constants/router';
import { useNavigate } from 'react-router-dom';

interface IProps {
  header?: ReactNode;
}

const DepartmentList: FC<IProps> = ({ header }) => {
  const { listDepartment } = useAppSelector((state) => state.department);
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchDeparments());
  }, [dispatch, user]);

  const processItems = useMemo(
    () =>
      listDepartment.map((item, index) => ({
        _id: item._id,
        id: index + 1,
        name: item.name,
        abbreviation: item.abbreviation,
        manager: item.manager
      })),
    [listDepartment]
  );
  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        cell: (row) => {
          return <div className="text-lg">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Name',
        cell: (row) => {
          const name = row.getValue() as any;
          return <div className="font-bold text-base">{name}</div>;
        },
        accessorKey: 'name'
      },
      {
        header: 'Abbreviation',
        cell: (row) => {
          const abbreviation = row.getValue() as any;
          return <div className="font-bold text-base">{abbreviation}</div>;
        },
        accessorKey: 'abbreviation'
      },
      {
        header: 'Manager',
        cell: (row) => {
          const user = row.getValue() as User;
          return (
            <div>
              <div className="font-bold text-base">
                {user?.firstName}, {user?.lastName}
              </div>
              <div className="font-normal text-sm">{user?.role}</div>
            </div>
          );
        },
        accessorKey: 'manager'
      },
      {
        header: '',
        cell: (row) => {
          const _id = row.getValue() as string;
          return (
            <Button
              onClick={() => navigate(`${ROUTES.DATA}/${ROUTES.DATA_DEPARTMENT_EDIT}/${_id}`)}
              label="Edit"
              size="small"
              className="!h-[52px] !w-[80px] ml-auto"
            />
          );
        },
        accessorKey: '_id'
      }
    ],
    [navigate]
  );

  return (
    <div className="w-full">
      <Table header={header} data={processItems} columns={cols} enableSetting />
    </div>
  );
};

export default DepartmentList;
