import Icon from '../../../common/Icon';
import UserInfoItem from '../../../common/UserInfoItem';
import { ChevronDown, ChevronUp } from 'lucide-react';
import Card from '../../../common/Card';
import { useState } from 'react';
import FormCard from '../../../common/FormCard';
import Select from '../../../common/Select';
import ModalUploadDocument from '../ModalUploadContent';

const FirstStep = () => {
  const [seeMore, setSeeMore] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  return (
    <div className="flex gap-[15px]">
      <Card className="!w-full">
        <UserInfoItem
          role="Senior Software Developer"
          firstName="Kenny"
          lastName="John"
          department="Marketing"
        />
        <div className="h-[1px] w-full bg-border-12 my-2"></div>
        <div className="">
          <div className="flex items-center gap-1">
            <Icon name="steps-active" size={14} />
            <div className="text-xs font-semibold">Previous Process Step</div>
          </div>
          <div className="text-xs mt-1">
            This step involves reviewing purchase orders (POs) submitted by team members to ensure
            they align with our budget, vendor agreements, and organizational needs. It’s a daily
            routine that ensures we spend wisely and comply with internal policies.
          </div>
        </div>
        <div className="mt-4">
          <div className="flex items-center gap-1">
            <Icon name="output" size={14} />
            <div className="text-xs font-semibold">Output</div>
          </div>
          <div className="text-xs mt-1">Approved Purchase Orders (POs)</div>
        </div>
        {seeMore && (
          <div className="mt-6 grid gap-x-2 gap-y-6 grid-cols-2">
            <FormCard className="col-span-1" theme="light" icon="rotate" title="System Used">
              <Select className="!min-w-full" items={[]} onChange={() => {}} />
            </FormCard>
            <FormCard
              className="col-span-1"
              theme="light"
              icon="person"
              title="Who else does this?">
              <Select className="!min-w-full" items={[]} onChange={() => {}} />
            </FormCard>
            <FormCard className="col-span-2" theme="light" icon="document" title="Documents">
              <div onClick={() => setUploadModal(true)}>
                <Select disabled items={[]} onChange={() => {}} />
              </div>
            </FormCard>
          </div>
        )}
        <div className="h-[1px] w-full bg-border-12 my-2"></div>
        <div
          className="w-full flex items-center justify-center gap-1 cursor-pointer"
          onClick={() => setSeeMore((value) => !value)}>
          <div className="text-xs font-bold">{seeMore ? 'See less' : 'See more'}</div>
          {seeMore ? (
            <ChevronUp style={{ width: '18px' }} />
          ) : (
            <ChevronDown style={{ width: '18px' }} />
          )}
        </div>
      </Card>
      {uploadModal ? (
        <ModalUploadDocument open={uploadModal} onClose={() => setUploadModal(false)} />
      ) : null}
    </div>
  );
};

export default FirstStep;
