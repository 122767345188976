import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { IItem, Props } from '../@types/select';
import Icon from '../Icon';
import _ from 'lodash';
import { X } from 'lucide-react';
import Checkbox from '../Checkbox';
import './style.css';

const Select: FC<Props> = ({
  value,
  onChange,
  items,
  placeholder,
  size = 'small',
  className = '',
  containerClassName = '',
  onValueRender,
  onMenuRender,
  onMenuHeaderRender,
  disabled = false,
  checkbox = false
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const getHeight = useMemo(() => {
    switch (size) {
      case 'small':
        return 'h-[48px]';
      case 'medium':
        return 'h-[54px]';
      case 'large':
        return 'h-[60px]';
    }
  }, [size]);
  const getSpace = useMemo(() => {
    switch (size) {
      case 'small':
        return 'top-[56px]';
      case 'medium':
        return 'top-[62px]';
      case 'large':
        return 'top-[68px]';
    }
  }, [size]);

  const handleChangeValue = useCallback(
    (newValue: string) => {
      if (_.isArray(value)) {
        if (value.includes(newValue)) {
          onChange(value.filter((item) => item !== newValue));
        } else {
          onChange([...value, newValue]);
        }
      } else {
        onChange(newValue);
      }
    },
    [value, onChange]
  );

  const renderSelectContent = useMemo(() => {
    const mapItemsContent = items.reduce((result: any, cur) => {
      result[cur.value] = { ...cur } as IItem;
      return result;
    }, {});
    if (value) {
      if (_.isArray(value) && !checkbox) {
        return (
          <>
            {value.map((item) => (
              <div
                key={item}
                className="bg-[#F3F3F3] min-h-[25px] px-1.5 rounded-full flex items-center justify-between gap-1.5">
                {onValueRender
                  ? onValueRender(mapItemsContent[item])
                  : mapItemsContent[item]?.label}
                <div
                  onClick={() => handleChangeValue(item)}
                  className="w-4 h-4 rounded-full border-border-6 bg-border-6 flex items-center justify-center text-white cursor-pointer">
                  <X size={12} />
                </div>
              </div>
            ))}
          </>
        );
      }
      const matchValue = _.isArray(value) ? value[0] : value;
      return onValueRender ? (
        onValueRender(mapItemsContent[matchValue])
      ) : (
        <div style={{ width: _.get(ref.current, 'offsetWidth', 0) - 60 }} className="truncate">
          {mapItemsContent[matchValue]?.label}
        </div>
      );
    }
    if (placeholder) {
      return <div className="text-base text-[#9CA3AF]">{placeholder}</div>;
    }
  }, [placeholder, value, handleChangeValue, items, onValueRender]);

  const renderOptions = useMemo(() => {
    if (disabled || !open) return null;

    if (onMenuRender)
      return (
        <div
          className={`w-full absolute z-50 left-0 ${getSpace} h-auto box-border`}
          onClick={(e) => e.stopPropagation()}>
          {onMenuRender(items, {
            onSelectItem: (item: IItem) => {
              handleChangeValue(item.value);
              setOpen(false);
            }
          })}
        </div>
      );

    return (
      <div
        className={`flex flex-col gap-2 absolute w-full rounded-lg border-[1px] p-2 ${getSpace} left-0 bg-white z-50 max-h-[200px] overflow-y-auto component-select-menu ${containerClassName}`}>
        {onMenuHeaderRender && onMenuHeaderRender()}
        {items.map((item) => (
          <div
            key={item.value}
            onClick={() => handleChangeValue(item.value)}
            className={`outline-none cursor-pointer px-2 py-1 hover:bg-gray-2 rounded-lg font-medium`}>
            {checkbox ? (
              <Checkbox
                label={item.label}
                value={item.value}
                checked={value?.includes(item.value)}
              />
            ) : (
              item.label
            )}
          </div>
        ))}
      </div>
    );
  }, [items, disabled, open, getSpace, handleChangeValue, onMenuRender]);

  return (
    <div
      ref={ref}
      className={`${className} ${getHeight} relative min-w-[200px] outline-none p-3 text-base ease-in duration-100 rounded-lg border-[1px] focus:border-[3px] flex items-center justify-between gap-3 border-normal ${disabled ? 'bg-gray-2 border-gray-2' : 'focus:border-blue-light-3 hover:border-blue-primary'} `}
      onClick={() => setOpen((prev) => !prev)}>
      {renderSelectContent}
      {disabled ? null : <Icon name="chevron-down" size={14} className="ml-auto" />}
      {renderOptions}
    </div>
  );
};

export default Select;
