import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { useMemo } from 'react';
import _ from 'lodash';
import { DATA_ROUTES, ROUTES } from '../../constants/router';
import { isOrgManager, isSuperAdmin } from '../../utils/data';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import SvgIcon from '../../components/common/SvgIcon';
import { Plus } from 'lucide-react';

const DataPage = () => {
  const { userTypes } = useAppSelector((state) => state.auth);
  const { contentBackground, contentSpacing } = useAppSelector((state) => state.layout);
  const location = useLocation();
  const path = _.last(_.split(location.pathname, '/'));
  const navigate = useNavigate();

  const userItems = useMemo(() => {
    return DATA_ROUTES.filter(
      (item) => !item.permissions || _.intersection(item.permissions, userTypes).length
    );
  }, [userTypes]);

  const renderRightContent = () => {
    if (isOrgManager(userTypes) && location.pathname.includes('process')) {
      return (
        <div className="mb-3">
          <Button
            onClick={() => handleClickItem(ROUTES.DATA_PROCESS_CREATE)}
            size="small"
            label="Create Process"
            rightIcon={<Icon name="calendar" />}
          />
        </div>
      );
    }

    if (isSuperAdmin(userTypes) && location.pathname.includes(ROUTES.DATA_ORGANIZATION)) {
      return (
        <div className="mb-4 flex justify-end">
          <Button
            onClick={() => navigate(`/data/${ROUTES.DATA_ORGANIZATION_CREATE}`)}
            size="small"
            label="Create New Organization"
            rightIcon={<Plus />}
          />
        </div>
      );
    }
  };

  if (!path || path === 'data') {
    return <Navigate to={ROUTES.DATA_OVERVIEW} />;
  }

  const handleClickItem = (route: string) => {
    navigate(route);
  };

  const isCreateOrEditMode = path === 'create' || location.pathname.includes('edit');

  return (
    <div className="w-full h-full flex flex-col">
      {isCreateOrEditMode ? null : (
        <div className="flex px-6 border-b-[2px] border-border-5 justify-between">
          <div className="flex gap-6">
            {userItems.map((item) => (
              <div
                onClick={() => handleClickItem(item.route)}
                className="relative font-medium text-base cursor-pointer py-4 flex items-center gap-2"
                key={item.key}>
                {item.icon && <SvgIcon name={item.icon} size={20} color={'#838383'} />}
                {item.label}
                {item.route === ROUTES.MY_TASK && (
                  <div
                    className={
                      'bg-[#FB4F57] leading-[21.6px] w-[21.6px] rounded-full text-center text-white text-[12px]'
                    }>
                    3
                  </div>
                )}

                {item.route === path ? (
                  <div className="absolute w-full -bottom-[2px] h-[2px] bg-border-6"></div>
                ) : null}
              </div>
            ))}
          </div>
          {renderRightContent()}
        </div>
      )}
      <div
        className={`${contentSpacing ? 'p-7' : ''} flex-1 relative ${contentBackground ? 'step-background' : ''} flex-grow overflow-y-auto`}>
        <Outlet />
      </div>
    </div>
  );
};

export default DataPage;
