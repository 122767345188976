import { FC } from 'react';
import { Props } from './@types/not-found';

const NotFound: FC<Props> = ({ text }) => {
  return (
    <div className="w-full h-full flex items-center justify-center flex-col">
      <img src="/assets/images/not-found.png" alt="Not found" />
      <div className="text-gray-9 text-2xl leading-[56px]">{text}</div>
    </div>
  );
};

export default NotFound;
