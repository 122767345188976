import { FC } from 'react';
import { Props } from './@types/icon';

const Icon: FC<Props> = ({ name, bold = false, size, ...props }) => {
  return (
    <img
      {...props}
      src={`/assets/icons${bold ? '/bold' : ''}/${name}.svg`}
      alt="Icon"
      width={size}
    />
  );
};

export default Icon;
