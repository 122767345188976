import React, { ReactNode, useEffect, useMemo } from 'react';
import Table from '../../common/Table';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import Badge from '../../common/Badge';
import Avatar from '../../common/Avatar';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchAllProcess } from '../../../api/process';
import { User } from '../../../@types/user';
import SvgIcon from '../../common/SvgIcon';
import moment from 'moment';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Tooltip from '../../common/Tooltip';

const AllProcess = () => {
  const dispatch = useAppDispatch();
  const { allProcess } = useAppSelector((state) => state.process);

  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: () => <div className={'m-auto bg-[#000000] w-[12px] h-[12px] rounded-full'}></div>,
        headerSettingName: '(Icon)',
        columnType: 'optional',
        enablePinning: true,
        cell: () => {
          return (
            <img className="!min-h-auto min-w-[45px]" src={'/assets/images/rec.png'} alt="Logo" />
          );
        },
        id: 'icon'
      },
      {
        header: 'P.ID',
        enablePinning: true,
        columnType: 'optional',
        cell: (row) => {
          let value = row.getValue() as string;

          return <div className="text-lg">{value.substring(0, 2)}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Process',
        enablePinning: true,
        enableSorting: true,
        cell: (row) => {
          const info = row.getValue();
          return (
            <div>
              <div className="font-bold text-base">{_.get(info, 'name')}</div>
              <div className="font-normal text-sm">{_.get(info, 'description')}</div>
            </div>
          );
        },
        accessorKey: 'info'
      },
      {
        header: 'Department',
        enableSorting: true,
        cell: (row) => {
          return (
            <Tooltip text={'Your Sub-department'}>
              <div className={'inline-block w-auto'}>
                <Badge
                  text={row.getValue() as ReactNode}
                  variant="white"
                  state="outline"
                  round="semi-round"
                  size="large"
                />
              </div>
            </Tooltip>
          );
        },
        accessorKey: 'department'
      },
      {
        header: 'Type',
        enableSorting: true,
        cell: () => {
          return (
            <Badge
              text={'Support'}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'type'
      },
      {
        header: 'Process Owner',
        enableSorting: true,
        cell: (row) => {
          const user = row.getValue() as User;
          return (
            <div className="flex items-center gap-3 border border-border-9 rounded-2xl w-fit p-2">
              <div className="border-[2px] border-border-10 rounded-lg">
                <Avatar src={user.image} />
              </div>
              <div>
                <div>
                  {user.firstName}, {user.lastName}
                </div>
                <div>{user.email}</div>
              </div>
            </div>
          );
        },
        accessorKey: 'processOwner'
      },
      {
        header: '# Steps',
        cell: (row) => {
          return (
            <Tooltip text={'Your Steps / Total Steps'}>
              <span className="rounded-md border px-[11.5px] py-[10px] border-border-12 text-gray-7 font-semibold text-sm w-auto flex-grow-0">
                {row.getValue() as ReactNode} / 10
              </span>
            </Tooltip>
          );
        },
        accessorKey: 'steps'
      },
      {
        header: 'Rating',
        enableSorting: true,
        cell: () => {
          return (
            <div className="inline-flex rounded-md border h-[38px] px-[6px] border-border-12 text-gray-7 font-semibold text-sm gap-2 items-center justify-between">
              <div>Ø 3.1</div>
              <SvgIcon name={'star-fill'} color={'#FFC700'} size={27} />
            </div>
          );
        },
        id: 'rating'
      },
      {
        header: 'Version',
        id: 'version',
        accessorKey: 'id',
        enableSorting: true,
        cell: (row) => {
          let versionId = (row.getValue() as string).substring(0, 8);
          return (
            <div className="flex flex-col items-start">
              <div className={'text-lg leading-7 text-[#15803D] mb-0'}>Published</div>
              <div className={'text-sm leading-5 text-[#15803D]'}>#{versionId}</div>
            </div>
          );
        }
      },
      {
        header: 'Last Change',
        accessorKey: 'updatedAt',
        enableSorting: true,
        cell: (row) => {
          let timeString = row.getValue() as string;
          return (
            <div className="flex flex-col items-start">
              <div className={'text-sm'}>
                <b>{moment(timeString).format('DD/MM/YYYY')}</b>{' '}
                <span className={'font-normal'}>{moment(timeString).format('HH:mm')}</span>
              </div>
              <div className={'font-medium'}>New Version created by Malte Pfeiffer</div>
            </div>
          );
        }
      }
      // {
      //   header: 'Status',
      //   cell: (row) => {
      //     const status = row.getValue() as string;
      //     return (
      //       <Badge
      //         className="capitalize"
      //         variant={status === 'completed' ? 'success' : 'white'}
      //         text={status}
      //         state="outline"
      //         size="large"
      //       />
      //     );
      //   },
      //   accessorKey: 'status'
      // },
      // {
      //   header: '',
      //   cell: () => (
      //     <div className="flex items-center gap-4 justify-end">
      //       <Button label="Submit" size="small" />
      //       <ChevronDown className="w-5" />
      //     </div>
      //   ),
      //   accessorKey: 'action'
      // }
    ],
    []
  );

  useEffect(() => {
    dispatch(fetchAllProcess());
  }, [dispatch]);

  const tableItems = useMemo(() => {
    return allProcess.map((item, i) => ({
      id: item._id,
      info: {
        name: item.name,
        description: item.description
      },
      department: item.department.name,
      processOwner: {
        firstName: item.processOwner?.firstName,
        lastName: item.processOwner?.lastName,
        image: item.processOwner?.image,
        email: item.processOwner?.email
      },
      version: i,
      lastChange: i,
      steps: 0,
      status: item.status
    }));
  }, [allProcess]);

  return (
    <div className="w-full">
      <Table
        data={tableItems}
        columns={cols}
        enableSetting
        enableStripedRowColor
        header={
          <Button
            className="min-w-[301px] !justify-start"
            label="Search"
            size="small"
            leftIcon={<Icon name="search" />}
          />
        }
        rightAction={
          <Button
            className="ml-auto"
            label="Department"
            size="small"
            leftIcon={<Icon name="list-filter" />}
            rightIcon={<Icon name="chevron-down" />}
          />
        }
      />
    </div>
  );
};

export default AllProcess;
