import * as RAvatar from '@radix-ui/react-avatar';
import { FC, useMemo } from 'react';
import { Props } from './@types/avatar';
import { extractFirstLetters } from '../../libs/helper';

const Avatar: FC<Props> = ({ src, size = 'small', rounded = 'md', className, alt }) => {
  const getSize = useMemo(() => {
    switch (size) {
      case 'small':
        return 'w-[34px] h-[34px]';
      case 'medium':
        return 'w-[40px] h-[40px]';
    }
  }, [size]);

  const getRounded = useMemo(() => {
    switch (rounded) {
      case 'md':
        return 'rounded-md';
      case 'lg':
        return 'rounded-lg';
      case 'full':
        return 'rounded-full';
    }
  }, [rounded]);

  return (
    <RAvatar.Root>
      <RAvatar.Image
        className={`${getSize} ${getRounded} ${className}`}
        src={src || '/assets/images/avatar.jpg'}
      />
      {alt && (
        <RAvatar.Fallback>
          <div
            className={`${getSize} ${getRounded} bg-[#F0D04A] flex items-center justify-center text-white`}>
            {extractFirstLetters(alt)}
          </div>
        </RAvatar.Fallback>
      )}
    </RAvatar.Root>
  );
};

export default Avatar;
