import { ArrowLeft, ArrowRight } from 'lucide-react';
import Button from '../../../common/Button';
import FirstStep from './FirstStep';
import Icon from '../../../common/Icon';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { Fragment, useCallback, useState } from 'react';
import { ROUTES } from '../../../../constants/router';
import { DataPageEnum } from '../../../../constants/data';
import { useNavigate } from 'react-router-dom';
import Survey from './Survey';
import ModalConfirm from '../../../common/ModalConfirm';
import Modal from '../../../common/Modal';
import FormCard from '../../../common/FormCard';

const ProcessFlow = () => {
  const [modalCancel, setModalCancel] = useState(false);
  const [finish, setFinish] = useState(false);
  const [modalComplete, setModalComplete] = useState(false);
  const [modalOnboarding, setModalOnboarding] = useState(false);
  const navigate = useNavigate();

  const handleFinish = useCallback(() => {
    if (finish) {
      setModalComplete(true);
    } else {
      setFinish(true);
    }
  }, [finish]);

  return (
    <div>
      {!finish ? (
        <div className="w-full grid grid-cols-11 justify-center h-[calc(100vh_-_305px)] overflow-y-auto gap-4 px-11 pb-7">
          <div className="col-span-3">
            <FirstStep />
          </div>
          <div className="col-span-5">
            <SecondStep />
          </div>
          <div className="col-span-3">
            <ThirdStep />
          </div>
        </div>
      ) : (
        <div className="w-full h-[calc(100vh_-_278px)] overflow-y-auto">
          <Survey />
        </div>
      )}
      <div className="mt-auto absolute bottom-0 left-0 w-full border-t border-border-5 p-4 flex justify-between items-center bg-white">
        <div className="flex gap-4">
          <Button label="Back" leftIcon={<ArrowLeft />} onClick={() => setModalCancel(true)} />
          <Button
            onClick={() => {}}
            label="Assign someome else"
            rightIcon={<Icon name="circle-off" size={20} />}
          />

          <Button rightIcon={<Icon name="circle-question" size={24} />} />
        </div>
        <div className="flex items-center gap-2">
          <div className="font-bold text-base">
            <div className="text-gray-11 text-sm">Process name</div>
            <div>Employee Onboarding</div>
          </div>
          <Icon name="eye" className="cursor-pointer" onClick={() => setModalOnboarding(true)} />
        </div>
        <div className="flex items-center gap-4">
          <div className="text-base font-bold">
            Saved: <span className="font-medium text-gray-500">2 minutes ago</span>
          </div>
          <Button label={'Finish'} rightIcon={<ArrowRight />} onClick={handleFinish} />
        </div>
      </div>
      {modalCancel && (
        <ModalConfirm
          open={modalCancel}
          onClose={() => setModalCancel(false)}
          contentClassName="max-w-[551px]"
          image="/assets/images/back-process.png"
          title="Do you want to go back to all your Process Steps?"
          description="Remember to save your process step before going back!"
          actions={
            <Fragment>
              <Button
                className="!w-full"
                label="Wait a moment"
                onClick={() => setModalCancel(false)}
              />
              <Button
                className="!w-full"
                label="Yes"
                rightIcon={<ArrowRight />}
                onClick={() => {
                  navigate(`${ROUTES.DATA}/${ROUTES.DATA_PROCESS}`, {
                    state: { data: DataPageEnum.MY_PROCESS_STEPS }
                  });
                }}
              />
            </Fragment>
          }
        />
      )}

      {modalComplete && (
        <ModalConfirm
          open={modalComplete}
          onClose={() => setModalComplete(false)}
          contentClassName="max-w-[551px]"
          image="/assets/images/complete-process.png"
          title={
            <span>
              You successfully finished your <br /> Process Step Recording
            </span>
          }
          description="Your contribution helps us improve every day."
          actions={
            <Button
              className="!w-full"
              label="Continue"
              rightIcon={<ArrowRight />}
              onClick={() => {
                navigate(`${ROUTES.DATA}/${ROUTES.DATA_PROCESS}`, {
                  state: { data: DataPageEnum.MY_PROCESS_STEPS }
                });
              }}
            />
          }
        />
      )}

      {modalOnboarding && (
        <Modal
          open={modalOnboarding}
          onClose={() => setModalOnboarding(false)}
          content={
            <div className="flex flex-col gap-4 w-[644px]">
              <div className="p-[16.5px] rounded-2xl border border-boder-4 w-fit">
                <Icon name="waypoints" size={30} />
              </div>
              <div className="font-semibold text-2xl">Process Name & Description</div>
              <FormCard
                theme="light"
                content={
                  <div className="text-base">
                    <div className="font-bold mb-2">Employee Onboarding</div>
                    <div>
                      Employee Onboarding involves integrating a new hire into the organization by
                      completing necessary paperwork, providing orientation, and assigning initial
                      training. This process ensures the employee is equipped with the tools,
                      knowledge, and resources needed to perform their role effectively. It also
                      fosters engagement and alignment with company culture from day one.
                    </div>
                  </div>
                }
              />
            </div>
          }
        />
      )}
    </div>
  );
};

export default ProcessFlow;
