import LogoWithHeader from '../components/layout/LogoWithHeader';
import TrustTeams from '../components/layout/TrustTeams';
import RightPanel from '../components/layout/RightPanel';
import SignUpForm from '../components/SignUp/SignUpForm';
const SignUpPage = () => {
  return (
    <div className="h-[100vh] w-[100vw] flex overflow-hidden">
      <div className="flex flex-col justify-center items-center h-full w-[46.7%]">
        <div className="max-w-[432px]">
          <LogoWithHeader isInvite />
          <div>
            <div className="mt-4">
              <SignUpForm />
            </div>
            <TrustTeams />
          </div>
        </div>
      </div>
      <RightPanel />
    </div>
  );
};

export default SignUpPage;
