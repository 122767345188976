import { ArrowLeft } from 'lucide-react';
import Button from '../common/Button';
import Input from '../common/Input';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { forgotPasword } from '../../api/auth';
import { ROUTES } from '../../constants/router';

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const { loading, sendForgotSuccess } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleGoBack = () => {
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    if (sendForgotSuccess) {
      window.alert('Ok');
    }
  }, [sendForgotSuccess]);

  const handleSubmit = useCallback(async () => {
    if (email) {
      await dispatch(forgotPasword(email));
      setEmail('');
    }
  }, [email, dispatch]);

  return (
    <div>
      <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('email')} />
      <div className="mt-4 flex gap-2">
        <Button onClick={handleGoBack} leftIcon={<ArrowLeft />} />
        <Button
          theme="dark"
          loading={loading}
          onClick={handleSubmit}
          className="w-full"
          label={t('resetPassword')}
        />
      </div>
      <div className="mt-2 text-center">
        <span className="text-gray-secondary text-sm">{t('didNotReceiveEmail')}</span>{' '}
        <span className="text-sm underline">{t('clickToResend')}</span>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
