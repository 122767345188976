import { ReactNode, useEffect, useMemo, useState } from 'react';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import NotFound from '../../../components/common/NotFound';
import CreateProcessModal from '../../../components/Data/Process/CreateProcessModal';
import { ArrowLeft, ArrowRight, ChevronDown, TrashIcon } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import Badge from '../../../components/common/Badge';
import Table from '../../../components/common/Table';
import Modal from '../../../components/common/Modal';
import Avatar from '../../../components/common/Avatar';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/router';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchDeparments } from '../../../api/department';
import { createProcess } from '../../../api/process';
import { clearDrafItems } from '../../../app/slices/process.slice';

const CreateProcess = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { drafItems, loading } = useAppSelector((state) => state.process);
  const { listDepartment } = useAppSelector((state) => state.department);
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchDeparments());
  }, [dispatch]);

  const RenderTable = () => {
    const items = _.map(drafItems, (process, index) => {
      const matchDepartment = listDepartment.find((item) => item._id === process.department);
      const matchProcessOwner = matchDepartment?.members.find(
        (member) => process.processOwner === member._id
      );
      const matchInitAssignMember = matchDepartment?.members.find(
        (member) => process.initAssignMember === member._id
      );
      const department = listDepartment.find((item) => item._id === process.department);
      return {
        id: index + 1,
        info: {
          name: process.name,
          description: process.description
        },
        department: department?.name,
        processOwner: {
          firstName: matchProcessOwner?.firstName,
          lastName: matchProcessOwner?.firstName,
          image: matchProcessOwner?.image,
          role: matchProcessOwner?.role,
          department: matchDepartment?.name
        },
        initAssignMember: {
          firstName: matchInitAssignMember?.firstName,
          lastName: matchInitAssignMember?.firstName,
          image: matchInitAssignMember?.image,
          role: matchInitAssignMember?.role,
          department: matchDepartment?.name
        }
      };
    });
    const cols = useMemo<ColumnDef<any>[]>(
      () => [
        {
          header: '#',
          cell: (row) => {
            return <div className="text-lg">{row.getValue() as ReactNode}</div>;
          },
          accessorKey: 'id'
        },
        {
          header: 'Process Name',
          cell: (row) => {
            const info = row.getValue();
            return (
              <div>
                <div className="font-medium text-base">{_.get(info, 'name')}</div>
                <div className="font-normal text-sm text-gray-secondary">
                  {_.get(info, 'description')}
                </div>
              </div>
            );
          },
          accessorKey: 'info'
        },

        {
          header: 'Department',
          cell: (row) => {
            return (
              <Badge
                text={row.getValue() as ReactNode}
                variant="white"
                state="outline"
                round="semi-round"
                size="large"
              />
            );
          },
          accessorKey: 'department'
        },
        {
          header: 'Process Owner',
          cell: (row) => {
            const user = row.getValue();
            return (
              <div className="flex items-center gap-3 border border-border-9 rounded-2xl w-fit p-2">
                <Avatar src={_.get(user, 'image', '')} />
                <div>
                  <div className="font-medium text-sm">
                    {_.get(user, 'firstName')}, {_.get(user, 'lastName')}
                  </div>
                  <div className="text-gray-secondary font-normal text-sm">
                    {_.get(user, 'role')}
                  </div>
                </div>
                <Badge
                  text={_.get(user, 'department')}
                  variant="white"
                  state="outline"
                  round="semi-round"
                  size="medium"
                />
                <ChevronDown className="w-5" />
              </div>
            );
          },
          accessorKey: 'processOwner'
        },
        {
          header: 'Initial Assignment Member',
          cell: (row) => {
            const user = row.getValue();
            return (
              <div className="flex items-center gap-3 border border-border-9 rounded-2xl w-fit p-2">
                <Avatar src={_.get(user, 'image', '')} />
                <div>
                  <div className="font-medium text-sm">
                    {_.get(user, 'firstName')}, {_.get(user, 'lastName')}
                  </div>
                  <div className="text-gray-secondary font-normal text-sm">
                    {_.get(user, 'role')}
                  </div>
                </div>
                <Badge
                  text={_.get(user, 'department')}
                  variant="white"
                  state="outline"
                  round="semi-round"
                  size="medium"
                />
                <ChevronDown className="w-5" />
              </div>
            );
          },
          accessorKey: 'initAssignMember'
        },
        {
          header: '',
          cell: () => (
            <div className="flex items-center gap-4 justify-end">
              <Button className="min-w-[88px]" label="Edit" size="small" />
              <Button leftIcon={<TrashIcon size={20} />} />
            </div>
          ),
          accessorKey: 'action'
        }
      ],
      []
    );

    return <Table data={items} columns={cols} />;
  };

  const handleCreateAll = async () => {
    Promise.all(
      drafItems.map((item) =>
        dispatch(
          createProcess({
            ...item,
            organization: user?.organization || ''
          })
        )
      )
    ).then(() => {
      setOpenConfirmModal(true);
    });
  };

  const handleConfirm = () => {
    dispatch(clearDrafItems());
    setOpenConfirmModal(false);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-xl font-semibold mb-[3px]">New Process List</div>
          <div className="text-sm text-gray-8">Placeholder for the new process list</div>
        </div>
        <div className="flex gap-2">
          {drafItems.length ? (
            <Button
              onClick={() => setOpenCreateModal(true)}
              className="h-[64px]"
              label="Add manually a process"
              rightIcon={<Icon name="plus" />}
            />
          ) : (
            <>
              <Button
                className="h-[64px]"
                label="Upload From Excel"
                rightIcon={<Icon name="plus" />}
              />
              <Button
                onClick={() => setOpenCreateModal(true)}
                className="h-[64px]"
                label="Add Manually"
                rightIcon={<Icon name="plus" />}
              />
            </>
          )}
        </div>
      </div>

      {!drafItems.length ? (
        <NotFound text="No process created." />
      ) : (
        <div className="mt-7">
          <RenderTable />
        </div>
      )}

      <div className="mt-auto absolute bottom-0 left-0 w-full border-t border-border-5 p-4 flex justify-between">
        <Button
          label="Back"
          leftIcon={<ArrowLeft />}
          onClick={() => navigate(`${ROUTES.DATA}/${ROUTES.DATA_PROCESS}`)}
        />
        {drafItems.length ? (
          <Button
            loading={loading}
            label="Create All"
            rightIcon={<ArrowRight />}
            onClick={handleCreateAll}
          />
        ) : null}
      </div>

      {openCreateModal && (
        <CreateProcessModal onClose={() => setOpenCreateModal(false)} open={openCreateModal} />
      )}

      <Modal
        open={openConfirmModal}
        content={
          <div className="min-w-[422px] flex flex-col items-center justify-center">
            <img src="/assets/images/create-process-success.png" alt="Logo" />
            <div className="mt-8 text-2xl font-semibold">
              {drafItems.length} new process has been created!
            </div>
            <div className="mt-2 text-base font-normal">Are you sure you want to procees?</div>
            <div className="flex gap-4 w-full mt-4">
              <Button className="flex-1" label="Wait a moment" onClick={handleConfirm} />
              <Button
                className="flex-1"
                label="Yes"
                rightIcon={<ArrowRight />}
                onClick={handleConfirm}
              />
            </div>
          </div>
        }
        onClose={() => {}}
      />
    </div>
  );
};

export default CreateProcess;
