import { createSlice } from '@reduxjs/toolkit'
import { Department } from '../../@types/department'
import { createDepartment, fetchDeparments, fetchDepartmentDetail } from '../../api/department'

interface IState {
    listDepartment: Department[],
    loading: boolean
    department?: Department
}


const defaultState: IState = {
    listDepartment: [],
    loading: false
}

const initialState = defaultState
const departmentSlice = createSlice({
    name: 'department',
    initialState,
    reducers: {
        reset: (state) => {
            state = defaultState
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchDeparments.fulfilled, (state, action) => {
            state.listDepartment = action.payload
        })
        builder.addCase(createDepartment.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(createDepartment.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchDepartmentDetail.fulfilled, (state, action) => {
            state.loading = false
            state.department = action.payload
        })
        builder.addCase(fetchDepartmentDetail.pending, (state) => {
            state.loading = true
        })
    }
})

export const { reset } = departmentSlice.actions

export default departmentSlice.reducer