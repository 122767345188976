import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import authReducer from "./slices/auth.slice";
import dataReducer from "./slices/data.slice";
import layoutReducer from "./slices/layout.slice";
import departmentReducer from "./slices/department.slice";
import processReducer from "./slices/process.slice";
import organizationReducer from './slices/organization'
import legalStructureReducer from './slices/legal-structure.slice'
import industryClassificationReducer from './slices/industry-classification.slice'

const rootReducer = combineReducers({
  auth: authReducer,
  data: dataReducer,
  layout: layoutReducer,
  department: departmentReducer,
  process: processReducer,
  organization: organizationReducer,
  legalStructure: legalStructureReducer,
  industryClassification: industryClassificationReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  },
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppStore = ReturnType<typeof setupStore>;