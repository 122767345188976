export enum CompanySizeType {
    "MICRO" = 'micro',
    "SMALL" = 'small',
    "MEDIUM" = 'medium',
    "LARGE" = 'large'
}

export enum LicenseModelType {
    "EXTRA_SMALL" = "extra-small",
    "SMALL" = "small",
    "MEDIUM" = 'medium',
    "CORPORATE" = 'corporate',
    "CONSULTING" = 'consulting-project'
}

export enum TargetMarketType {
    "B2B" = "B2B",
    "B2C" = 'B2C'
}

export enum OrgMemberStatus {
    "INTERNAL_MEMBER" = 'internal-member',
    "EXTERNAL_MEMBER" = 'external-member'
}

export enum UserTypes {
    SUPER_ADMIN = 'SuperAdmin',
    SYSTEM_ADMIN = 'SystemAdmin',
    ORG_MANAGER = 'OrgManager',
    ORG_MEMBER = 'OrgMember',
    DEPT_MANAGER = 'DeptManager',
    DEPT_MEMBER = 'DeptMember'
}

export const COMPANY_SIZE = [
    {
        type: CompanySizeType.MICRO,
        name: "microEnterPrises",
        description: "microEnterPrisesDes",
        employeeCount: 10,
        turnover: 2,
        balanceSheetTotal: 2
    },
    {
        type: CompanySizeType.SMALL,
        name: 'smallEnterPrises',
        description: "smallEnterPrisesDes",
        employeeCount: 50,
        turnover: 10,
        balanceSheetTotal: 10
    },
    {
        type: CompanySizeType.MEDIUM,
        name: 'mediumEnterPrises',
        description: "mediumEnterPrisesDes",
        employeeCount: 250,
        turnover: 50,
        balanceSheetTotal: 43
    },
    {
        type: CompanySizeType.LARGE,
        name: 'largeEnterPrises',
        description: "largeEnterPrisesDes",
        employeeCount: null,
        turnover: null,
        balanceSheetTotal: null,
    },
]

export const LICENSE_MODEL = [
    {
        type: LicenseModelType.EXTRA_SMALL,
        name: 'extraSmall',
        description: "<10",
        size: {
            min: null,
            max: 10
        }
    },
    {
        type: LicenseModelType.SMALL,
        description: "0-50",
        name: 'small',
        size: {
            min: 0,
            max: 50
        }
    },
    {
        type: LicenseModelType.MEDIUM,
        name: 'medium',
        description: "51-500",
        size: {
            min: 51,
            max: 500
        }
    },
    {
        type: LicenseModelType.CORPORATE,
        name: 'corporate',
        description: "501+",
        size: {
            min: 501,
            max: null
        }
    },
    {
        type: LicenseModelType.CONSULTING,
        name: 'consultingProject',
        size: {
            min: null,
            max: null
        },
        isLimitless: true
    },
]

export const TARGET_MARKET = [
    {
        type: TargetMarketType.B2B,
        name: TargetMarketType.B2B
    },
    {
        type: TargetMarketType.B2C,
        name: TargetMarketType.B2C
    },
]

export const ORG_MEMBER_STATUS = [
    {
        type: OrgMemberStatus.INTERNAL_MEMBER,
        name: 'internalMember',
        description: 'internalMemberDes'
    },
    {
        type: OrgMemberStatus.EXTERNAL_MEMBER,
        name: 'externalMember',
        description: 'externalMemberDes'
    }
]

export const MAP_ORG_MEMBER_STATUS = ORG_MEMBER_STATUS.reduce((result: any, item) => {
    result[item.type] = item
    return result
}, {})

export const USER_TYPES = [
    {
        type: UserTypes.SYSTEM_ADMIN,
        name: "SystemAdmin"
    },
    {
        type: UserTypes.ORG_MANAGER,
        name: "OrgManager"
    },
]