import _ from 'lodash';
import { useAppSelector } from '../../app/hooks';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/router';

const Header = () => {
  const { user } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();

  if (pathname.includes(ROUTES.DATA_PROCESS_CREATE)) {
    return (
      <div className="p-6 bg-bg-3 border-b border-border-5">
        <div className="font-semibold text-3xl">Create New Process</div>
        <div className="text-lg">Sub-heading</div>
      </div>
    );
  }

  if (pathname.includes(ROUTES.DATA_ORGANIZATION_CREATE)) {
    return (
      <div className="p-6 bg-bg-3 border-b border-border-5">
        <div className="font-semibold text-3xl">Create New Customer-Organization</div>
        <div className="text-lg">
          Follow these four simple steps to create a new customer organization.
        </div>
      </div>
    );
  }

  if (pathname.includes(ROUTES.DATA_DEPARTMENT_CREATE)) {
    return (
      <div className="p-6 bg-bg-3 border-b border-border-5">
        <div className="font-semibold text-3xl">Create New Department</div>
        <div className="text-lg">Follow these simple steps to create a new department.</div>
      </div>
    );
  }

  if (pathname.includes('edit') && pathname.includes('organization')) {
    return (
      <div className="p-6 bg-bg-3 border-b border-border-5">
        <div className="font-semibold text-3xl">Edit Customer-Organization</div>
        <div className="text-lg">Sub-headling</div>
      </div>
    );
  }

  return (
    <div className="mt-6 mb-4 mx-6 flex justify-between items-center">
      <div className="flex items-center gap-4">
        <div className="border-border-3 border-[2px] rounded-2xl">
          <img
            className="h-[76px] w-[76px] object-cover rounded-2xl"
            src={_.get(user, 'image', '/assets/images/avatar.jpg')}
            alt="Logo"
            onError={(e) => {
              const img = e.target as HTMLImageElement;
              img.src = '/assets/images/avatar.png';
            }}
          />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold text-3xl">
            {_.get(user, 'firstName')} {_.get(user, 'lastName')}
          </div>
          <div className="font-normal text-lg">{_.get(user, 'role')}</div>
        </div>
      </div>
      <div className="flex gap-3">
        <div className="w-[63px] h-[63px] border-border-4 rounded-2xl border-[1px] flex items-center justify-center text-gray-4">
          <img className="cursor-pointer" src="/assets/icons/search-normal.svg" alt="icon" />
        </div>
        <div className="w-[63px] h-[63px] border-border-4 rounded-2xl border-[1px] flex items-center justify-center text-gray-4">
          <img className="cursor-pointer" src="/assets/icons/star.svg" alt="icon" />
        </div>
        <div className="w-[63px] h-[63px] border-border-4 rounded-2xl border-[1px] flex items-center justify-center text-gray-4">
          <img className="cursor-pointer" src="/assets/icons/notification.svg" alt="icon" />
        </div>
        <div className="w-[63px] h-[63px] border-border-4 rounded-2xl border-[1px] flex items-center justify-center text-gray-4">
          <img className="cursor-pointer" src="/assets/icons/setting-2.svg" alt="icon" />
        </div>
      </div>
    </div>
  );
};
export default Header;
