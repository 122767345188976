import * as Dialog from '@radix-ui/react-dialog';
import { FC, useRef } from 'react';
import { Props } from './@types/modal';
import { X } from 'lucide-react';

const Modal: FC<Props> = ({ open, content, onClose, noCloseIcon, className }) => {
  const ref = useRef(null);

  if (!open) {
    return null;
  }

  return (
    <Dialog.Root>
      <Dialog.Portal forceMount>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content ref={ref} className={`DialogContent ${className || ''}`}>
          {!noCloseIcon && (
            <X className="absolute right-3 top-3 cursor-pointer" onClick={onClose} />
          )}
          {content}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Modal;
