import { createSlice } from '@reduxjs/toolkit'
import { createProcess, fetchAllProcess } from '../../api/process'
import { ICreateProcess, Process } from '../../@types/process'

interface IState {
    drafItems: ICreateProcess[],
    loading: boolean,
    allProcess: Process[]
}


const defaultState: IState = {
    drafItems: [],
    loading: false,
    allProcess: []
}

const initialState = defaultState
const processSlice = createSlice({
    name: 'process',
    initialState,
    reducers: {
        addDrafItem: (state, action) => {
            state.drafItems.push(action.payload)
        },
        clearDrafItems: (state) => {
            state.drafItems = []
        },
        removeDrafItems: (state, action) => {
            state.drafItems.splice(action.payload + 1)
        }
    },
    extraReducers: builder => {
        builder.addCase(createProcess.fulfilled, (state) => {
            state.loading = false
        })
        builder.addCase(createProcess.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAllProcess.fulfilled, (state, action) => {
            state.loading = false
            state.allProcess = action.payload
        })
        builder.addCase(fetchAllProcess.pending, (state) => {
            state.loading = true
        })
    }
})

export const { addDrafItem, removeDrafItems, clearDrafItems } = processSlice.actions

export default processSlice.reducer