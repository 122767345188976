import { FC, useState } from 'react';
import { Props } from './@types/collapse';
import { ChevronDown } from 'lucide-react';

const Collapse: FC<Props> = ({ label, content }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-bg-2 border-[1px] border-border-14 rounded-lg p-4 overflow-hidden">
      <div className="flex justify-between">
        <div>{label}</div>
        <ChevronDown
          onClick={() => setOpen(!open)}
          className={`cursor-pointer w-5 transform transition-transform ${open ? 'rotate-180' : 'rotate-0 '}`}
        />
      </div>
      <div className={`mt-4 transform transition-transform ${open ? 'h-full' : 'h-[0px] hidden'}`}>
        {content}
      </div>
    </div>
  );
};

export default Collapse;
