import React, { useState } from 'react';
import Card from '../../../common/Card';
import Modal from '../../../common/Modal';
import SvgIcon from '../../../common/SvgIcon';
import Button from '../../../common/Button';
import CardHeader from '../../../common/CardHeader';
import Icon from '../../../common/Icon';
import { Plus } from 'lucide-react';
import Input from '../../../common/Input';
import FormCard from '../../../common/FormCard';
import Select from '../../../common/Select';
import TextArea from '../../../common/TextArea';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '../../../common/Avatar';
import Badge from '../../../common/Badge';
import './style.css';
import ModalUploadDocument from '../ModalUploadContent';

type Props = {
  show: boolean;
  onClose: () => void;
};

function PublishContentModal(props: Props) {
  const [uploadModal, setUploadModal] = useState(false);
  const [inputs, setInputs] = useState<any[]>([{ id: uuidv4(), value: '' }]);
  const [form, setForm] = useState({
    value: 'Visuals from the designer',
    stepDesc:
      'Upload and share pre-approved branding content on the company’s Instagram profile as part of the social media strategy.'
  });
  const [selectedInput, setSelectedInput] = useState<string>(inputs[0].id);

  const handleAddInput = () => {
    const newInput = { id: uuidv4(), value: '' };
    setInputs([...inputs, newInput]);
  };

  const handleRemoveInput = (id: string) => {
    const newInputs = inputs.filter((input) => input.id !== id);
    setInputs(newInputs);
  };

  return (
    <>
      <Modal
        className={'publish-content-modal'}
        noCloseIcon
        open={props.show}
        content={
          <div className="min-w-[845px] flex flex-col justify-start items-start gap-4">
            <div className={'rounded-2xl p-[16.5px] border border-[#DEDEDE]'}>
              <SvgIcon color={'#898989'} name={'steps-active'} size={30} />
            </div>

            <div>
              <h1 className={'text-2xl font-semibold'}>Publish Content</h1>
            </div>

            <Card className={'w-full'}>
              <CardHeader>
                <div className="flex items-center w-full justify-between">
                  <div className="flex items-center gap-1.5">
                    <Avatar
                      className="border-border-10 border-[2px] !w-[32px] !h-[32px]"
                      src={''}
                      alt={`Kenny John`}
                    />
                    <div className="text-sm">
                      <div className="font-semibold">
                        <span className="uppercase">Kenny,</span> John
                      </div>
                      <div className="text-[10px] text-gray-secondary">
                        Senior Software Developer
                      </div>
                    </div>
                  </div>

                  <Badge
                    text="Marketing"
                    variant="white"
                    state="outline"
                    round="semi-round"
                    size="semi"
                    textClassName={'text-sm'}
                  />
                </div>
              </CardHeader>

              <ol className="relative mt-7 border-s border-[#DEDEDE]">
                <li className="ms-4 mb-3">
                  <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
                  <div className="-translate-y-1">
                    <div className="flex justify-between">
                      <div className="flex gap-1">
                        {inputs.map((input, index) => (
                          <div
                            onClick={() => setSelectedInput(input.id)}
                            key={input.id}
                            className={`flex items-center gap-1 cursor-pointer ${selectedInput === input.id ? 'opacity-100' : 'opacity-20'}`}>
                            <Icon name="badge-input" size={16} />
                            <div className="text-base font-semibold">Input {index + 1}</div>
                            {selectedInput === input.id ? (
                              <Icon name="x" onClick={() => handleRemoveInput(input.id)} />
                            ) : (
                              <Icon name="badge-info" />
                            )}
                          </div>
                        ))}
                      </div>

                      <div className="flex items-center gap-1">
                        <Icon name="badge-input-2" size={16} />
                        <div className="text-xs font-medium text-gray-12">Add Input</div>
                        <div
                          className="w-5 h-5 rounded-full border border-border-15 flex items-center justify-center cursor-pointer"
                          onClick={handleAddInput}>
                          <Plus size={16} />
                        </div>
                      </div>
                    </div>
                    <Input className="text-sm my-2" value={form.value} />
                  </div>
                </li>
                <li className="ms-4">
                  <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
                  <div className="-translate-y-1 mb-2">
                    <FormCard theme="gray" icon="steps-active" title="Process Step Description">
                      <Card className={'w-full h-[123px]'}>
                        <TextArea
                          placeholder="Please enter"
                          value={form.stepDesc}
                          onChange={(e) =>
                            setForm((prev) => ({ ...prev, stepDesc: e.target?.value }))
                          }
                        />
                      </Card>
                      <FormCard theme="light" className="mt-2">
                        <div className="grid grid-cols-3 gap-2">
                          <FormCard
                            className="col-span-1"
                            theme="light"
                            icon="rotate"
                            title="System Used">
                            <Select className="!min-w-full" items={[]} onChange={() => {}} />
                          </FormCard>
                          <FormCard
                            className="col-span-1"
                            theme="light"
                            icon="person"
                            title="Who else does this?">
                            <Select className="!min-w-full" items={[]} onChange={() => {}} />
                          </FormCard>
                          <FormCard
                            className="col-span-1"
                            theme="light"
                            icon="document"
                            title="Documents">
                            <div onClick={() => setUploadModal(true)}>
                              <Select className="!min-w-full" items={[]} onChange={() => {}} />
                            </div>
                          </FormCard>
                        </div>
                      </FormCard>
                    </FormCard>
                  </div>
                </li>
                <li className="ms-4">
                  <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
                  <div className="-translate-y-1">
                    <div className="flex items-center gap-1">
                      <Icon name="badge-input" size={16} />
                      <div className="text-base font-semibold">Output</div>
                    </div>
                    <TextArea className="mt-2" placeholder="Describe the output of this process." />
                  </div>
                </li>
              </ol>
            </Card>

            <Button className={'w-full'} label={'Back'} onClick={props.onClose} />
          </div>
        }
        onClose={props.onClose}
      />

      {uploadModal && (
        <ModalUploadDocument open={uploadModal} onClose={() => setUploadModal(false)} />
      )}
    </>
  );
}

export default PublishContentModal;
