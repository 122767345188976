import { FC, ReactNode } from 'react';
import { Props } from './@types/modal';
import Modal from './Modal';

interface ModalConfirmProps extends Props {
  image: string;
  title: string | ReactNode;
  description: string;
  actions: ReactNode;
  contentClassName?: string;
}

const ModalConfirm: FC<ModalConfirmProps> = ({
  image,
  title,
  description,
  actions,
  open,
  onClose,
  contentClassName
}) => {
  return (
    <Modal
      onClose={onClose}
      open={open}
      content={
        <div className={`min-w-[422px] flex flex-col items-center justify-center ${contentClassName}`}>
          <img src={image} alt="Logo" />
          <div className="mt-8 text-2xl font-semibold text-center mx-10">{title}</div>
          <div className="mt-2 text-base font-normal">{description}</div>
          <div className="flex gap-4 w-full mt-4">{actions}</div>
        </div>
      }
    />
  );
};

export default ModalConfirm;
