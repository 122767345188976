import React, { PropsWithChildren } from 'react';
import SvgIcon from '../../common/SvgIcon';
import { IconName } from '../../common/@types/icon';

type Props = {
  icon: IconName;
  title: string;
  desc: string;
  disabled?: boolean;
};

function CheckCard(props: PropsWithChildren<Props>) {
  return (
    <div
      className={`w-full rounded-lg border-[#DEE2E8] border p-3 ${props.disabled ? 'opacity-[22%] cursor-not-allowed' : 'cursor-pointer'}`}>
      <div className="flex justify-between items-start">
        <div className={'flex items-center gap-[4px]'}>
          <SvgIcon name={props.icon} size={18} />
          <div className={'text-base font-semibold select-none'}>{props.title}</div>
        </div>
        <SvgIcon name={'circle-check'} size={24} />
      </div>

      <div className={'mt-2 text-[#A5A7AA] select-none'}>{props.desc}</div>
    </div>
  );
}

export default CheckCard;
