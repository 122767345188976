import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICreateOrganization,
  IFilter,
  IOgrganizationMember,
  Organization
} from '../../@types/organization';
import _ from 'lodash';
import {
  addMemberToOrganization,
  createOrganization,
  fetchOrganizationDetail,
  fetchOrganizationMembers,
  listOrganization
} from '../../api/organization';

interface IState {
  step: number;
  createData?: ICreateOrganization;
  members: IOgrganizationMember[];
  organization?: Organization;
  loading: boolean;
  organizations: Organization[];
  filters?: IFilter;
}

const defaultState: IState = {
  step: 1,
  createData: undefined,
  members: [],
  organization: undefined,
  loading: false,
  organizations: [],
  filters: {
    industries: [],
    licenses: []
  }
};

const initialState = { ...defaultState };
const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setCreateData: (state, action) => {
      state.createData = _.set(state.createData || {}, action.payload.key, action.payload.value);
    },
    setMember: (state, action) => {
      state.members = action.payload;
    },
    addMember: (state, action) => {
      state.members = [...state.members, action.payload];
    },
    editMember: (state, action) => {
      state.members = state.members?.map((member) => {
        if (member.email === action.payload.email) {
          return {
            ...member,
            ...action.payload
          };
        }
        return member;
      });
    },
    clearMember: (state) => {
      state.members = [];
    },
    removeMember: (state, action) => {
      state.members = state.members?.filter((item) => item.email !== action.payload.email);
    },
    reset: () => {
      return { ...defaultState };
    },
    setFilter: (state, action) => {
      state.filters = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createOrganization.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrganization.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(addMemberToOrganization.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addMemberToOrganization.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addMemberToOrganization.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(listOrganization.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listOrganization.fulfilled, (state, action) => {
      state.loading = false;
      state.organizations = action.payload;
    });
    builder.addCase(fetchOrganizationMembers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrganizationMembers.fulfilled, (state, action) => {
      state.loading = false;
      state.members = action.payload;
    });
    builder.addCase(fetchOrganizationDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrganizationDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.organization = action.payload;
      state.createData = {
        ...action.payload,
        legalStructure: action.payload.legalStructure as string,
        industryClassification: action.payload.industryClassification as string
      };
    });
  }
});

export const {
  setStep,
  setCreateData,
  addMember,
  clearMember,
  removeMember,
  reset,
  editMember,
  setFilter
} = organizationSlice.actions;

export default organizationSlice.reducer;
