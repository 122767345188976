import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from '../libs/agent'
import { Department, ICreateDepartment } from "../@types/department";


export const fetchDeparments = createAsyncThunk("department/list", async (): Promise<Department[] | []> => {
    const response = await agent.get(`departments`);
    return response.data.data
})


export const createDepartment = createAsyncThunk("department/create", async (body: ICreateDepartment): Promise<Department> => {
    const response = await agent.post(`departments`, body);
    return response.data.data
})


export const fetchDepartmentDetail = createAsyncThunk("department/detail", async (id: string): Promise<Department> => {
    const response = await agent.get(`departments/${id}`);
    return response.data.data
})
