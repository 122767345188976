import React, { ReactNode, useEffect, useMemo } from 'react';
import { listOrganization } from '../../../../api/organization';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ColumnDef } from '@tanstack/react-table';
import Table from '../../../common/Table';
import { LegalStructure } from '../../../../@types/legal-structrue';
import { IndustryClassification } from '../../../../@types/industry-classification';
import { License } from '../../../../@types/organization';
import { LICENSE_MODEL } from '../../../../config/data';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/router';
import Avatar from '../../../common/Avatar';
import * as _ from 'lodash';
import Select from '../../../common/Select';
import SvgIcon from '../../../common/SvgIcon';
import { listIndustryClassification } from '../../../../api/industry-classification';
import { setFilter } from '../../../../app/slices/organization';
import moment from 'moment/moment';

const ListOrganization = () => {
  const dispatch = useAppDispatch();
  const { organizations, filters } = useAppSelector((state) => state.organization);
  const { list: industryClassifications } = useAppSelector((state) => state.industryClassification);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listOrganization(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    dispatch(listIndustryClassification());
  }, [dispatch]);

  const licenseModelData = LICENSE_MODEL.map((item) => {
    const value = item.type;
    const label = (
      <div className="text-left">
        <div className="font-medium text-sm">{t(`data.${item.name}`)}</div>
      </div>
    );
    return { value, label };
  });

  const onChangeIndustry = (value: string[]) => {
    dispatch(setFilter({ ...filters, industries: value }));
  };

  const onChangeLicense = (value: string[]) => {
    dispatch(setFilter({ ...filters, licenses: value }));
  };

  const processItems = useMemo(
    () =>
      organizations.map((item, index) => ({
        _id: item._id,
        id: index + 1,
        info: {
          name: item.companyName,
          image: item.image
        },
        legalStructure: item.legalStructure,
        industryClassification: item.industryClassification,
        license: item.license,
        totalUsers: item.members?.length,
        updatedAt: item.updatedAt,
        active: item.license?.manualStatus
      })),
    [organizations]
  );

  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: () => <div className={'text-center w-full'}>O.ID</div>,
        headerSettingName: 'O.ID',
        size: 73,
        cell: (row) => {
          return <div className="text-lg text-center">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Name',
        enableSorting: true,
        cell: (row) => {
          const info = row.getValue() as any;
          return (
            <div className="flex items-center gap-2.5">
              <Avatar
                src={`${process.env.REACT_APP_BACKEND_ENDPOINT}/${info.image}`}
                alt={info?.name}
                rounded="full"
                size="medium"
              />
              <div className="font-bold text-base">{info?.name}</div>
            </div>
          );
        },
        accessorKey: 'info'
      },
      {
        header: 'Legal Structure',
        enableSorting: true,
        cell: (row) => {
          const legalStructure = row.getValue() as LegalStructure;
          return <div className="text-base font-semibold">{legalStructure.en}</div>;
        },
        accessorKey: 'legalStructure'
      },
      {
        header: 'Industry Classification',
        enableSorting: true,
        cell: (row) => {
          const industryClassification = row.getValue() as IndustryClassification;
          return (
            <div className="w-[15vw]">
              <div className="text-base font-semibold">Class {industryClassification.class}</div>
              <div title={industryClassification.en} className="text-sm font-normal truncate">
                {industryClassification.en}
              </div>
            </div>
          );
        },
        accessorKey: 'industryClassification'
      },
      {
        header: 'License',
        enableSorting: true,
        cell: (row) => {
          const license = row.getValue() as License;
          const matchTypes = LICENSE_MODEL.find((item) => item.type === license.model.type);
          return (
            <div>
              <div className="text-base font-semibold">{t(`data.${matchTypes?.name}`)}</div>
              <div className="text-sm font-normal">
                {matchTypes?.description} {t('data.licensedUsers')}
              </div>
            </div>
          );
        },
        accessorKey: 'license'
      },
      {
        header: 'Total Users',
        enableSorting: true,
        cell: (row) => {
          const totalUsers = row.getValue() as string;
          return (
            <div className="w-[38px] h-[38px] rounded-md flex items-center justify-center text-base font-semibold border">
              {totalUsers}
            </div>
          );
        },
        accessorKey: 'totalUsers'
      },
      {
        header: 'Status',
        enableSorting: true,
        cell: (row) => {
          const active = row.getValue() as boolean;
          let cssStyle = !active ? 'bg-[#E5E7EB] text-black' : 'bg-[#22AD5C] text-white';

          return (
            <span
              className={`rounded-md border py-[4px] px-[10px] font-medium text-sm w-auto flex-grow-0 ${cssStyle}`}>
              {active ? 'Active' : 'Inactive'}
            </span>
          );
        },
        accessorKey: 'active'
      },
      {
        header: 'Date Created',
        columnType: 'optional',
        accessorKey: 'updatedAt',
        cell: (row) => {
          let timeString = row.getValue() as string;
          return (
            <div className="flex flex-col items-start">
              <div className={'text-sm'}>
                <b>{moment(timeString).format('DD/MM/YYYY')}</b>{' '}
                <span className={'font-normal'}>{moment(timeString).format('HH:mm')}</span>
              </div>
            </div>
          );
        }
      },
      {
        header: ' ',
        cell: (row) => {
          const _id = row.getValue() as string;
          return (
            <Button
              onClick={() => navigate(`${ROUTES.DATA}/${ROUTES.DATA_ORGANIZATION_EDIT}/${_id}`)}
              label="Edit"
              size="small"
              className="!h-[52px] !w-[80px]"
            />
          );
        },
        accessorKey: '_id'
      }
    ],
    [t, navigate]
  );

  return (
    <div className="w-full">
      <Table
        enableSetting
        enableStripedRowColor
        data={processItems}
        columns={cols}
        rightAction={
          <div className={'flex items-center gap-2'}>
            <Select
              className={'grow-2 max-w-[238px] h-[51px]'}
              items={_.orderBy(industryClassifications, 'class').map((item) => ({
                label: (
                  <div className={'flex items-center gap-1.5'}>
                    <div>Class {item.class}</div>
                  </div>
                ),
                value: item._id
              }))}
              onValueRender={() => (
                <>
                  <SvgIcon name={'list-filter'} />
                  <div>Industry</div>
                </>
              )}
              placeholder={'Select'}
              onChange={onChangeIndustry}
              value={filters?.industries}
              checkbox
            />

            <Select
              className={'grow-2 max-w-[238px] h-[51px]'}
              items={licenseModelData}
              onValueRender={() => (
                <div className={'flex items-center gap-1.5'}>
                  <SvgIcon name={'list-filter'} />
                  <div>License</div>
                </div>
              )}
              checkbox
              placeholder={'Select'}
              onChange={onChangeLicense}
              value={filters?.licenses}
            />
          </div>
        }
      />
    </div>
  );
};

export default ListOrganization;
