import { FC, useCallback, useMemo } from 'react';
import { Props } from './@types/button';
import { Loader } from 'lucide-react';

const Button: FC<Props> = ({
  leftIcon,
  rightIcon,
  label,
  className = '',
  textClassName = '',
  kind = 'primary',
  theme = 'light',
  loading = false,
  disabled,
  onClick,
  size = 'medium'
}) => {
  const colorClassName = useMemo(() => {
    if (disabled) {
      switch (kind) {
        case 'primary':
          return 'bg-white opacity-30 border-normal';
        default:
          return 'bg-gray-3';
      }
    }
    if (loading) {
      switch (kind) {
        case 'primary':
          return 'border-normal bg-black-2';
        case 'secondary':
          return 'border-blue-primary bg-blue-light-3';
        case 'tertiary':
          return 'shadow shadow-[#A6AFC366] bg-blue-light-5';
      }
    }
    switch (kind) {
      case 'primary':
        return `${theme === 'dark' ? 'bg-black-2 border-black-2' : 'border-normal hover:bg-gray-primary active:bg-black-2'}`;
      case 'secondary':
        return 'border-blue-primary hover:bg-blue-light-5 active:bg-blue-light-3';
      case 'tertiary':
        return 'shadow shadow-[#A6AFC366] hover:bg-gray-2 active:bg-blue-light-5';
    }
  }, [kind, disabled, loading, theme]);
  const textColorClassName = useMemo(() => {
    if (disabled) {
      switch (kind) {
        case 'secondary':
        case 'tertiary':
          return 'text-secondary';
      }
    }
    switch (kind) {
      case 'primary': {
        return theme === 'dark' ? 'text-white' : '';
      }
      case 'secondary':
      case 'tertiary':
        return 'text-blue-primary';
    }
    return '';
  }, [kind, disabled, theme]);

  const iconColorClassName = useMemo(() => {
    if (!disabled) {
      switch (kind) {
        case 'primary': {
          return `${theme === 'dark' ? 'text-white' : ''} active:text-white`;
        }
        case 'secondary':
          return 'active:text-black';
      }
    }
  }, [kind, disabled, theme]);

  const loadingClassName = useMemo(() => {
    return loading ? 'text-white flex justify-center' : '';
  }, [loading]);

  const handleOnClick = useCallback(
    (event: any) => {
      if (loading || disabled) {
        return;
      }
      return onClick && onClick(event);
    },
    [loading, onClick, disabled]
  );

  const spaceClassName = useMemo(() => {
    switch (size) {
      case 'medium': {
        return `h-[56px] px-4`;
      }
      case 'small': {
        return `h-[54px] px-3`;
      }
    }
  }, [size]);

  const textSpaceClassName = useMemo(() => {
    switch (size) {
      case 'medium': {
        return `text-base`;
      }
      case 'small': {
        return `text-sm`;
      }
    }
  }, [size]);

  return (
    <div
      onClick={handleOnClick}
      className={`${className} ${colorClassName} ${loadingClassName} ${spaceClassName} cursor-pointer ease-in duration-100 border-[1px] flex items-center justify-center rounded-xl w-fit`}>
      {loading ? (
        <Loader className="animate-spin" />
      ) : (
        <div
          className={`text-base font-semibold leading-6 flex gap-2 items-center justify-center icon-suffix select-none ${iconColorClassName}`}>
          {leftIcon && <div className={`${textClassName && textClassName}`}>{leftIcon}</div>}
          {label && (
            <div
              className={`${textClassName && textClassName} ${textColorClassName} ${textSpaceClassName}`}>
              {label}
            </div>
          )}
          {rightIcon && <div className={`${textClassName && textClassName}`}>{rightIcon}</div>}
        </div>
      )}
    </div>
  );
};

export default Button;
