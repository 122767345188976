import { FC } from 'react';
import { Props } from './@types/toggle';

const Toggle: FC<Props> = ({ onChange, checked, labelLeft, lableRight, disabled }) => {
  return (
    <label className="inline-flex items-center cursor-pointer gap-3">
      {labelLeft ? <div className="text-dark-primary font-medium text-sm">{labelLeft}</div> : null}
      <input
        type="checkbox"
        onChange={(e) => onChange && onChange(e.target.checked)}
        checked={checked}
        className="sr-only peer"
        disabled={disabled}
      />
      <div className="relative h-[26px] w-[50px] bg-[#CCCCCE] rounded-full flex items-center peer peer-checked:after:translate-x-[24px] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[18px] after:w-[18px] after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
      {lableRight ? (
        <div className="text-dark-primary font-medium text-sm">{lableRight}</div>
      ) : null}
    </label>
  );
};

export default Toggle;
