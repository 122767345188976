import axios from 'axios';
import { BACKEND_ENDPOINT, LOCAL_STORAGE_KEYS } from '../constants';

const instance = axios.create({
  baseURL: BACKEND_ENDPOINT
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
    config.headers.Authorization = `Bearer ${token}`
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error && error.status === 401) {
    window.location.href = "/login"
  }
  return Promise.reject(error.response.data)
});
export default instance;
