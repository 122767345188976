import { useTranslation } from 'react-i18next';
import LoginForm from '../components/Login/LoginForm';
import LogoWithHeader from '../components/layout/LogoWithHeader';
import TrustTeams from '../components/layout/TrustTeams';
import RightPanel from '../components/layout/RightPanel';
const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[100vh] w-[100vw] flex overflow-hidden">
      <div className="flex flex-col justify-center items-center h-full w-[46.7%]">
        <div className="max-w-[432px]">
          <LogoWithHeader />
          <LoginForm />
          <div>
            <div className="text-center">
              <div className="text-gray-secondary text-sm">
                {t('dontHaveAccount')}?{' '}
                <span className="text-black underline">{t('contact')} Process Be</span>
              </div>
            </div>
            <div className="mt-2 text-[10px] text-gray-secondary text-center">
              {t('byContinueTerm')} <span className="underline">{t('termOfService')} </span>{' '}
              {t('and')} <span className="underline">{t('privacyPolicy')}</span>.
            </div>
            <TrustTeams />
          </div>
        </div>
      </div>
      <RightPanel />
    </div>
  );
};

export default LoginPage;
