import { FC, useMemo } from 'react';
import { InputStatus, Props } from './@types/textarea';
import { Theme } from '@radix-ui/themes';
const getBorderColorClass = (status: InputStatus) => {
  switch (status) {
    case 'error': {
      return 'border-red-primary focus:border-red-light-3';
    }
    case 'success': {
      return 'border-green-primary focus:border-green-light-3';
    }
    case 'default': {
      return 'border-normal focus:border-blue-light-3 hover:border-blue-primary';
    }
  }
};

const getTextColorClass = (status: InputStatus, isDisabled: boolean) => {
  if (isDisabled) {
    return 'text-dark-5';
  }
  switch (status) {
    case 'error': {
      return 'text-red-primary';
    }
    case 'success': {
      return 'text-green-primary';
    }
    case 'default': {
      return 'text-dark-4';
    }
  }
};

const TextArea: FC<Props> = ({
  placeholder,
  value,
  onChange,
  label,
  status = 'default',
  helperText,
  rightIcon,
  disabled = false,
  className,
  ...props
}) => {
  const renderInput = useMemo(() => {
    const borderColorClass = !disabled && getBorderColorClass(status);
    const textColorClass = !disabled && getTextColorClass(status, disabled);
    const bgClass = disabled ? 'bg-gray-2' : '';
    const cursorClass = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
    return (
      <div className={`relative flex items-center h-full w-full`}>
        <textarea
          {...props}
          className={`w-full min-h-[100px] p-3 text-base ease-in duration-100 rounded-lg outline-none h-[46px] border-[1px] focus:border-[3px] ${borderColorClass}  ${bgClass} ${cursorClass} ${rightIcon && 'pr-10'}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        {rightIcon && (
          <div
            className={`absolute right-3 cursor-pointer z-10 input-suffix ${textColorClass} ${cursorClass}`}>
            {rightIcon}
          </div>
        )}
      </div>
    );
  }, [status, value, rightIcon, onChange, placeholder, disabled, props]);

  const renderHelperText = useMemo(() => {
    if (!helperText) {
      return null;
    }
    const textColorClass = disabled ? 'text-dark-5' : getTextColorClass(status, disabled);
    return (
      <div className={`text-sm font-normal leading-[22px] ${textColorClass}`}>{helperText}</div>
    );
  }, [helperText, status, disabled]);

  const renderLabel = useMemo(() => {
    if (!label) {
      return null;
    }
    return (
      <div
        className={` text-base leading-6 font-medium mb-[10px] ${disabled ? 'text-dark-5' : 'text-dark-primary'}`}>
        {label}
      </div>
    );
  }, [label, disabled]);

  return (
    <Theme>
      <div className={`relative ${className && className}`}>
        {renderLabel}
        {renderInput}
        {renderHelperText}
      </div>
    </Theme>
  );
};

export default TextArea;
