import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';

const MainLayout = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="w-[calc(100%_-_102px)] h-[100vh] flex flex-col">
        <Header />
        <div className={'flex-grow overflow-y-auto'}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
