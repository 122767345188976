import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string
}

const Card: React.FC<Props> = ({ children ,className }) => {
  return (
    <div className={`w-fit border-border-14 border-[1px] rounded-lg p-3 bg-white h-fit ${className}`}>
      {children}
    </div>
  );
};

export default Card;
