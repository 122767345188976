import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from '../libs/agent'
import { ICreateOrganization, IFilter, IOgrganizationMember, Organization } from "../@types/organization";

interface IAddMember {
    organizationId: string,
    member: IOgrganizationMember
}

interface IUpdateOrganization {
    body: ICreateOrganization,
    organizationId: string,
}

export const createOrganization = createAsyncThunk("organization/create", async (body: ICreateOrganization): Promise<Organization> => {
    const response = await agent.post(`organizations`, body);
    return response.data.data
})

export const updateOrganization = createAsyncThunk("organization/update", async (params: IUpdateOrganization): Promise<Organization> => {
    const response = await agent.put(`organizations/${params.organizationId}`, params.body);
    return response.data.data
})

export const listOrganization = createAsyncThunk("organization/list", async (filter?: IFilter): Promise<Organization[] | []> => {
    const response = await agent.get(`organizations`, { params: filter });
    return response.data.data
})

export const fetchOrganizationDetail = createAsyncThunk("organization/detail", async (id: string): Promise<Organization> => {
    const response = await agent.get(`organizations/${id}`);
    return response.data.data
})


export const addMemberToOrganization = createAsyncThunk("organization/add-member", async (data: IAddMember): Promise<any> => {
    const response = await agent.patch(`organizations/${data.organizationId}/add-member`, data.member);
    return response.data.data
})

export const fetchOrganizationMembers = createAsyncThunk("organization/members", async (id: string): Promise<IOgrganizationMember[]> => {
    const response = await agent.get(`organizations/${id}/members`);
    return response.data.data
})

export const updateMemberOfOrganization = createAsyncThunk("organization/update-member", async (data: IAddMember): Promise<any> => {
    const response = await agent.patch(`organizations/${data.organizationId}/update-member`, data.member);
    return response.data.data
})

