import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Props } from '../../../common/@types/modal';
import Modal from '../../../common/Modal';
import Icon from '../../../common/Icon';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import Button from '../../../common/Button';
import { ArrowRight } from 'lucide-react';
import { ORG_MEMBER_STATUS, USER_TYPES } from '../../../../config/data';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../app/hooks';
import { listOrganization } from '../../../../api/organization';
import { IOgrganizationMember } from '../../../../@types/organization';
import { addMember, editMember } from '../../../../app/slices/organization';
import Toggle from '../../../common/Toggle';

interface IProps extends Props {
  user?: IOgrganizationMember;
}

const CreateUserModal: FC<IProps> = ({ open, onClose, user }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getValues, setValue, reset, watch } = useForm<IOgrganizationMember>();
  const { firstName, lastName, role, email, userTypes, orgMemberStatus, active } = watch();

  useEffect(() => {
    if (user) {
      setValue('email', user.email);
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('role', user.role);
      setValue('userTypes', user.userTypes);
      setValue('orgMemberStatus', user.orgMemberStatus);
      setValue('active', user.active || false);
    }
  }, [user, setValue]);

  const onSubmit = () => {
    const data = getValues();
    if (user) {
      dispatch(editMember(data));
    } else {
      dispatch(addMember(data));
    }
    reset();
    onClose && onClose();
  };

  useEffect(() => {
    dispatch(listOrganization());
  }, [dispatch]);

  const orgMemberStatusItems = ORG_MEMBER_STATUS.map((item) => ({
    value: item.type,
    label: (
      <div className="text-left">
        <div className="fomt-medium text-sm"> {t(`data.${item.name}`)}</div>
        <div className="text-xs text-gray-secondary">{t(`data.${item.description}`)}</div>
      </div>
    )
  }));

  const userTypesItems = USER_TYPES.map((item) => ({
    value: item.type,
    label: (
      <div className="text-left">
        <div className="fomt-medium text-sm"> {t(`data.${item.name}`)}</div>
      </div>
    )
  }));

  return (
    <Modal
      open={open}
      content={
        <div className="flex flex-col gap-4">
          <div className="p-[16.5px] rounded-2xl border border-boder-4 w-fit">
            <Icon name="user-plus" size={30} />
          </div>
          <div>
            <div className="font-semibold text-2xl">Create New Member</div>
            <div className="text-base">Choose a successor who will receive your output.</div>
          </div>
          <form>
            <div className="grid grid-cols-2 gap-x-6 gap-y-[18px]">
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">Last Name*</div>
                <Input
                  className="w-[300px]"
                  value={lastName}
                  onChange={(e) => setValue('lastName', e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">First Name*</div>
                <Input
                  className="w-[300px]"
                  value={firstName}
                  onChange={(e) => setValue('firstName', e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">Role</div>
                <Input
                  className="w-[300px]"
                  value={role}
                  onChange={(e) => setValue('role', e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">
                  Business Email Address*
                </div>
                <Input
                  className="w-[300px]"
                  value={email}
                  onChange={(e) => setValue('email', e.target.value)}
                />
              </div>
              <div className="col-span-2">
                <div className="mb-2 text-base font-medium text-gray-900">User Type</div>
                <Select
                  className="w-full"
                  items={userTypesItems}
                  value={userTypes || []}
                  onChange={(value) => setValue('userTypes', value)}
                  placeholder="Placeholder"
                />
              </div>
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">Org-Member Status*</div>
                <Select
                  className="w-full"
                  items={orgMemberStatusItems}
                  value={orgMemberStatus}
                  onChange={(value) => setValue('orgMemberStatus', value)}
                />
              </div>
              <div>
                <div className="mb-2 text-base font-medium text-gray-900">Organization*</div>
                <Select
                  className="w-full"
                  items={orgMemberStatusItems}
                  value=""
                  onChange={() => {}}
                  disabled
                />
              </div>
              <div className={'col-span-2 flex items-center justify-between'}>
                <div>Is the User active?</div>
                <Toggle
                  checked={active}
                  onChange={(value) => setValue('active', value)}
                  lableRight={active ? 'Yes' : 'No'}
                />
              </div>
            </div>

            <div className="flex gap-4 mt-4">
              <Button label="Back" className="flex-1" onClick={onClose} />
              <Button
                disabled={[firstName, lastName, email, userTypes, orgMemberStatus].some(
                  (v) => !v?.toString()
                )}
                label="Continue"
                className="flex-1"
                rightIcon={<ArrowRight />}
                onClick={onSubmit}
              />
            </div>
          </form>
        </div>
      }
      onClose={onClose}
    />
  );
};

export default CreateUserModal;
