import { createSlice } from '@reduxjs/toolkit'

const defaultState = {
    contentBackground: false,
    contentSpacing: true
}

const initialState = defaultState
const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setContentBackground: (state, action) => {
            state.contentBackground = action.payload
        },
        setContentSpacing: (state, action) => {
            state.contentSpacing = action.payload
        }
    },
})

export const { setContentBackground, setContentSpacing } = layoutSlice.actions

export default layoutSlice.reducer