import { UserTypes } from "../@types/user"


export const isSuperAdmin = (userType: UserTypes[]) => {
    if (!userType.length) {
        return false
    }
    return userType.includes('SuperAdmin')
}

export const isOrgManager = (userType: UserTypes[]) => {
    return userType.includes('OrgManager')
}