import { createSlice } from '@reduxjs/toolkit'
import { LegalStructure } from '../../@types/legal-structrue'
import { listLegalStructure } from '../../api/legal-structrue'

interface IState {
    list: LegalStructure[],
    loading: boolean
}

const defaultState: IState = {
    list: [],
    loading: false
}

const initialState = defaultState
const legalStructureSlice = createSlice({
    name: 'legalStructure',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(listLegalStructure.pending, (state) => {
            state.loading = true
        })
        builder.addCase(listLegalStructure.fulfilled, (state, action) => {
            state.loading = false
            state.list = action.payload
        })
    }
})

export default legalStructureSlice.reducer