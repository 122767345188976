import { useMemo } from 'react';
import { useAppSelector } from '../../../app/hooks';
import AllProcess from '../../../components/Data/Process/AllProcess';
import { DataPageEnum } from '../../../constants/data';
import NeedsValidation from '../../../components/Data/Process/NeedsValidation';

const Process = () => {
  const { page } = useAppSelector((state) => state.data);

  const renderTableData = useMemo(() => {
    switch (page) {
      case DataPageEnum.ALL_PROCESS:
        return <AllProcess />;
      // case DataPageEnum.MY_PROCESS_STEPS:
      //   return <MyProcessSteps />;
      case DataPageEnum.NEED_VALIDATIONS:
        return <NeedsValidation />;
    }
  }, [page]);

  return (
    <div className="h-full w-full">
      {/*<HeaderFilter />*/}
      {renderTableData}
    </div>
  );
};

export default Process;
