import * as Tooltip from '@radix-ui/react-tooltip';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  text: string;
  placement?: 'bottom' | 'top' | 'right' | 'left';
};

function CustomTooltip(props: Props) {
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{props.children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            side={props.placement || 'bottom'}
            className="select-none rounded-[5px] bg-[#111928] px-[12px] py-2 text-[14px] text-[#BBBBBB] leading-[17.64px] will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade"
            sideOffset={5}>
            {props.text}
            <Tooltip.Arrow className="fill-[#111928]" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export default CustomTooltip;
