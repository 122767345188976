import { Fragment, ReactNode, useMemo } from 'react';
import Table from '../../common/Table';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import Badge from '../../common/Badge';
import Button from '../../common/Button';
import { ChevronDown } from 'lucide-react';
import moment from 'moment';
import Collapse from '../../common/Collapse';
import { useAppSelector } from '../../../app/hooks';
import Avatar from '../../common/Avatar';

const ValidateTeamProcessTable = () => {
  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        cell: (row) => {
          return <div className="text-lg">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Process Name',
        cell: (row) => {
          const info = row.getValue();
          return (
            <div>
              <div className="font-bold text-base">{_.get(info, 'name')}</div>
              <div className="font-normal text-sm">{_.get(info, 'description')}</div>
            </div>
          );
        },
        accessorKey: 'info'
      },
      {
        header: 'Previous Department',
        cell: (row) => {
          return (
            <Badge
              text={
                <div className="flex gap-1">
                  {row.getValue() as ReactNode}
                  <ChevronDown className="w-5" />
                </div>
              }
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'previousDepartment'
      },
      {
        header: 'Department',
        cell: (row) => {
          return (
            <Badge
              text={row.getValue() as ReactNode}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'department'
      },
      {
        header: 'New Process Owner',
        cell: (row) => {
          const user = row.getValue();
          return (
            <div className="flex items-center gap-3 border border-border-9 rounded-2xl w-fit p-2">
              <div className="rounded-lg">
                <Avatar src={_.get(user, 'image', '')} />
              </div>
              <div>
                <div className="font-medium text-sm">
                  {_.get(user, 'firstName')}, {_.get(user, 'lastName')}
                </div>
                <div className="text-gray-secondary font-normal text-sm">{_.get(user, 'role')}</div>
              </div>
              <Badge
                text={_.get(user, 'department')}
                variant="white"
                state="outline"
                round="semi-round"
                size="medium"
              />
              <ChevronDown className="w-5" />
            </div>
          );
        },
        accessorKey: 'newProcessOwner'
      },
      {
        header: 'Total Steps',
        cell: (row) => {
          return (
            <div className="rounded-md w-[38px] h-[38px] border p-[6px] border-border-12 text-gray-7 font-semibold text-sm flex items-center justify-center">
              {row.getValue() as ReactNode}
            </div>
          );
        },
        accessorKey: 'steps'
      },
      {
        header: 'Version',
        cell: (row) => {
          const version = row.getValue() as string;
          return (
            <Badge
              className="capitalize"
              variant="success"
              text={version}
              state="dou-tone"
              size="semi"
            />
          );
        },
        accessorKey: 'version'
      },
      {
        header: '',
        cell: () => (
          <div className="flex items-center gap-4 justify-end">
            <Button label="Continue" size="small" />
            <ChevronDown className="w-5" />
          </div>
        ),
        accessorKey: 'action'
      }
    ],
    []
  );
  const dummyData = () => {
    const items = [];
    for (let i = 0; i < 3; i++) {
      items.push({
        id: i + 1,
        info: {
          name: 'Fidelity wireframe landing',
          description: 'High-fidelity wireframe landing'
        },
        department: 'Online Marketing',
        previousDepartment: 'Marketing',
        myProcessStep: {
          step: 2,
          description:
            'High-fidelity wireframe landing employee Onboarding involves integrating a new hire into the organization by completing necessary paperwork, providing orientation, and assigning initial training. This process ensures the employee is equipped with the tools, knowledge, and resources needed to perform their role effectively. It also fosters engagement and alignment with company culture from day one. perform their role effectively. It also fosters engagement and alignment with company culture from day one.perform their role effectively. It also fosters engagement and alignment with company culture from day one.perform their role effectively. It also fosters engagement and alignment with company culture from day one.',
          status: 'in progress'
        },
        newProcessOwner: {
          firstName: 'Croft',
          lastName: 'Lara',
          image:
            'https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80',
          email: 'musharof@example.com',
          role: 'Marketing Department Manager',
          department: 'Marketing'
        },
        status: 'in progress',
        dueDate: moment().toDate(),
        steps: 20,
        version: 'new'
      });
    }
    return items;
  };
  return <Table data={dummyData()} columns={cols} />;
};

const ValidateMyProcessSteps = () => {
  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        cell: (row) => {
          return <div className="text-lg">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Process Name',
        cell: (row) => {
          const info = row.getValue();
          return (
            <div>
              <div className="font-bold text-base">{_.get(info, 'name')}</div>
              <div className="font-normal text-sm">{_.get(info, 'description')}</div>
            </div>
          );
        },
        accessorKey: 'info'
      },
      {
        header: 'Department',
        cell: (row) => {
          return (
            <Badge
              text={row.getValue() as ReactNode}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'department'
      },
      {
        header: 'Process Step',
        cell: (row) => {
          const step = row.getValue();
          return (
            <div className="flex items-center gap-3 border border-border-9 rounded-2xl w-fit p-2">
              <div className="w-[38px] h-[38px] bg-badge-1 text-gray-7 font-semibold text-sm flex items-center justify-center rounded-md">
                #{_.get(step, 'step')}
              </div>
              <div className="truncate max-w-[200px]">{_.get(step, 'description')}</div>
            </div>
          );
        },
        accessorKey: 'myProcessStep'
      },
      {
        header: 'Assignment Date',
        cell: (row) => {
          const assignedDate = row.getValue() as string;
          return (
            <div className="text-sm">
              <div>
                <span className="font-bold">{moment(assignedDate).format('DD/MM/YYYY')}</span>{' '}
                <span className="font-normal">{moment(assignedDate).format('HH:mm')}</span>
              </div>
            </div>
          );
        },
        accessorKey: 'assignedDate'
      },
      {
        header: 'Due Date',
        cell: (row) => {
          const dueDate = row.getValue() as string;
          return (
            <div className="text-sm">
              <div>
                <span className="font-bold">{moment(dueDate).format('DD/MM/YYYY')}</span>{' '}
                <span className="font-normal">{moment(dueDate).format('HH:mm')}</span>
              </div>
              <div className="font-medium">2 Days, 01:48 min left</div>
            </div>
          );
        },
        accessorKey: 'dueDate'
      },
      {
        header: 'Status',
        cell: (row) => {
          return (
            <Badge
              className="capitalize"
              variant="success"
              text="Not Started"
              state="dou-tone"
              size="semi"
            />
          );
        },
        accessorKey: 'status'
      },
      {
        header: '',
        cell: () => (
          <div className="flex items-center gap-4 justify-end">
            <Button label="View" />
            <ChevronDown className="w-5" />
          </div>
        ),
        accessorKey: 'action'
      }
    ],
    []
  );

  const dummyData = () => {
    const items = [];
    for (let i = 0; i < 3; i++) {
      items.push({
        id: i + 1,
        info: {
          name: 'Fidelity wireframe landing',
          description: 'High-fidelity wireframe landing'
        },
        department: 'Marketing',
        myProcessStep: {
          step: 2,
          description:
            'High-fidelity wireframe landing employee Onboarding involves integrating a new hire into the organization by completing necessary paperwork, providing orientation, and assigning initial training. This process ensures the employee is equipped with the tools, knowledge, and resources needed to perform their role effectively. It also fosters engagement and alignment with company culture from day one. perform their role effectively. It also fosters engagement and alignment with company culture from day one.perform their role effectively. It also fosters engagement and alignment with company culture from day one.perform their role effectively. It also fosters engagement and alignment with company culture from day one.',
          status: 'in progress'
        },
        status: 'in progress',
        dueDate: moment().add(1, 'day').toDate(),
        assignedDate: moment().toDate()
      });
    }
    return items;
  };

  return (
    <div className="mt-4 w-full">
      <Table data={dummyData()} columns={cols} />
    </div>
  );
};

const NeedsValidation = () => {
  const { userTypes } = useAppSelector((state) => state.auth);

  const renderValidateTeamProcess = useMemo(() => {
    if (!userTypes.includes('OrgManager')) {
      return null;
    }
    return (
      <div className="mt-4 w-full">
        <Collapse
          label={
            <div className="flex gap-1">
              <img src="/assets/icons/user-round-check.svg" alt="Icon" />
              <div className="text-black font-semibold text-base">Validate Team Process</div>
              <Badge text="2" variant="warning" state="fill" round="full-round" size="small" />
            </div>
          }
          content={<ValidateTeamProcessTable />}
        />
      </div>
    );
  }, [userTypes]);

  return (
    <Fragment>
      {renderValidateTeamProcess}
      <div className="mt-4 w-full">
        <Collapse
          label={
            <div className="flex gap-1">
              <img src="/assets/icons/search-check-active.svg" alt="Icon" />
              <div className="text-black font-semibold text-base">Validate My Process Steps</div>
              <Badge text="2" variant="warning" state="fill" round="full-round" size="small" />
            </div>
          }
          content={<ValidateMyProcessSteps />}
        />
      </div>
    </Fragment>
  );
};

export default NeedsValidation;
