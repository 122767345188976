import { UserTypes } from '../@types/user';
import { IconName } from '../components/common/@types/icon';
interface IDataRoutes {
  key: string;
  icon?: IconName;
  label: string;
  route: string;
  permissions?: UserTypes[];
}

export const ROUTES = {
  DASHBOARD: '/dashboard',
  DATA: '/data',
  NOTIFICATION: '/notifications',
  PROFILE: '/profiles',
  SETTING: '/settings',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password/:token',
  SIGN_UP: '/sign-up/:token',
  ANY: '*',
  MAIN: '/',
  DATA_PROCESS: 'process',
  MY_PROCESS: 'my-process',
  MY_TASK: 'my-task',
  DATA_ORGANIZATION: 'organizations',
  DATA_DEPARTMENT: 'departments',
  DATA_ORGANIZATION_MEMBERS: 'organization-members',
  DATA_TEAM_MEMBERS: 'team_members',
  DATA_NOTES: 'notes',
  DATA_FILES: 'files',
  DATA_OVERVIEW: 'overview',
  DATA_PROCESS_CREATE: 'process/create',
  DATA_ORGANIZATION_CREATE: 'organizations/create',
  DATA_ORGANIZATION_EDIT: 'organizations/edit',
  DATA_DEPARTMENT_CREATE: 'departments/create',
  DATA_DEPARTMENT_EDIT: 'departments/edit'
};

export const DATA_ROUTES: IDataRoutes[] = [
  {
    key: 'overview',
    label: 'Overview',
    route: ROUTES.DATA_OVERVIEW
  },
  {
    key: 'departments',
    label: 'Departments',
    route: ROUTES.DATA_DEPARTMENT,
    permissions: ['OrgManager']
  },
  {
    key: 'organization-members',
    label: 'Organization Member',
    route: ROUTES.DATA_ORGANIZATION_MEMBERS,
    permissions: ['OrgManager']
  },
  {
    key: 'process',
    icon: 'waypoints-active',
    label: 'All Processes',
    route: ROUTES.DATA_PROCESS,
    permissions: ['OrgManager', 'OrgMember', 'DeptManager', 'DeptMember']
  },
  {
    key: 'my-process',
    icon: 'steps-active',
    label: 'My Process Steps',
    route: ROUTES.MY_PROCESS,
    permissions: ['OrgManager', 'OrgMember', 'DeptManager', 'DeptMember']
  },
  {
    key: 'my-task',
    label: 'My Tasks',
    route: ROUTES.MY_TASK,
    permissions: ['OrgManager', 'OrgMember', 'DeptManager', 'DeptMember']
  },
  {
    key: 'organizations',
    label: 'Organizations',
    route: ROUTES.DATA_ORGANIZATION,
    permissions: ['SuperAdmin']
  },
  {
    key: 'team-members',
    label: 'Team Members',
    route: ROUTES.DATA_TEAM_MEMBERS,
    permissions: ['DeptManager']
  },
  {
    key: 'notes',
    label: 'Notes',
    route: ROUTES.DATA_NOTES,
    permissions: ['DeptManager', 'DeptMember']
  },
  {
    key: 'files',
    label: 'Files',
    route: ROUTES.DATA_FILES,
    permissions: ['DeptManager', 'DeptMember']
  }
];
