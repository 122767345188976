import { UserTypes } from "../@types/user"

export enum DataPageEnum {
    "ALL_PROCESS" = 'all-process',
    "MY_PROCESS_STEPS" = 'my-process-steps',
    "NEED_VALIDATIONS" = 'need-validations'
}

export interface IDataPageItem {
    label: string,
    value: string,
    icon: string,
    permissions?: UserTypes[],
    key: 'all-process' | 'my-process-steps' | 'need-validations'
}

export const DATA_PAGES_ITEMS: IDataPageItem[] = [
    {
        label: 'All Process',
        key: 'all-process',
        value: DataPageEnum.ALL_PROCESS,
        icon: 'waypoints',
        permissions: ['OrgManager']
    },
    {
        label: 'My Process Steps',
        key: 'my-process-steps',
        value: DataPageEnum.MY_PROCESS_STEPS,
        icon: 'steps'
    },
    {
        label: 'Need Validations',
        key: 'need-validations',
        value: DataPageEnum.NEED_VALIDATIONS,
        icon: 'search-check'
    }
]

