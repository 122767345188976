import { FC } from 'react';
import { Props } from './@types/icon';
import { ReactSVG } from 'react-svg';

const SvgIcon: FC<Props> = ({ name, bold = false, size, color, className }) => {
  return (
    <>
      <ReactSVG
        className={className}
        src={`/assets/icons${bold ? '/bold' : ''}/${name}.svg`}
        beforeInjection={(svg) => {
          svg.setAttribute(
            'style',
            `width: ${size}px; height: ${size}px; color: ${color || 'initial'}`
          );
        }}
      />
    </>
  );
};

export default SvgIcon;
