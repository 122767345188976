import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import SvgIcon from '../../../components/common/SvgIcon';
import Badge from '../../../components/common/Badge';
import Avatar from '../../../components/common/Avatar';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import Table from '../../../components/common/Table';
import ColumnSettingDisplayDropdown from '../../../components/common/ColumnSettingDisplayDropdown';

const NEW_DEPT_DATA = [
  {
    id: 1,
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    prevDepartment: 'Marketing',
    newDepartment: 'Marketing',
    newOwner: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    steps: 5,
    version: {
      status: 'New',
      id: '#20241024'
    }
  },
  {
    id: 2,
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    prevDepartment: 'Marketing',
    newDepartment: 'Marketing',
    newOwner: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    steps: 5,
    version: {
      status: 'Published',
      id: '#20241024'
    }
  },
  {
    id: 3,
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    prevDepartment: 'Marketing',
    newDepartment: 'Marketing',
    newOwner: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing',
      disabled: true
    },
    steps: 5,
    version: {
      status: 'Published',
      id: '#20241024'
    }
  }
];

const PROCESS_RECORDING_DATA = [
  {
    id: 1,
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    processOwner: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    steps: 5,
    status: 'Not Started',
    version: {
      status: 'New',
      id: '#20241024'
    },
    action: 'Record'
  },
  {
    id: 2,
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    processOwner: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    steps: 5,
    status: 'Issue',
    version: {
      status: 'New',
      id: '#20241024'
    },
    action: 'View'
  },
  {
    id: 3,
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    processOwner: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    steps: 13,
    status: 'Finished',
    version: {
      status: 'New',
      id: '#20241024'
    },
    action: 'View'
  }
];

const PROCESS_RECORDED_DATA = [
  {
    id: 1,
    processStep: {
      name: 'New Process Step',
      desc: 'This process is still inprogess..'
    },
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    assignedBy: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    dueDate: {
      day: '23/12/2024',
      time: '12:00',
      timeLeft: '2 Days, 01:48 min left'
    },
    action: 'Continue'
  },
  {
    id: 2,
    processStep: {
      name: 'New Process Step',
      desc: 'This process is still inprogess..'
    },
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    assignedBy: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    dueDate: {
      day: '23/12/2024',
      time: '12:00',
      timeLeft: '2 Days, 01:48 min left'
    },
    action: 'Check'
  },
  {
    id: 3,
    processStep: {
      name: 'New Process Step',
      desc: 'This process is still inprogess..'
    },
    process: {
      name: 'Target group analysis',
      desc: 'IdentificatioIdentification and analysis of target groups to optimize the approach and positioning of products.'
    },
    department: 'Marketing',
    assignedBy: {
      firstName: 'Croft',
      lastName: 'Lara',
      role: 'Marketing Expert',
      department: 'Marketing'
    },
    dueDate: {
      day: '23/12/2024',
      time: '12:00',
      timeLeft: '2 Days, 01:48 min left'
    },
    action: 'Check'
  }
];

function MyTask() {
  const [visible, setVisible] = useState<Record<string, string[]>>({
    newDeptTable: [],
    recordingTable: [],
    recordedTable: []
  });

  const newDepartmentProcesses = useMemo<ColumnDef<any>[]>(() => {
    return [
      {
        header: 'P.ID',
        columnType: 'optional',
        accessorKey: 'id'
      },
      {
        header: 'Process',
        cell: (row: any) => {
          const info = row.getValue() as Record<string, string>;
          return (
            <div>
              <div className="font-bold text-base mb-1">{info.name}</div>
              <div className="max-w-[338px] font-normal text-sm text-ellipsis overflow-hidden">
                {info.desc}
              </div>
            </div>
          );
        },
        accessorKey: 'process'
      },
      {
        header: 'Previous Department',
        cell: (row: any) => {
          return (
            <Badge
              text={row.getValue() as ReactNode}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'prevDepartment'
      },
      {
        header: 'New Department',
        cell: (row: any) => {
          return (
            <Badge
              text={row.getValue() as ReactNode}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'newDepartment'
      },
      {
        header: 'Select new Process Owner',
        cell: (row: any) => {
          const value = row.getValue() as Record<string, any>;

          return (
            <div
              className={`flex items-center min-w-[330px] justify-between border border-gray rounded-lg p-[8px] !pr-[16px] ${value?.disabled ? 'opacity-50' : ''}`}>
              <div className="flex items-center gap-1.5">
                <Avatar
                  className="!w-[34px] !h-[34px]"
                  src={''}
                  alt={`${value.firstName} ${value.lastName}`}
                />
                <div className="text-sm">
                  <div className="font-medium">
                    <span className="uppercase">{value.firstName},</span> {value.lastName}
                  </div>
                  <div className="text-xs text-gray-secondary">{value.role}</div>
                </div>
              </div>

              <div className={'flex items-center gap-2'}>
                <Badge
                  text={value.department}
                  variant="white"
                  state="outline"
                  round="semi-round"
                  size="medium"
                />

                <SvgIcon name={'chevron-down'} size={16} />
              </div>
            </div>
          );
        },
        accessorKey: 'newOwner'
      },
      {
        header: '# Steps',
        cell: (row: any) => {
          return (
            <span className="rounded-md border px-[11.5px] py-[10px] border-border-12 text-gray-7 font-semibold text-sm w-auto flex-grow-0">
              {row.getValue() as ReactNode} / 10
            </span>
          );
        },
        accessorKey: 'steps'
      },
      {
        header: 'Version',
        accessorKey: 'version',
        cell: (row: any) => {
          let value = row.getValue() as Record<string, string>;
          let isPublished = value.status === 'Published';
          let color = isPublished ? 'text-[#15803D]' : 'text-[#9CA3AF]';

          return (
            <div className="flex flex-col items-start">
              <div className={`text-lg leading-7 ${color} mb-0`}>{value.status}</div>
              <div className={`text-sm leading-5 ${color}`}>{value.id}</div>
            </div>
          );
        }
      },
      {
        header: '',
        cell: () => (
          <div className="flex items-center gap-4 justify-end">
            <Button label="Complete" size="small" />
          </div>
        ),
        accessorKey: 'action'
      }
    ] as any[];
  }, []);

  const processRecordingCols = useMemo<ColumnDef<any>[]>(() => {
    return [
      {
        header: 'P.ID',
        columnType: 'optional',
        accessorKey: 'id'
      },
      {
        header: 'Process',
        cell: (row: any) => {
          const info = row.getValue() as Record<string, string>;
          return (
            <div>
              <div className="font-bold text-base mb-1">{info.name}</div>
              <div className="max-w-[338px] font-normal text-sm text-ellipsis overflow-hidden">
                {info.desc}
              </div>
            </div>
          );
        },
        accessorKey: 'process'
      },
      {
        header: 'Department',
        cell: (row: any) => {
          return (
            <Badge
              text={row.getValue() as ReactNode}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'department'
      },
      {
        header: 'Process Owner',
        cell: (row: any) => {
          const value = row.getValue() as Record<string, any>;

          return (
            <div
              className={`flex items-center min-w-[330px] justify-between border border-gray rounded-lg p-[8px] !pr-[16px]`}>
              <div className="flex items-center gap-1.5">
                <Avatar
                  className="!w-[34px] !h-[34px]"
                  src={''}
                  alt={`${value.firstName} ${value.lastName}`}
                />
                <div className="text-sm">
                  <div className="font-medium">
                    <span className="uppercase">{value.firstName},</span> {value.lastName}
                  </div>
                  <div className="text-xs text-gray-secondary">{value.role}</div>
                </div>
              </div>

              <div className={'flex items-center gap-2'}>
                <Badge
                  text={value.department}
                  variant="white"
                  state="outline"
                  round="semi-round"
                  size="medium"
                />
              </div>
            </div>
          );
        },
        accessorKey: 'processOwner'
      },
      {
        header: '# Steps',
        cell: (row: any) => {
          return (
            <span className="rounded-md border px-[11.5px] py-[10px] border-border-12 text-gray-7 font-semibold text-sm w-auto flex-grow-0">
              {row.getValue() as ReactNode}
            </span>
          );
        },
        accessorKey: 'steps'
      },
      {
        header: 'Status',
        cell: (row: any) => {
          let cssStyle = '';
          let statusValue = row.getValue() as string;
          switch (statusValue) {
            case 'Not Started':
              cssStyle += 'bg-[#E5E7EB] text-black';
              break;

            case 'Issue':
              cssStyle += 'bg-[#F59E0B] text-white';
              break;

            case 'Finished':
              cssStyle += 'bg-[#22AD5C] text-white';
              break;
          }

          return (
            <span
              className={`rounded-md border py-[4px] px-[10px] font-medium text-sm w-auto flex-grow-0 ${cssStyle}`}>
              {statusValue}
            </span>
          );
        },
        accessorKey: 'status'
      },
      {
        header: 'Version',
        accessorKey: 'version',
        cell: (row: any) => {
          let value = row.getValue() as Record<string, string>;
          let isPublished = value.status === 'Published';
          let color = isPublished ? 'text-[#15803D]' : 'text-[#9CA3AF]';

          return (
            <div className="flex flex-col items-start">
              <div className={`text-lg leading-7 ${color} mb-0`}>{value.status}</div>
              <div className={`text-sm leading-5 ${color}`}>{value.id}</div>
            </div>
          );
        }
      },
      {
        header: '',
        cell: (row: any) => {
          let action = row.getValue() as string;
          switch (action) {
            case 'Record':
              return (
                <div className="flex items-center gap-4 justify-end">
                  <Button
                    className={'text-[#DC2626] bg-[#FEE2E2] w-[105px]'}
                    leftIcon={<div className={'w-[10px] h-[10px] rounded-full bg-[#DC2626]'} />}
                    label="Record"
                    size="small"
                  />
                </div>
              );

            case 'View':
              return (
                <div className="flex items-center gap-4 justify-end">
                  <Button label="View" size="small" className={'w-full'} />
                </div>
              );
          }
        },
        accessorKey: 'action'
      }
    ] as any[];
  }, []);

  const processRecordedCols = useMemo<ColumnDef<any>[]>(() => {
    return [
      {
        header: 'S.ID',
        columnType: 'optional',
        accessorKey: 'id'
      },
      {
        header: 'Process Step',
        cell: (row: any) => {
          const info = row.getValue() as Record<string, string>;
          return (
            <div className={'border border-[#EBEBEB] inline-flex p-2 rounded-lg gap-3'}>
              <div className={'flex items-center gap-3'}>
                <SvgIcon name={'steps'} size={20} color={'#838383'} />
                <div>
                  <div className={'font-semibold leading-[17.64px] text-sm'}>{info?.name}</div>
                  <div
                    className={
                      'text-xs leading-[15.12px] max-w-[305px] text-ellipsis overflow-hidden'
                    }>
                    {info?.desc}
                  </div>
                </div>
              </div>
            </div>
          );
        },
        accessorKey: 'processStep'
      },
      {
        header: 'Process',
        cell: (row: any) => {
          const info = row.getValue() as Record<string, string>;
          return (
            <div className={'flex items-center gap-4'}>
              <div>
                <div className="font-bold text-base mb-1">{info.name}</div>
                <div className="max-w-[338px] font-normal text-sm text-ellipsis overflow-hidden">
                  {info.desc}
                </div>
              </div>

              <div
                className={
                  'border w-[34px] h-[34px] rounded-lg border-[#EBEBEB] flex items-center justify-center cursor-pointer'
                }>
                <SvgIcon name={'eye'} size={18} color={'#636369'} />
              </div>
            </div>
          );
        },
        accessorKey: 'process'
      },
      {
        header: 'Department',
        cell: (row: any) => {
          return (
            <Badge
              text={row.getValue() as ReactNode}
              variant="white"
              state="outline"
              round="semi-round"
              size="large"
            />
          );
        },
        accessorKey: 'department'
      },
      {
        header: 'Assigned by',
        cell: (row: any) => {
          const value = row.getValue() as Record<string, any>;

          return (
            <div
              className={`flex items-center min-w-[330px] justify-between border border-gray rounded-lg p-[8px] !pr-[16px]`}>
              <div className="flex items-center gap-1.5">
                <Avatar
                  className="!w-[34px] !h-[34px]"
                  src={''}
                  alt={`${value.firstName} ${value.lastName}`}
                />
                <div className="text-sm">
                  <div className="font-medium">
                    <span className="uppercase">{value.firstName},</span> {value.lastName}
                  </div>
                  <div className="text-xs text-gray-secondary">{value.role}</div>
                </div>
              </div>

              <Badge
                text={value.department}
                variant="white"
                state="outline"
                round="semi-round"
                size="medium"
              />
            </div>
          );
        },
        accessorKey: 'assignedBy'
      },
      {
        header: 'Due Date',
        cell: (row: any) => {
          const value = row.getValue() as Record<string, string>;

          return (
            <div className="flex flex-col items-start">
              <div className={'text-sm'}>
                <b>{value.day}</b> <span className={'font-normal'}>{value.time}</span>
              </div>
              <div className={'font-medium'}>{value.timeLeft}</div>
            </div>
          );
        },
        accessorKey: 'dueDate'
      },
      {
        header: '',
        cell: (row: any) => {
          let action = row.getValue() as string;

          return (
            <div className="flex items-center gap-4 justify-end">
              <Button label={action} size="small" className={'w-full'} />
            </div>
          );
        },
        accessorKey: 'action'
      }
    ] as any[];
  }, []);

  useEffect(() => {
    if (visible.newDeptTable.length === 0 && newDepartmentProcesses.length > 0) {
      setVisible((prev) => ({
        ...prev,
        newDeptTable: ([...newDepartmentProcesses] as Record<string, any>[])
          .filter((col) => !col.columnType)
          .map((col) => col.accessorKey)
      }));
    }

    if (visible.recordingTable.length === 0 && processRecordingCols.length > 0) {
      setVisible((prev) => ({
        ...prev,
        recordingTable: ([...processRecordingCols] as Record<string, any>[])
          .filter((col) => !col.columnType)
          .map((col) => col.accessorKey)
      }));
    }

    if (visible.recordedTable.length === 0 && processRecordedCols.length > 0) {
      setVisible((prev) => ({
        ...prev,
        recordedTable: ([...processRecordedCols] as Record<string, any>[])
          .filter((col) => !col.columnType)
          .map((col) => col.accessorKey)
      }));
    }
  }, [
    visible.recordedTable,
    visible.recordingTable,
    visible.newDeptTable,
    newDepartmentProcesses,
    processRecordingCols,
    processRecordedCols
  ]);

  const handleToggleVisible = (table: string, columnId: string) => {
    if (visible[table].includes(columnId)) {
      setVisible((prev) => ({
        ...prev,
        [table]: prev[table].filter((item) => item !== columnId)
      }));
    } else {
      setVisible((prev) => ({
        ...prev,
        [table]: [...prev[table], columnId]
      }));
    }
  };

  const visibleSettingOptions = useMemo(
    () => ({
      newDept: ([...newDepartmentProcesses] as Record<string, any>[]).map((col) => ({
        name: col.header,
        id: col.accessorKey || col.id || col.header,
        type: col.columnType || undefined
      })),
      recording: ([...processRecordingCols] as Record<string, any>[]).map((col) => ({
        name: col.header,
        id: col.accessorKey || col.id || col.header,
        type: col.columnType || undefined
      })),
      recorded: ([...processRecordedCols] as Record<string, any>[]).map((col) => ({
        name: col.header,
        id: col.accessorKey || col.id || col.header,
        type: col.columnType || undefined
      }))
    }),
    [newDepartmentProcesses, processRecordingCols, processRecordedCols]
  );

  return (
    <>
      <div className="flex gap-4">
        <Button
          className="min-w-[301px] !justify-start"
          label="Search"
          size="small"
          leftIcon={<Icon name="search" />}
        />
      </div>

      <div className="mt-4 w-full p-4 border border-[#DEE2E8] rounded-lg bg-[#F9F9F9]">
        <div className={'flex items-center justify-between w-full'}>
          <div className={'flex items-center gap-2'}>
            <div className={'flex items-center'}>
              <SvgIcon name={'waypoints-active'} color={'#010101'} size={20} />
              <SvgIcon name={'arrow-right'} color={'#9CA3AF'} size={20} />
              <SvgIcon name={'fax'} size={20} color={'#010101'} />
            </div>
            <div className={'font-semibold text-base leading-[20.16px]'}>
              New Department Processes
            </div>
            <div
              className={
                'leading-[17.66px] font-bold w-[22.36px] text-white bg-[#F3BA1D] text-[9.81px] rounded-full text-center'
              }>
              4
            </div>
          </div>

          <div className={'flex items-center gap-2'}>
            <ColumnSettingDisplayDropdown
              list={visibleSettingOptions.newDept}
              values={visible.newDeptTable}
              onChange={(colId: string) => handleToggleVisible('newDeptTable', colId)}>
              <SvgIcon className={'cursor-pointer'} name={'setting'} size={20} color={'#020202'} />
            </ColumnSettingDisplayDropdown>
            <SvgIcon name={'chevron-down'} size={20} color={'#020202'} />
          </div>
        </div>

        <Table
          enableStripedRowColor
          visibleColumns={visible.newDeptTable}
          data={NEW_DEPT_DATA}
          columns={newDepartmentProcesses}
        />
      </div>

      <div className="mt-4 w-full p-4 border border-[#DEE2E8] rounded-lg bg-[#F9F9F9]">
        <div className={'flex items-center justify-between w-full'}>
          <div className={'flex items-center gap-2'}>
            <div className={'flex items-center'}>
              <SvgIcon name={'waypoints-active'} color={'#010101'} size={20} />
              <img className="h-[17px] w-[41px]" src={'/assets/images/rec.png'} alt="Logo" />
            </div>
            <div className={'font-semibold text-base leading-[20.16px]'}>
              Process Recording Management
            </div>
            <div
              className={
                'leading-[17.66px] font-bold w-[22.36px] text-white bg-[#F3BA1D] text-[9.81px] rounded-full text-center'
              }>
              4
            </div>
          </div>

          <div className={'flex items-center gap-2'}>
            <ColumnSettingDisplayDropdown
              list={visibleSettingOptions.recording}
              values={visible.recordingTable}
              onChange={(colId: string) => handleToggleVisible('recordingTable', colId)}>
              <SvgIcon className={'cursor-pointer'} name={'setting'} size={20} color={'#020202'} />
            </ColumnSettingDisplayDropdown>
            <SvgIcon name={'chevron-down'} size={20} color={'#020202'} />
          </div>
        </div>

        <Table
          visibleColumns={visible.recordingTable}
          enableStripedRowColor
          data={PROCESS_RECORDING_DATA}
          columns={processRecordingCols}
        />
      </div>

      <div className="mt-4 w-full p-4 border border-[#DEE2E8] rounded-lg bg-[#F9F9F9]">
        <div className={'flex items-center justify-between w-full'}>
          <div className={'flex items-center gap-2'}>
            <div className={'flex items-center'}>
              <SvgIcon name={'steps-active'} color={'#010101'} size={20} />
            </div>
            <div className={'font-semibold text-base leading-[20.16px]'}>
              Process steps to recorded
            </div>
            <div
              className={
                'leading-[17.66px] font-bold w-[22.36px] text-white bg-[#F3BA1D] text-[9.81px] rounded-full text-center'
              }>
              4
            </div>
          </div>

          <div className={'flex items-center gap-2'}>
            <ColumnSettingDisplayDropdown
              list={visibleSettingOptions.recorded}
              values={visible.recordedTable}
              onChange={(colId: string) => handleToggleVisible('recordedTable', colId)}>
              <SvgIcon className={'cursor-pointer'} name={'setting'} size={20} color={'#020202'} />
            </ColumnSettingDisplayDropdown>
            <SvgIcon name={'chevron-down'} size={20} color={'#020202'} />
          </div>
        </div>

        <Table
          visibleColumns={visible.recordedTable}
          enableStripedRowColor
          data={PROCESS_RECORDED_DATA}
          columns={processRecordedCols}
        />
      </div>
    </>
  );
}

export default MyTask;
