import { Fragment, useMemo, useState } from 'react';
import Modal from '../../common/Modal';
import Icon from '../../common/Icon';
import Avatar from '../../common/Avatar';
import Badge from '../../common/Badge';
import Button from '../../common/Button';
import { ArrowRight } from 'lucide-react';
import Select from '../../common/Select';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/router';
import { DataPageEnum } from '../../../constants/data';

const AssignmentValidation = () => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectStep, setRejectStep] = useState(1);
  const [isUnclear, setIsUnclear] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const modalContent = useMemo(() => {
    if (showRejectModal) {
      switch (rejectStep) {
        case 1:
          return (
            <div className="flex flex-col gap-4">
              <div className="p-[16.5px] rounded-2xl border border-boder-4 w-fit">
                <Icon name="user-group" size={30} />
              </div>
              <div className="text-2xl">
                Do you know who has to be <span className="font-bold">assigned</span> instead?
              </div>
              <div
                className={`border-border-14 border-[1px] rounded-lg p-3 ${isUnclear ? 'opacity-30' : ''}`}>
                <div className="gap-1 flex items-center">
                  <Icon name="person" size={16.5} />
                  <div className="text-base font-semibold">Person</div>
                </div>
                <div className="text-gray-9 text-sm">
                  This person will receive an invitation to describe{' '}
                  <span className="font-bold">this process step</span>.
                </div>
                <Select
                  items={[]}
                  value=""
                  onChange={() => {}}
                  className="mt-2 !min-w-[622px]"
                  placeholder="Choose"
                />
              </div>

              <div
                className={`border-border-14 border-[1px] rounded-lg p-3 ${isUnclear ? 'opacity-30' : ''}`}>
                <div className="gap-1 flex items-center">
                  <Icon name="inbox" size={18} />
                  <div className="text-base font-semibold">Shared Inbox</div>
                </div>
                <div className="text-gray-9 text-sm">
                  An invitation to describe <strong>this process step</strong> will be sent to this
                  inbox.
                </div>
                <Select
                  items={[]}
                  value=""
                  onChange={() => {}}
                  className="mt-2 !min-w-[622px]"
                  placeholder="mail@example.com"
                />
              </div>

              <div className="border-border-14 border-[1px] rounded-lg p-3">
                <div className="flex items-center justify-between">
                  <div className="gap-1 flex items-center">
                    <Icon name="circle-help" size={18} />
                    <div className="text-base font-semibold">Unclear</div>
                  </div>
                  <Icon
                    name={isUnclear ? 'circle-white' : 'circle-gray'}
                    size={24}
                    className="cursor-pointer"
                    onClick={() => setIsUnclear((value) => !value)}
                  />
                </div>

                <div className="text-gray-9 text-sm">
                  I am neither responsible nor do I know an alternative
                </div>
              </div>

              <div className="flex w-full gap-4">
                <Button onClick={() => setShowRejectModal(false)} className="w-full" label="Back" />
                <Button
                  className="w-full"
                  label="Continue"
                  rightIcon={<ArrowRight />}
                  disabled={!isUnclear}
                  onClick={() => setRejectStep(2)}
                />
              </div>
            </div>
          );
        case 2: {
          return (
            <div className="max-w-[479px] flex flex-col items-center">
              <img src="/assets/images/designer-desk.png" alt="" />
              <div className="text-2xl font-semibold text-center mt-7">
                Thank you for your input!
              </div>
              <div className="text-base mt-2 text-center">
                The process owner will receive a notification to assign a different person.
              </div>
              <Button
                className="mt-4 !w-full"
                label="Finish"
                onClick={() =>
                  navigate(`${ROUTES.DATA}/${ROUTES.DATA_PROCESS}`, {
                    state: { data: DataPageEnum.MY_PROCESS_STEPS }
                  })
                }
              />
            </div>
          );
        }
      }
    }
    return (
      <div>
        <div className="p-[16.5px] rounded-2xl border border-boder-4 w-fit">
          <Icon name="hand-shake" size={30} />
        </div>

        <ol className="relative mt-7 border-s border-[#DEDEDE]">
          <li className="ms-4 mb-3">
            <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
            <div className="-translate-y-3 ml-2">
              <div className="text-2xl font-semibold">Hi John!</div>
              <div className="mt-4 flex items-center gap-4">
                <Avatar
                  className="border-border-10 border-[2px] !w-[56px] !h-[56px]"
                  src=""
                  alt="Kenny John"
                />
                <div className="text-base">
                  <div>
                    <span className="font-semibold">KENNY, John</span> (Senior Software Developer),
                  </div>
                  <div>
                    recently finished her process step description and{' '}
                    <span className="font-semibold">assigned you</span> to describe a step in this
                    process.
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="mb-4 ms-6">
            <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
            <div className="border-border-14 border-[1px] rounded-lg p-4">
              <div className="flex items-center gap-1">
                <Icon name="waypoints-active" size={16} />
                <div className="text-[18px] font-semibold ">Employee Onboarding</div>
              </div>
              <div className="text-base mt-2 max-h-[115px] overflow-y-auto">
                Employee Onboarding involves integrating a new hire into the organization by
                completing necessary paperwork, providing orientation, and assigning initial
                training. This process ensures the employee is equipped with the tools, knowledge,
                and resources needed to perform their role effectively. It also fosters engagement
                and alignment with company culture from day one. perform their role effectively. It
                also fosters engagement and alignment with company culture from day one.perform
                their role effectively. It also fosters engagement and alignment with company
                culture from day one.perform their role effectively. It also fosters engagement and
                alignment with company culture from day one.
              </div>
            </div>
          </li>
          <li className="mb-4 ms-6">
            <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
            <div className="border-border-14 border-[1px] rounded-lg px-4 py-7 step-background">
              <div className="w-fit border-border-14 border-[1px] rounded-lg p-3">
                <div className="flex items-center w-full justify-between">
                  <div className="flex items-center gap-1.5">
                    <Avatar
                      className="border-border-10 border-[2px] !w-[32px] !h-[32px]"
                      src=""
                      alt="Kenny John"
                    />
                    <div className="text-xs">
                      <div className="font-semibold">KENNY, John</div>
                      <div className="text-[10px] text-gray-secondary">
                        Senior Software Developer
                      </div>
                    </div>
                  </div>
                  <Badge
                    text="Marketing"
                    variant="white"
                    state="outline"
                    round="semi-round"
                    size="medium"
                  />
                </div>
                <div className="h-[1px] w-full bg-border-12 my-2"></div>
                <div className="">
                  <div className="flex items-center gap-1">
                    <Icon name="steps-active" size={14} />
                    <div className="text-xs font-semibold">Previous Process Step</div>
                  </div>
                  <div className="text-xs mt-1 max-w-[300px]">
                    This step involves reviewing purchase orders (POs) submitted by team members to
                    ensure they align with our budget, vendor agreements, and organizational needs.
                    It’s a daily routine that ensures we spend wisely and comply with internal
                    policies.
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="mb-4 ms-6">
            <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
            <div className="bg-yellow-light-4 flex items-center p-7 gap-5 rounded-lg border-l-[6px] border-yellow-light-1">
              <div className="bg-yellow-light-1 rounded w-[34px] h-[34px] flex items-center justify-center">
                <Icon name="warning" size={18} />
              </div>
              <div className="text-yellow-dark-1 text-base ">
                <div>
                  First, please check if this assignment is{' '}
                  <span className="font-bold">correct</span>.
                </div>
                <div>If everything is correct, please document your specific process step. </div>
                <div>
                  Please complete this by <span className="font-bold">30-10-2024</span>.
                </div>
              </div>
            </div>
          </li>
          <li className="ms-6">
            <span className="absolute flex items-center justify-center w-[11px] h-[11px]  rounded-full -start-1.5 border-[#B2B2B2] border-[1.5px] bg-white"></span>
            <div className="flex w-full gap-4">
              <Button
                onClick={() => setShowRejectModal(true)}
                className="w-full"
                label="Don't Assign Me"
                rightIcon={<Icon name="circle-off" size={20} />}
              />
              <Button
                className="w-full"
                label="Assign Me"
                rightIcon={<ArrowRight />}
                onClick={() => {
                  setShowSuccessModal(true);
                }}
              />
            </div>
          </li>
        </ol>
      </div>
    );
  }, [showRejectModal, isUnclear, rejectStep, navigate]);

  const handleStart = () => {
    navigate(`${ROUTES.DATA}/${ROUTES.DATA_PROCESS}`, {
      state: { data: DataPageEnum.MY_PROCESS_STEPS }
    });
  };
  return (
    <Fragment>
      <Modal open={true} content={modalContent} onClose={() => {}} />
      {showSuccessModal && (
        <Modal
          open={showSuccessModal}
          content={
            <div className="min-w-[422px] flex flex-col items-center justify-center">
              <img src="/assets/images/create-process-success.png" alt="Logo" />
              <div className="mt-8 text-2xl font-semibold">
                You have been assigned successfully!
              </div>
              <div className="mt-2 text-base font-normal">
                You can now start describing your process step.
              </div>
              <div className="flex gap-4 w-full mt-4">
                <Button
                  className="flex-1"
                  label="Start"
                  rightIcon={<ArrowRight />}
                  onClick={handleStart}
                />
              </div>
            </div>
          }
          onClose={() => {}}
        />
      )}
    </Fragment>
  );
};

export default AssignmentValidation;
