import { useTranslation } from 'react-i18next';
import { COMPANY_SIZE, CompanySizeType, TARGET_MARKET } from '../../../../config/data';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useEffect, useMemo } from 'react';
import { listIndustryClassification } from '../../../../api/industry-classification';
import _ from 'lodash';
import { setCreateData } from '../../../../app/slices/organization';

const AdditionalInformation = () => {
  const { t } = useTranslation();
  const { list } = useAppSelector((state) => state.industryClassification);
  const { createData } = useAppSelector((state) => state.organization);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listIndustryClassification());
  }, [dispatch]);

  const targetMarketItems = TARGET_MARKET.map((item) => ({
    value: item.type,
    label: t(`data.${item.name}`)
  }));

  const industryClassificationItems = useMemo(() => {
    return _.orderBy(list, 'class').map((item) => ({
      value: item._id,
      label: (
        <div className="text-left">
          <div className="fomt-medium text-sm">Class {item.class}</div>
          <div title={item.en} className="text-xs text-gray-secondary">
            {item.en}
          </div>
        </div>
      )
    }));
  }, [list]);

  const companySizeItems = COMPANY_SIZE.map((item) => ({
    value: item.type,
    label: (
      <div className="text-left">
        <div className="fomt-medium text-sm">{t(`data.${item.name}`)}</div>
        <div className="text-xs text-gray-secondary">{t(`data.${item.description}`)}</div>
      </div>
    )
  }));

  const handleChangeValue = (key: string, value: any) => {
    dispatch(setCreateData({ key, value }));
  };

  const handleChangeCompanySize = (value: CompanySizeType) => {
    const matchCompanySize = COMPANY_SIZE.find((item) => item.type === value);
    handleChangeValue('companySize', _.omit(matchCompanySize, ['name', 'description']));
  };

  return (
    <div className="min-w-[930px] mt-10">
      <div>
        <div className="text-xl font-semibold">Additional Information</div>
        <div className="text-sm text-gray-8">
          Provide additional details about the customer organization.
        </div>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Business Model</div>
            <Input
              placeholder="Enter description for the Business Model"
              value={createData?.businessModel}
              onChange={(e) => handleChangeValue('businessModel', e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Target Market</div>
            <Select
              items={targetMarketItems}
              placeholder={'Base or Audience'}
              onChange={(value) => handleChangeValue('targetMarket', value)}
              value={createData?.targetMarket}
            />
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Industry Classification</div>
            <Select
              items={industryClassificationItems}
              placeholder={'The sector or industry in which the client operates'}
              onChange={(value) => handleChangeValue('industryClassification', value)}
              value={createData?.industryClassification}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Company Size</div>
            <Select
              items={companySizeItems}
              placeholder={'Based on the number of employees'}
              onChange={(value) => handleChangeCompanySize(value)}
              value={createData?.companySize?.type}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInformation;
