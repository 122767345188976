import { FC, useRef, useState } from 'react';
import { Props } from './@types/dropzone';
import Icon from './Icon';
import Button from './Button';
import { useAppDispatch } from '../../app/hooks';
import { uploadFile } from '../../api/upload';
import { get } from 'lodash';
import Avatar from './Avatar';

const DropZone: FC<Props> = ({ onSuccess, src, type, ...props }) => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const handleChangeFile = (files: FileList | null) => {
    if (!files?.length) {
      setFile(null);
    } else {
      setFile(files[0]);
      handleUploadFile(files[0]);
    }
  };

  const handleEdit = () => {
    inputRef.current?.click();
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragging(false);
    setFile(e.dataTransfer.files[0]);
    handleUploadFile(e.dataTransfer.files[0]);
  };

  const handleUploadFile = async (file?: File | null) => {
    if (!file) {
      return;
    }
    const result = await dispatch(uploadFile(file));
    onSuccess && onSuccess(get(result, 'payload.filename', ''));
  };

  return (
    <div>
      {file || src ? (
        <div className="flex items-center gap-6">
          <Avatar
            className="!w-[174px] !h-[174px] rounded-full"
            src={file ? URL.createObjectURL(file) : src || ''}
            rounded="full"
          />
          <div className="flex gap-[6px] flex-col">
            <div className="flex gap-[6px]">
              <Button
                label="Edit"
                size="small"
                className="!w-[58px] !h-[40px] !rounded-lg"
                onClick={handleEdit}
              />
              <Button
                label="Remove"
                size="small"
                className="!h-[40px] !rounded-lg"
                onClick={() => setFile(null)}
              />
            </div>
            <div className="text-gray-8 text-xs">SVG, PNG, JPG or GIF (max. 800x400px)</div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">
          <label
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            htmlFor="dropzone-file"
            className={`flex flex-col items-center justify-center w-full min-h-[174px] rounded-lg cursor-pointer ${isDragging ? 'border-[1px] border-gray-400 border-solid bg-bg-4' : 'border-2 border-gray-300 border-dashed'}`}>
            {type === 'image' ? (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Icon name="backup" />
                <p className="mt-3 mb-2 text-sm">
                  <span>Drag your file or</span>{' '}
                  <span className="text-[#1849D6] font-semibold">browse</span>
                </p>
                <p className="text-sm text-secDefault">Max 10 MB files are allowed</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Icon name="cloud-upload" />
                <p className="mt-3 mb-2 text-base font-medium text-gray-15">
                  <span>Drag & drop, or </span> <span>click to select files</span>
                </p>
                <p className="text-sm text-secDefault">You can upload up to 20 files.</p>
              </div>
            )}
          </label>
        </div>
      )}
      <input
        ref={inputRef}
        onChange={(e) => handleChangeFile(e.target.files)}
        id="dropzone-file"
        type="file"
        className="hidden"
        accept="image/*"
        {...props}
      />
    </div>
  );
};

export default DropZone;
