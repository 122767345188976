import { useForm } from 'react-hook-form';
import Input from '../../../components/common/Input';
import { ICreateDepartment } from '../../../@types/department';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { reset as resetDepartmentSlice } from '../../../app/slices/department.slice';
import { fetchUsers } from '../../../api/user';
import { User } from '../../../@types/user';
import Select from '../../../components/common/Select';
import Button from '../../../components/common/Button';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import { createDepartment, fetchDepartmentDetail } from '../../../api/department';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/router';
import _ from 'lodash';

const CreateDepartment = () => {
  const { getValues, setValue, reset, watch } = useForm<ICreateDepartment>();
  const { department } = useAppSelector((state) => state.department);
  const { name, abbreviation, manager, members, description } = watch();
  const { departmentId } = useParams();
  const [users, setUsers] = useState<User[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getListUser = async () => {
      const result = await dispatch(fetchUsers());
      setUsers(result.payload as User[]);
    };
    if (departmentId) {
      dispatch(fetchDepartmentDetail(departmentId));
    }
    getListUser();
    return () => {
      dispatch(resetDepartmentSlice());
    };
  }, [dispatch, departmentId]);

  const handleSubmit = useCallback(() => {
    const data = getValues();
    dispatch(createDepartment(data));
    reset();
    navigate(`${ROUTES.DATA}/${ROUTES.DATA_DEPARTMENT}`);
  }, [reset, getValues, dispatch, navigate]);

  const handleGoBack = () => {
    navigate(`${ROUTES.DATA}/${ROUTES.DATA_DEPARTMENT}`);
  };

  useEffect(() => {
    if (department) {
      setValue('abbreviation', department.abbreviation);
      setValue('description', department.description);
      setValue('manager', department.manager as string);
      setValue('name', department.name);
      setValue('members', _.map(department.members, '_id'));
    }
  }, [department, setValue]);

  const userItems = useMemo(() => {
    return users.map((user) => ({
      value: user._id,
      label: (
        <div className="text-left">
          <div className="text-sm">
            {user.firstName} {user.lastName}
          </div>
          <div className="text-xs text-gray-secondary">{user.role}</div>
        </div>
      )
    }));
  }, [users]);

  return (
    <div>
      <div>
        <div className="grid grid-cols-2 gap-6">
          <div className="flex col-span-2 gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Department Name*</div>
            <Input value={name} onChange={(e) => setValue('name', e.target.value)} />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Description</div>
            <Input value={description} onChange={(e) => setValue('description', e.target.value)} />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Abbreviation</div>
            <Input
              value={abbreviation}
              onChange={(e) => setValue('abbreviation', e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Manager</div>
            <Select
              items={userItems}
              value={manager}
              onChange={(value) => setValue('manager', value)}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Members</div>
            <Select
              items={userItems}
              value={members || []}
              onChange={(value) => setValue('members', value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-auto absolute bottom-0 left-0 w-full border-t border-border-5 p-4 flex justify-between">
        <Button label="Back" leftIcon={<ArrowLeft />} onClick={handleGoBack} />
        <Button
          disabled={Boolean(departmentId)}
          label={'Finish'}
          rightIcon={<ArrowRight />}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default CreateDepartment;
