import Avatar from './Avatar';
import { FC } from 'react';
import Badge from './Badge';

interface Props {
  image?: string;
  firstName: string;
  lastName: string;
  role?: string;
  department?: string;
}

const UserInfoItem: FC<Props> = ({ image, firstName, lastName, role, department }) => {
  return (
    <div className="flex items-center w-full justify-between">
      <div className="flex items-center gap-1.5">
        <Avatar
          className="border-border-10 border-[2px] !w-[32px] !h-[32px]"
          src={image || ''}
          alt={`${firstName} ${lastName}`}
        />
        <div className="text-xs">
          <div className="font-semibold">
            <span className="uppercase">{firstName},</span> {lastName}
          </div>
          <div className="text-[10px] text-gray-secondary">{role}</div>
        </div>
      </div>
      <Badge text={department} variant="white" state="outline" round="semi-round" size="medium" />
    </div>
  );
};

export default UserInfoItem;
