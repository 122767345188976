import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from '../libs/agent'

export const uploadFile = createAsyncThunk("uploads", async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append(
        "file",
        file,
        file.name
    );
    const response = await agent.post(`uploads`, formData);
    return response.data.data
})

