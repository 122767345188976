import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import DropZone from '../../../common/DropZone';
import Input from '../../../common/Input';
import Select from '../../../common/Select';
import { listLegalStructure } from '../../../../api/legal-structrue';
import { getData } from 'country-list';
import { setCreateData } from '../../../../app/slices/organization';
import SvgIcon from '../../../common/SvgIcon';

const GeneralInformation = () => {
  const [searchStructure, setSearchStructure] = useState('');
  const dispatch = useAppDispatch();
  const { list } = useAppSelector((state) => state.legalStructure);
  const { createData } = useAppSelector((state) => state.organization);
  useEffect(() => {
    dispatch(listLegalStructure());
  }, [dispatch]);

  const legalStructureItems = useMemo(() => {
    return list
      .filter(
        (item) =>
          item.de.toLowerCase().includes(searchStructure.toLowerCase()) ||
          item._id === createData?.legalStructure
      )
      .map((item) => ({
        value: item._id,
        label: (
          <div className="text-left">
            <div className="text-sm">{item.de}</div>
            <div className="text-xs text-gray-secondary">{item.en}</div>
          </div>
        )
      }));
  }, [list, searchStructure]);

  const countryItems = getData().map((item) => ({ value: item.code, label: item.name }));

  const handleChangeValue = (key: string, value: string) => {
    dispatch(setCreateData({ key, value }));
  };

  return (
    <div className="min-w-[930px] mt-10">
      <div>
        <div className="text-xl font-semibold">General Information</div>
        <div className="text-sm text-gray-8">
          Enter key details to create a new customer organization.
        </div>
      </div>
      <div className="mt-10 w-1/2">
        <div className="text-base font-medium text-gray-900 mb-2">Company Logo</div>
        <DropZone
          type={'image'}
          src={
            createData?.image && `${process.env.REACT_APP_BACKEND_ENDPOINT}/${createData?.image}`
          }
          onSuccess={(value) => handleChangeValue('image', value)}
        />
      </div>
      <div className="mt-7">
        <div className="grid grid-cols-2 gap-6">
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Company Name*</div>
            <Input
              value={createData?.companyName}
              onChange={(e) => handleChangeValue('companyName', e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Legal Structure</div>
            <Select
              onMenuHeaderRender={() => (
                <Input
                  type={'text'}
                  placeholder={'Search...'}
                  status={'default'}
                  onClick={(e: any) => e.stopPropagation()}
                  leftIcon={<SvgIcon name={'search'} color={'#C2C2C2'} size={16} />}
                  value={searchStructure}
                  onChange={(e) => setSearchStructure(e.target.value)}
                />
              )}
              items={legalStructureItems}
              value={createData?.legalStructure}
              onChange={(value) => handleChangeValue('legalStructure', value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-5">
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Country</div>
            <Select
              items={countryItems}
              value={createData?.address?.country}
              onChange={(value) => handleChangeValue('address.country', value)}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">City</div>
            <Input
              value={createData?.address?.city}
              onChange={(e) => handleChangeValue('address.city', e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col">
            <div className="text-base font-medium text-gray-900">Postal Code</div>
            <Input
              value={createData?.address?.zipCode}
              onChange={(e) => handleChangeValue('address.zipCode', e.target.value)}
            />
          </div>
        </div>
        <div className="flex gap-2 flex-col mt-5">
          <div className="text-base font-medium text-gray-900">Street Address</div>
          <Input
            value={createData?.address?.street}
            onChange={(e) => handleChangeValue('address.street', e.target.value)}
          />
        </div>
        <div className="flex gap-2 flex-col mt-5">
          <div className="text-base font-medium text-gray-900">Website</div>
          <Input
            value={createData?.website}
            onChange={(e) => handleChangeValue('website', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
