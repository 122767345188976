import { FC, ReactNode, useEffect, useMemo } from 'react';
import { IOgrganizationMember } from '../../../@types/organization';
import { useTranslation } from 'react-i18next';
import { MAP_ORG_MEMBER_STATUS } from '../../../config/data';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import Toggle from '../../../components/common/Toggle';
import Table from '../../../components/common/Table';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { fetchOrganizationMembers } from '../../../api/organization';
import Badge from '../../../components/common/Badge';
import UserNotFound from '../../../components/Data/Oranization/Create/UserNotFound';

const RenderUserList: FC<{
  items: IOgrganizationMember[];
}> = ({ items }) => {
  const { t } = useTranslation();
  const processItems = useMemo(
    () =>
      items.map((item, index) => ({
        id: index + 1,
        info: {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email
        },
        role: item.role,
        userTypes: item.userTypes,
        orgMemberStatus: {
          name: t(`data.${item.orgMemberStatus}`),
          description: t(`data.${MAP_ORG_MEMBER_STATUS[item.orgMemberStatus].description}`)
        },
        active: item.confirm
      })),
    [items, t]
  );
  const cols = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: '#',
        cell: (row) => {
          return <div className="text-lg">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'id'
      },
      {
        header: 'Name',
        cell: (row) => {
          const info = row.getValue();
          return (
            <div>
              <div className="font-bold text-base">
                {_.get(info, 'firstName')}, {_.get(info, 'lastName')}
              </div>
              <div className="font-normal text-sm text-gray-secondary">{_.get(info, 'email')}</div>
            </div>
          );
        },
        accessorKey: 'info'
      },
      {
        header: 'Role Description',
        cell: (row) => {
          return <div className="text-sm font-medium">{row.getValue() as ReactNode}</div>;
        },
        accessorKey: 'role'
      },
      {
        header: 'User-Type',
        cell: (row) => {
          const userTypes = row.getValue() as string[];
          return (
            <div className="flex flex-col gap-1">
              {userTypes.map((item) => (
                <Badge text={item} variant="light" state="fill" round="full-round" size="medium" />
              ))}
            </div>
          );
        },
        accessorKey: 'userTypes'
      },
      {
        header: 'Org-Member Status',
        cell: (row) => {
          const value = row.getValue() as any;
          return (
            <div>
              <div className="text-base font-medium">{value.name}</div>
              <div className="font-normal text-sm text-gray-secondary">{value.description}</div>
            </div>
          );
        },
        accessorKey: 'orgMemberStatus'
      },
      {
        header: 'Is active?',
        cell: (row) => {
          const active = row.getValue() as boolean;
          return <Toggle checked={active} lableRight={active ? 'Yes' : 'No'} />;
        },
        accessorKey: 'active'
      }
    ],
    []
  );

  return (
    <div className="w-full">
      <Table data={processItems} columns={cols} />
    </div>
  );
};

const OrganizationMember = () => {
  const { members } = useAppSelector((state) => state.organization);
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && user.organization) {
      dispatch(fetchOrganizationMembers(user.organization));
    }
  }, [dispatch, user]);

  return (
    <div className="w-full flex flex-col">
      {members?.length ? <RenderUserList items={members} /> : <UserNotFound />}
    </div>
  );
};

export default OrganizationMember;
