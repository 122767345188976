import { FC } from 'react';
import { Props } from '../../common/@types/modal';
import Modal from '../../common/Modal';
import Icon from '../../common/Icon';
import Button from '../../common/Button';
import { ArrowRight } from 'lucide-react';
import DropZone from '../../common/DropZone';
import FormCard from '../../common/FormCard';

const ModalUploadDocument: FC<Props> = ({ onClose, open }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="flex flex-col gap-4 w-[773px]">
          <div className="p-[16.5px] rounded-2xl border border-boder-4 w-fit">
            <Icon name="document-2" size={30} />
          </div>
          <div>
            <div className="font-semibold text-2xl">Documents</div>
            <div className="text-base">Upload or choose documents</div>
          </div>
          <FormCard
            theme="light"
            content={
              <div>
                <DropZone accept=".doc,.docx,.xls,.xlsx,.pdf" multiple type="document" />
                <div className="mt-5 flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <Icon name="document-2" size={16} />
                      <div>
                        <div className="text-base">Tech design requirements.pdf</div>
                        <div className="flex gap-2 text-sm text-[#71717AB2]">
                          <div>
                            <strong>Size</strong>: 39.1 mo
                          </div>
                          <div>
                            <strong>Created</strong>: 28/11/2024
                          </div>
                        </div>
                      </div>
                    </div>
                    <Icon name="trash-2" size={16} />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <Icon name="document-2" size={16} />
                      <div>
                        <div className="text-base">Tech design requirements.pdf</div>
                        <div className="flex gap-2 text-sm text-[#71717AB2]">
                          <div>
                            <strong>Size</strong>: 39.1 mo
                          </div>
                          <div>
                            <strong>Created</strong>: 28/11/2024
                          </div>
                        </div>
                      </div>
                    </div>
                    <Icon name="trash-2" size={16} />
                  </div>
                </div>
              </div>
            }
          />
          <div className="flex gap-4">
            <Button className="!w-full" label="Back" onClick={onClose} />
            <Button className="!w-full" label="Save" rightIcon={<ArrowRight />} onClick={onClose} />
          </div>
        </div>
      }
    />
  );
};

export default ModalUploadDocument;
