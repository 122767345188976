import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getSortedRowModel,
  Column
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import SvgIcon from '../SvgIcon';
import './style.css';
import Button from '../Button';
import ColumnSettingDisplayDropdown from '../ColumnSettingDisplayDropdown';

type RenderOption<T> = T & {
  columnType?: string;
};

interface ReactTableProps<T extends object> {
  data: RenderOption<T>[];
  columns: RenderOption<ColumnDef<T>>[];
  header?: ReactNode;
  enableSetting?: boolean;
  rightAction?: ReactNode;
  visibleColumns?: string[];
  enableStripedRowColor?: boolean;
}

const Table = <T extends object>({
  data,
  columns,
  header,
  rightAction,
  visibleColumns,
  enableSetting = false,
  enableStripedRowColor = false
}: ReactTableProps<RenderOption<T>>) => {
  const tableContainerRef = useRef<HTMLTableElement | null>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [visibleCols, setVisibleCols] = useState<string[]>([]);

  let transformColumns: ColumnDef<T>[] = useMemo(
    () =>
      columns.map((col) => {
        return { enableSorting: false, enablePinning: false, ...col };
      }),
    [columns]
  );

  useEffect(() => {
    if (visibleColumns) setVisibleCols(visibleColumns);
  }, [visibleColumns]);

  useEffect(() => {
    if (visibleCols.length === 0 && columns.length > 0) {
      setVisibleCols(
        ([...columns] as Record<string, any>[])
          .filter((col) => !col.columnType && col.header)
          .map((col) => col.id || col.accessorKey || col.header)
      );
    }
  }, [enableSetting, visibleCols.length, columns.length]);

  const table = useReactTable({
    data,
    columns: transformColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableColumnPinning: true,
    columnResizeMode: 'onChange',
    state: {
      sorting
    }
  });

  const getCommonPinningStyles = (column: Column<T>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');

    return {
      boxShadow: isLastLeftPinnedColumn ? '-1px 0 2px -1px gray inset' : undefined,
      left: isPinned === 'left' ? `${column.getStart('left') - 1}px` : undefined,
      opacity: 1,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      background: isPinned ? 'white' : undefined
    };
  };

  const handleToggleVisible = useCallback(
    (header: string) => {
      if (visibleCols.includes(header)) {
        setVisibleCols((value) => value.filter((item) => item !== header));
      } else {
        setVisibleCols((value) => [...value, header]);
      }
    },
    [visibleCols]
  );

  const hdPinVisibleColumn = useCallback(() => {
    if (tableContainerRef.current) {
      let shouldPin = tableContainerRef.current?.scrollLeft > 0;

      let allColumns = table.getAllColumns();

      allColumns.forEach((col) => {
        col.pin(false);
      });

      if (!shouldPin) return;

      let allVisibleColumns = allColumns.filter((col) => visibleCols.includes(col.id));
      let noPinColumn = allVisibleColumns.every((col) => !col.getCanPin());

      if (noPinColumn && allVisibleColumns.length > 0)
        return allVisibleColumns[0].pin(shouldPin ? 'left' : false);

      return allVisibleColumns.forEach((col) => {
        if (col.getCanPin()) col.pin(shouldPin ? 'left' : false);
      });
    }
  }, [visibleCols, table]);

  useEffect(() => {
    hdPinVisibleColumn();
  }, [hdPinVisibleColumn]);

  useEffect(() => {
    const abortController = new AbortController();
    if (tableContainerRef.current) {
      tableContainerRef.current.addEventListener(
        'scroll',
        () => {
          hdPinVisibleColumn();
        },
        {
          signal: abortController.signal
        }
      );
    }

    return () => {
      abortController.abort();
    };
  }, [hdPinVisibleColumn]);

  const displaySettingOptions = useMemo(() => {
    return ([...columns] as Record<string, any>[])
      .filter((column) => column.header)
      .map((column) => ({
        id: column.id || column.accessorKey || column.header,
        name: typeof column.header === 'string' ? column.header : column.headerSettingName,
        type: column.columnType || undefined
      }));
  }, [columns]);

  const columnSizingHandler = (
    thElem: HTMLTableCellElement | null,
    table: any,
    column: Column<any>
  ) => {
    if (!thElem) return;
    if (table.getState().columnSizing[column.id] === thElem.getBoundingClientRect().width) return;

    table.setColumnSizing((prevSizes: any) => ({
      ...prevSizes,
      [column.id]: thElem.getBoundingClientRect().width
    }));
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div>{header && header}</div>

        <div className="flex items-center gap-2">
          {rightAction}

          {enableSetting && (
            <ColumnSettingDisplayDropdown
              list={displaySettingOptions}
              values={visibleCols}
              onChange={handleToggleVisible}>
              <Button className="cursor-pointer" leftIcon={<SvgIcon name="setting" size={24} />} />
            </ColumnSettingDisplayDropdown>
          )}
        </div>
      </div>

      <div ref={tableContainerRef} className="w-full overflow-x-auto table-container">
        <table className="min-w-full text-center border-border-13 border-2 rounded-xl">
          <thead className="border-b bg-gray-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  let isSortable = header.column.getCanSort();
                  let isSorted = header.column.getIsSorted();
                  let colCss = isSortable ? `cursor-pointer select-none` : '';
                  if (
                    visibleCols.includes(header.id) ||
                    (!header.column.columnDef.header && header.column.columnDef.cell)
                  ) {
                    return (
                      <th
                        key={header.id}
                        className="px-7 py-4 text-lg font-semibold w-auto text-black text-left bg-bg-1 whitespace-nowrap"
                        style={{ ...getCommonPinningStyles(header.column) }}
                        ref={(thElem) => columnSizingHandler(thElem, table, header.column)}>
                        <div
                          className={`flex items-center gap-2.5 ${colCss}`}
                          onClick={
                            isSortable ? header.column.getToggleSortingHandler() : undefined
                          }>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                          {isSortable && !isSorted && <SvgIcon name={'sort-disable'} size={24} />}
                          {isSortable && isSorted && (
                            <SvgIcon name={`sort-${isSorted}`} size={24} />
                          )}
                        </div>
                      </th>
                    );
                  } else {
                    return null;
                  }
                })}
              </tr>
            ))}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row, idx) => (
              <tr
                key={row.id}
                className={`w-auto ${enableStripedRowColor && idx % 2 !== 0 ? 'bg-[#F9FAFB]' : 'bg-white'}`}>
                {row.getVisibleCells().map((cell) => {
                  const columnId = cell.column.id;
                  if (
                    visibleCols.includes(columnId) ||
                    (!cell.column.columnDef.header && cell.column.columnDef.cell)
                  ) {
                    return (
                      <td
                        className="whitespace-nowrap px-7 py-4 text-sm font-medium text-black text-left"
                        key={cell.id}
                        style={{ ...getCommonPinningStyles(cell.column) }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  }
                  return null;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
