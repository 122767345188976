import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from '../libs/agent'
import { IResetPassword, ISignIn, ISignUp } from "../@types/auth";
import { User } from "../@types/user";


export const signIn = createAsyncThunk("auth/login", async (data: ISignIn) => {
    const response = await agent.post(`auth/login`, data);
    return response.data
})

export const signUp = createAsyncThunk("auth/sign-up", async (data: ISignUp) => {
    const response = await agent.post(`auth/sign-up`, data);
    return response.data
})

export const verifyToken = createAsyncThunk("auth/verify-token", async (token: string) => {
    const response = await agent.post(`auth/verify-token`, { token });
    return response.data.data
})

export const forgotPasword = createAsyncThunk("auth/forgot-password", async (email: string) => {
    const response = await agent.post(`auth/forgot-password`, { email });
    return response.data
})

export const resetPassword = createAsyncThunk("auth/reset-password", async (data: IResetPassword) => {
    const response = await agent.post(`auth/reset-password`, data);
    return response.data
})

export const profile = createAsyncThunk("auth/profile", async (): Promise<User> => {
    const response = await agent.get(`auth/profile`);
    return response.data.data
})
