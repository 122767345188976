import { ArrowRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import Input from '../common/Input';
import Button from '../common/Button';
import PasswordInput from '../common/PasswordInput';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { signIn } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS } from '../../constants';
import { ROUTES } from '../../constants/router';

const LoginForm = () => {
  const { t } = useTranslation();
  const { loading, error, accessToken } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [validForm, setValidForm] = useState({
    validEmail: true,
    validPassword: true,
    helperTextEmail: '',
    helperTextPassword: ''
  });
  const navigate = useNavigate();

  const isValidForm = useCallback(() => {
    setValidForm({
      validEmail: Boolean(email),
      validPassword: Boolean(password),
      helperTextEmail: Boolean(email) ? '' : t('emailIsRequired'),
      helperTextPassword: Boolean(password) ? '' : t('passwordIsRequired')
    });
    return email && password;
  }, [email, password, t]);

  const handleSignIn = useCallback(async () => {
    if (isValidForm()) {
      dispatch(signIn({ email, password }));
    }
  }, [email, password, dispatch, isValidForm]);

  useEffect(() => {
    if (error) {
      setValidForm((data) => ({
        ...data,
        validEmail: false,
        validPassword: false,
        helperTextPassword: t('unauthorizedText')
      }));
    } else {
      setValidForm((data) => ({
        ...data,
        validEmail: true,
        validPassword: true,
        helperTextPassword: ''
      }));
    }
  }, [error, t]);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, accessToken);
      navigate(ROUTES.MAIN);
    }
  }, [accessToken, rememberMe, navigate]);

  const handleForgotPassword = () => {
    navigate(ROUTES.FORGOT_PASSWORD);
  };

  return (
    <div className="flex flex-col w-full">
      <Input
        placeholder={t('email')}
        type="email"
        className="mb-4"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        status={validForm.validEmail ? 'default' : 'error'}
        onMouseDown={() =>
          setValidForm((data) => ({ ...data, validEmail: true, helperTextEmail: '' }))
        }
        helperText={validForm.helperTextEmail}
      />
      <PasswordInput
        placeholder={t('password')}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        status={validForm.validPassword ? 'default' : 'error'}
        onMouseDown={() =>
          setValidForm((data) => ({ ...data, validPassword: true, helperTextPassword: '' }))
        }
        helperText={validForm.helperTextPassword}
      />
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2 text-gray-secondary text-sm">
          <input
            type="checkbox"
            className="border-inputBorder w-[20px] h-[20px] rounded border-[1px]"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          {t('rememberMe')}
        </div>
        <div onClick={handleForgotPassword} className="font-medium text-sm cursor-pointer">
          {t('forgotPassword')} ?
        </div>
      </div>
      <Button
        label={t('signIn')}
        rightIcon={<ArrowRight />}
        className="mt-4 mb-2 w-full"
        onClick={handleSignIn}
        theme="dark"
        loading={loading}
      />
    </div>
  );
};

export default LoginForm;
