import { FC } from 'react';
import { Props } from './@types/checkbox';
import SvgIcon from './SvgIcon';

const Checkbox: FC<Props> = ({ label, value, checked, onChange, disabled }) => {
  return (
    <div
      className={`flex items-center gap-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={() => !disabled && onChange && onChange(!checked)}>
      <div
        className={`w-5 h-5 rounded border flex items-center justify-center ${checked ? 'bg-black-2 border-black-2' : 'border-normal bg-white'} ${disabled ? 'opacity-30' : ''}`}>
        {checked ? <SvgIcon size={12} name="dropdown" /> : null}
      </div>
      <label
        className={`text-sm text-dark-primary select-none ${disabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'}`}
        htmlFor={value}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
